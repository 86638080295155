import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { getLastNSomns, getSleepDiffs, calculateSleepVariation, Colors } from '../../helpers/utils';
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import WarningIcon from '@mui/icons-material/Warning'; // import warning icon
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import '../../../src/fonts.css';

function Gauge({ title, user, calculateValue, warningCondition, colorBands, valueUnit, warningMessage, privacyLevel }) {
  const chartRef = useRef(null);
  const [userValue, setUserValue] = useState(null);
  const [warning, setWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // add loading state

  

  useEffect(() => {
    const calculateDataset = async () => {
      const lastSevenSomns = await getLastNSomns(user.uid, 8);
      const lastSevenSleepTimes = getSleepDiffs(lastSevenSomns);
      const value = calculateValue(lastSevenSleepTimes);

      setUserValue(value);
      setWarning(warningCondition(value));
      setIsLoading(false); // stop loading when data is ready
    }

    calculateDataset();
  }, [user, title, calculateValue, warningCondition]);

  useLayoutEffect(() => {
    if (isLoading) return; // don't create chart if still loading

    let root = am5.Root.new(chartRef.current);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    let chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: 160,
        endAngle: 380
      })
    );

    // Create axis and its renderer
    let axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -40
    });

    axisRenderer.grid.template.setAll({
      stroke: root.interfaceColors.get("background"),
      visible: true,
      strokeOpacity: 0.8
    });

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        max: 8,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    // Add clock hand
    let axisDataItem = xAxis.makeDataItem({});

    let clockHand = am5radar.ClockHand.new(root, {
      pinRadius: am5.percent(20),
      radius: am5.percent(100),
      bottomWidth: 40
    });

    let bullet = axisDataItem.set(
      "bullet",
      am5xy.AxisBullet.new(root, {
        sprite: clockHand
      })
    );

    xAxis.createAxisRange(axisDataItem);

    let label = chart.radarContainer.children.push(
      am5.Label.new(root, {
        fill: am5.color("#FFFFFF"),
        centerX: am5.percent(50),
        textAlign: "center",
        centerY: am5.percent(-50),
        fontSize: "2em",
      })
    );
    // label.dy = 50; // moves the label 50 pixels down
    // label.y = am4core.percent(90); // adjust the value as needed
    // label.y = am5xy.Percent.new(120);


    

    axisDataItem.set("value", userValue);

    // pin number and colour
    bullet.get("sprite").on("rotation", function () {
      let value = axisDataItem.get("value");
      let fill = am5.color("#FFFFFF");
      xAxis.axisRanges.each(function (axisRange) {
        if (
          value >= axisRange.get("value") &&
          value <= axisRange.get("endValue")
        ) {
          fill = axisRange.get("axisFill").get("fill");
        }
      });
    
      label.set("text", userValue >= 8 ? '>8 hours' : `${Math.round(userValue)} ${Math.round(userValue) === 1 ? 'hour' : 'hours'}`);



      clockHand.pin.animate({
        key: "fill",
        to: fill,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
      clockHand.hand.animate({
        key: "fill",
        to: fill,
        duration: 500,
        easing: am5.ease.out(am5.ease.cubic)
      });
    });

    chart.bulletsContainer.set("mask", undefined);

    const bandsData = colorBands;

    bandsData.forEach((data) => {
      let axisRange = xAxis.createAxisRange(xAxis.makeDataItem({}));
    
      axisRange.setAll({
        value: data.lowScore,
        endValue: data.highScore,
      });
    
      axisRange.get("axisFill").setAll({
        visible: true,
        fill: am5.color(data.color),
        fillOpacity: 0.8,
      });
    
      axisRange.get("label").setAll({
        text: data.title,
        inside: true,
        radius: 15,
        fontSize: "0.9em",
        fill: root.interfaceColors.get("background"),
      });
    });

    // Display the current value with the unit
    label.set("text", `${Math.round(userValue)} ${valueUnit}`);

    // Make stuff animate on load
    chart.appear(1000, 100);

    // Clean up on unmount
    return () => {
      root.dispose();
    };
  }, [userValue, isLoading]); // added userValue and isLoading dependencies


  return (
    <Box sx={{ width: '100%', margin: '10px 20px'}}>
      <div style={{ width: '100%' }}> {/* Adjust the width and height as needed */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Figtree',
            color: '#fff',
            borderRadius: 5,
            padding: 1,
            marginBottom: 0, // Add margin to separate the title from the gauge
          }}
        >
          <h2 style={{ fontFamily: 'Figtree', fontSize: 30, fontWeight: 900, color: '#fff', margin: 0, textAlign: 'center'}}>
            {title}
          </h2>
          {warning &&
            <Box sx={{ color: 'orange', marginTop: '-3px', ml: 2}}>
              <Tooltip title={warningMessage}>
                <WarningIcon style={{ color: Colors.SoftYellow }} fontSize="large" /> {/* Adjust icon size */}
              </Tooltip>
            </Box>
          }
        </Box>
       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  <div ref={chartRef} style={{ width: '100%', maxWidth: '300px', height: '300px' }}></div>
</div>

      </div>
    </Box>
  );
  

  
}

export default Gauge;
