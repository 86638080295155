import { React, useRef, useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
    ArcElement,
    TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Doughnut } from 'react-chartjs-2';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import { getLastSevenSomns, getRandomColor, getSleepDiffs, getLastSevenTimeZones, getLocationAndStamps, getProcessedLastSevenSomns, formatDateLabels, darkenColor, PRIVACY_LEVELS, Colors, toSeconds, getLastEightSomns } from '../../helpers/utils';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import '../../../src/fonts.css';

const centerTextPlugin = {
    id: 'centerTextPlugin',
    beforeDraw: function (chart) {
        const ctx = chart.ctx;
        const value = chart.data.datasets[0].data[0];
        const userValue = chart.options.userValue;
        const titleText = chart.options.titleText;
        
        let mainText = ''; // For userValue or value
    
        if (userValue !== null) {
            if (value === userValue && titleText === 'Sleep Debt') {
                mainText = `${userValue}`;
            } else if (value !== 0) {
                mainText = `${value}`;
            }
            else {
                mainText = '0';
            }
        }
    
        ctx.save();
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";
    
        // Rendering mainText (value or userValue)
        ctx.font = "70px 'Figtree'";
        ctx.fillStyle = "#fff";
        ctx.fillText(mainText, chart.width / 2, chart.height / 2 - 10); // -10 to slightly adjust position
    
        let subText = (titleText !== 'Bedtime Variation' && titleText !== 'Wake Time Variation') ? 'hours': 'mins';

        // Rendering subText
        ctx.font = "25px 'Figtree'";
        ctx.fillStyle = "#fff";
        ctx.fillText(subText, chart.width / 2, chart.height / 2 + 45); // +45 to place it below the mainText
    
        ctx.restore();
    }
    
    
};



ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    TimeScale,
    ArcElement,
    Title,
    Tooltip,
    Legend,
);

function Gauge({ title, 
  user, 
  calculateValue, 
  warningCondition, 
  colorBands, 
  valueUnit, 
  warningMessage, 
  privacyLevel,
  teamSleepDurations,
  teamSleepDurationsVBW,
  currentStartDate
}) {
    const chartRef = useRef(null);
    const [userValue, setUserValue] = useState(null);
    const [warning, setWarning] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // add loading state
    const [hoursForRest, setHoursForRest] = useState(null);

    useEffect(() => {
        const year = currentStartDate.getFullYear();
        const month = String(currentStartDate.getMonth() + 1).padStart(2, '0'); // +1 because JavaScript months are 0-indexed
        const day = String(currentStartDate.getDate()).padStart(2, '0');
        const formattedCurrentStartDate = `${year}-${month}-${day}`;

        const calculateDataset = async () => {
            // const lastSevenSomns = await getLastSevenSomns(user.uid);
            // const lastSevenSleepTimes = getSleepDiffs(lastSevenSomns);
            const hoursForRest = user.hoursForRest.split(' ')[0];
            const findIndexByProperty = (arr, prop, value) => {
                return arr.reduce((acc, curr, idx) => {
                  if (acc !== -1) return acc;  // If the index is already found, return it
                  return curr[prop] === value ? idx : -1;
                }, -1);
              };
            const currentDateIndex = findIndexByProperty(teamSleepDurationsVBW[user.uid], 'date', formattedCurrentStartDate);

            const lastSevenSleepTimes = teamSleepDurations[user.uid].slice(currentDateIndex, currentDateIndex + 7);
            const lastEightSomns = teamSleepDurationsVBW[user.uid].slice(currentDateIndex, currentDateIndex + 8);

            const naturalSleepTime = toSeconds(user.naturalSleepTime.toDate());
            const naturalWakeTime = toSeconds(user.naturalWakeTime.toDate());
            const value = calculateValue(lastSevenSleepTimes, hoursForRest, naturalSleepTime, naturalWakeTime, lastEightSomns);

            setUserValue(value);
            setWarning(warningCondition(value));
            setIsLoading(false); // stop loading when data is ready
            setHoursForRest(hoursForRest);
        }

        calculateDataset();
    }, [user, title, calculateValue, warningCondition, currentStartDate]);

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        circumference: 360, // Full circle
        rotation: 0,
        cutout: '70%', // Adjust this for a thicker/thinner gauge
        tooltips: { enabled: false },
        hover: { mode: null },
        plugins: {
            legend: { display: false },
            centerTextPlugin: {
                color: '#fff',
                anchor: 'center',
                align: 'center',
                formatter: function (value, context) {
                    if (context.dataIndex === 0) {
                        if (userValue === 0 && title === 'Sleep Debt') {
                            return userValue;
                        }
                        return value;
                    }
                },
                font: {
                    size: 20,
                    weight: '900',
                    family: 'Figtree',
                }
            }
        },
        userValue: userValue,
        titleText: title,
    }

    let actualValue = Math.round(userValue * Math.pow(10, 1)) / Math.pow(10, 1);

    if (title === 'Bedtime Variation' || title === 'Wake Time Variation') {
        actualValue = Math.floor(actualValue);
    }

    const data = {
        labels: ['Value', 'Remaining'],
        datasets: [{
            data: [(title === 'Sleep Debt' && actualValue === 0) ? hoursForRest : (actualValue > 0) ? actualValue : 0, (actualValue > hoursForRest) ? 0 : (title === 'Sleep Debt' && actualValue === 0) ? 0 : hoursForRest - actualValue],
            backgroundColor: appropriateColor(actualValue, title), // Color for value and remaining respectively
            borderWidth: [0, 0],
            borderAlign: 'center',
            borderJoinStyle: 'miter',
        }]
    }

    return (
        <Box sx={{ minWidth: 250, width: '25%', alignItems: 'center' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Figtree',
                    color: '#fff',
                    marginBottom: 5, // Add margin to separate the title from the gauge
                }}
            >
                <h2 style={{ fontFamily: 'Figtree', fontSize: 25, fontWeight: 900, color: '#fff', margin: 0, textAlign: 'center' }}>
                    {title}
                </h2>
            </Box>
            {
                userValue !== null &&
                <Doughnut options={options} data={data} plugins={[centerTextPlugin]}></Doughnut>
            }
        </Box>
    );
}

function appropriateColor(actualValue, title) {
    const darkenPercent = 0.65;
    switch (title) {
        case 'Sleep Duration':
            if (actualValue >= 0 && actualValue < 6) {
                return [Colors.ErrorRed, darkenColor(Colors.ErrorRed, darkenPercent)];
            } else if (actualValue >= 6 && actualValue < 7.5) {
                return [Colors.SoftYellow, darkenColor(Colors.SoftYellow, darkenPercent)];
            } else if (actualValue >= 7.5) {
                return [Colors.SuccessGreen, darkenColor(Colors.SuccessGreen, darkenPercent)];
            } else {
                return [Colors.BlueGreen500, Colors.DarkTeal500];
            }
        case 'Sleep Debt':
            if (actualValue <= 0) {
                return [Colors.SuccessGreen, darkenColor(Colors.SuccessGreen, darkenPercent)];
            }
            if (actualValue > 0 && actualValue < 4) {
                return [Colors.SuccessGreen, darkenColor(Colors.SuccessGreen, darkenPercent)];
            } else if (actualValue >= 4 && actualValue < 6) {
                return [Colors.SoftYellow, darkenColor(Colors.SoftYellow, darkenPercent)];
            } else if (actualValue >= 6) {
                return [Colors.ErrorRed, darkenColor(Colors.ErrorRed, darkenPercent)];
            } else {
                return [Colors.BlueGreen500, Colors.DarkTeal500];
            }
        case 'Bedtime Variation':
            if (actualValue <= 60) {
                return [Colors.SuccessGreen, darkenColor(Colors.SuccessGreen, darkenPercent)];
            }
            else {
                return [Colors.ErrorRed, darkenColor(Colors.ErrorRed, darkenPercent)];
            }
        case 'Wake Time Variation':
            if (actualValue <= 60) {
                return [Colors.SuccessGreen, darkenColor(Colors.SuccessGreen, darkenPercent)];
            }
            else {
                return [Colors.ErrorRed, darkenColor(Colors.ErrorRed, darkenPercent)];
            }
        default:
            if (actualValue >= 0 && actualValue < 2) {
                return [Colors.SuccessGreen, darkenColor(Colors.SuccessGreen, darkenPercent)];
            } else if (actualValue >= 2 && actualValue < 3) {
                return [Colors.SoftYellow, darkenColor(Colors.SoftYellow, darkenPercent)];
            } else if (actualValue >= 3) {
                return [Colors.ErrorRed, darkenColor(Colors.ErrorRed, darkenPercent)];
            } else {
                return [Colors.BlueGreen500, Colors.DarkTeal500];
            }
    }
}

export default Gauge;
