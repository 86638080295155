
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Chart, LinearScale, CategoryScale } from 'chart.js';
import { ViolinController, ViolinChart, BoxPlotController } from '@sgratzl/chartjs-chart-boxplot';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import '../../../src/fonts.css';
import { debounce, max } from 'lodash';
import { useChartContext } from '../../contexts/ChartContext';
import mixpanel from 'mixpanel-browser';

import {
  getLastSevenSomns, getRandomColor, getSleepDiffs,
  getLastSevenTimeZones, getLocationAndStamps,
  Colors, getProcessedLastSevenSomns, formatDateLabels,
  PRIVACY_LEVELS, PRIVACY_LEVELS_REVERSED,
  PRIVACY_LEVELS_STRINGS, validateDataAlignment, generateDateLabels, getSevenDaysAgo,
  formatDateToWeekdayMMDDYY, alignDataWithLabels, getProcessedChartData, getLastEightSomns, hexToRgba, setPlotThreshold, getPlotThreshold, logEvent
} from '../../helpers/utils';
import { Button, makeStyles } from '@material-ui/core';
import { Color } from '@amcharts/amcharts5';

function createGoodBadZonesPlugin(sliderValueRef) {
  return {
    id: 'goodBadZonesPlugin',
    beforeDatasetsDraw: function (chart) {
      const ctx = chart.ctx;
      ctx.strokeStyle = 'transparent'; // Set strokeStyle to transparent
      ctx.lineWidth = 0;               // Set lineWidth to 0
      const yScale = chart.scales['y'];
      const xScale = chart.scales['x'];  // Access the X-Axis scale
      const titleText = chart.options.titleText;

      // Define the threshold

      xScale.ticks.forEach((tick, index) => {
        // const threshold = (titleText === 'Bedtime Variation Spread' || titleText === 'Wake Time Variation Spread') ? sliderValue : 7;
        const threshold = sliderValueRef.current;
        const thresholdPixel = yScale.getPixelForValue(threshold);
        const xLeft = xScale.getPixelForTick(index) - xScale.width / (xScale.ticks.length * 2);
        const xRight = xScale.getPixelForTick(index) + xScale.width / (xScale.ticks.length * 2);

        if (titleText === 'Sleep Duration Spread') {
          // Shade everything above the threshold in green
          ctx.fillStyle = hexToRgba(Colors.SuccessGreen, 0.5);
          ctx.fillRect(xLeft, yScale.top, xRight - xLeft, thresholdPixel - yScale.top);

          // Shade everything below the threshold in red
          ctx.fillStyle = hexToRgba(Colors.ErrorRed, 0.5);
          ctx.fillRect(xLeft, thresholdPixel, xRight - xLeft, yScale.bottom - thresholdPixel);
        }
        else {

          // Shade everything above the threshold in red
          ctx.fillStyle = hexToRgba(Colors.ErrorRed, 0.5);
          ctx.fillRect(xLeft, yScale.top, xRight - xLeft, thresholdPixel - yScale.top);

          // Shade everything below the threshold in green
          ctx.fillStyle = hexToRgba(Colors.SuccessGreen, 0.5);
          ctx.fillRect(xLeft, thresholdPixel, xRight - xLeft, yScale.bottom - thresholdPixel);
        }
      });
    }
  };
}

// register controller in chart.js and ensure the defaults are set
Chart.register(BoxPlotController, ViolinChart, ViolinController, LinearScale, CategoryScale);

const useStyles = makeStyles({
  trackSleepDuration: {
    width: '12px !important',
    borderRadius: 4,
    backgroundColor: Colors.ErrorRed,
    opacity: 1,
  },
  trackRest: {
    width: '12px !important',
    borderRadius: 4,
    backgroundColor: Colors.SuccessGreen,
    opacity: 1,
  },
  railSleepDuration: {
    width: '12px !important',
    borderRadius: 4,
    backgroundColor: Colors.SuccessGreen,
    opacity: 1,
  },
  railRest: {
    width: '12px !important',
    borderRadius: 4,
    backgroundColor: Colors.ErrorRed,
    opacity: 1,
  },
  thumb: {
    height: 24,
    width: 24,
    color: 'rgba(75, 192, 192, 1)',
  },
  valueLabel: {
    left: 'calc(-50% + 8px)', // adjust the label positioning to be centered
    top: -24, // adjust as needed for vertical centering
  }
});

function ViolinPlot({ title, user, team, organization, selectedUser, setSelectedUser, 
  privacyLevels, memberLabels, teamSleepDurations, teamSleepDurationsVBW, 
  calculateValue, currentStartDate, previousDaysCutoff }) {
  const classes = useStyles();
  const { yMaxViolin, setYMaxViolin, yMaxSleepDuration, setYMaxSleepDuration } = useChartContext();

  const canvasRef = useRef(null);
  const chartRef = useRef(null);  // Store a reference to the chart instance

  const [chartData, setChartData] = useState([]);
  const [sliderValue, setSliderValue] = useState(null);

  useEffect(() => {
    async function fetchThreshold() {
      const threshold = await getPlotThreshold(user.uid, organization, title);
      setSliderValue(threshold);
    }

    fetchThreshold();
  }, [organization, title]);

  // const labels = formatDateLabels();
  // const { startDate } = getSevenDaysAgo();
  let labels = generateDateLabels(currentStartDate, previousDaysCutoff);
  // let labels = allLabels.slice(1);
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const initializeEmptyData = labels => {
    return labels.map(label => ({
      t: label,
      y: null,
      sleepTime: null,
      wakeTime: null,
      sleepTimeReported: false,
      wakeTimeReported: false,
    }));
  };
  const userLocations = new Set();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeoutReached(true);
    }, 7000); // 5 seconds

    // Clear the timeout if the component is unmounted before the timeout finishes
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {

    const calculateDatasets = async () => {
      let maxValue = 0;
      let allData = Array(7).fill().map(() => []);

      if (team && Array.isArray(team)) {
        for (let user of team) {
          const privacyLevel = privacyLevels.get(user.uid);
          if (privacyLevel === PRIVACY_LEVELS.NONE) {
            continue;
          }


          const sleepDiffs = teamSleepDurations[user.uid];
          const sleepDiffsVBW = teamSleepDurationsVBW[user.uid];
          const lastSevenSomns = getProcessedChartData(labels, sleepDiffs);
          const lastEightSomns = getProcessedChartData(labels, sleepDiffsVBW);

          let value = calculateValue(lastSevenSomns, lastEightSomns);
          value.forEach((field, index) => {
            if (field !== null && field !== undefined) {
              if (field > yMaxViolin && title !== 'Sleep Duration Spread') {
                setYMaxViolin(prevYMaxViolin => Math.max(prevYMaxViolin, field));
              } else if (field > yMaxViolin && title === 'Sleep Duration Spread') {
                setYMaxSleepDuration(prevYMaxSleepDuration => Math.round(Math.max(prevYMaxSleepDuration, field), 0));
              }
              allData[index].push(field);
            }
          });
          
        }
      }
      setChartData(allData);
    }

    calculateDatasets();
    setDataLoaded(true);

  }, [team, selectedUser, calculateValue, currentStartDate]);

  // useEffect() is going to run every single time either team or selected user updates its state

  const commonTimezonesArray = Array.from(userLocations); // Assuming commonLocations is a Set
  const mostRecentTimezone = commonTimezonesArray[commonTimezonesArray.length - 1];
  const violinPlotData = {
    // define label tree
    labels: labels.map(dateStr => formatDateToWeekdayMMDDYY(dateStr, mostRecentTimezone)),
    datasets: [{
      borderColor: '#fff',
      borderWidth: 2.5,
      meanRadius: 15,
      meanBackgroundColor: 'rgba(256, 256, 256, 0.5)',
      meanBorderWidth: 2.5,
      meanBorderColor: '#fff',
      outlierBackgroundColor: Colors.ErrorRed,
      outlierBorderColor: Colors.ErrorRed,
      outlierRadius: 5,
      backgroundColor: 'rgba(75, 192, 192, 1)',
      data: chartData,
    }]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      goodBadZonesPlugin: true,
    },
    title: {
      display: false,
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: (title === 'Bedtime Variation Spread' || title === 'Wake Time Variation Spread') ? 'Minutes' : 'Hours',
          color: 'white',
          font: {
            size: 20,
            family: 'Figtree',
          },
        },
        ticks: {
          color: 'white',
          font: {
            size: 15,
            weight: 900,
          },
        },
        grid: {
          display: true,
          color: '#23383C',
          drawOnChartArea: true,
          drawTicks: true,
          lineWidth: 2.5,
        },
        border: {
          display: false,
        },
        max: title === 'Sleep Duration Spread' ? yMaxSleepDuration + 2 : yMaxViolin + 25,
      },
      x: {
        type: 'category',
        ticks: {
          color: 'white',
          font: {
            size: 14,
            weight: 900,
            family: 'Figtree',
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
    titleText: title,
  };

  const [yMaxValue, setYMaxValue] = useState(null);
  const [gridHeight, setGridHeight] = useState(null);

  const createChart = () => {
    const ctx = canvasRef.current.getContext('2d');
    chartRef.current = new ViolinChart(ctx, {
      data: violinPlotData,
      options: options,
      plugins: [createGoodBadZonesPlugin(sliderValueRef)],
    });
  };

  useEffect(() => {
    if (!chartRef.current) {
      createChart();
    } else {
      chartRef.current.data = violinPlotData;
      chartRef.current.options = options;
      chartRef.current.update('none'); // Update without animations
    }

    const maxY = chartRef.current.scales['y'].max;
    setYMaxValue(maxY);
    const gridHeightValue = chartRef.current.scales['y'].bottom - chartRef.current.scales['y'].top - chartRef.current.chartArea.top;
    setGridHeight(gridHeightValue);
    // if (title === 'Sleep Duration Spread') {
    //   setGridHeight(gridHeightValue + 2);
    // } else {
    //   setGridHeight(gridHeightValue + 25);
    // }
    

  }, [violinPlotData, options]); 

  const sliderValueRef = useRef(null);

  useEffect(() => {
    sliderValueRef.current = sliderValue;
    if (chartRef.current) {
      chartRef.current.update('none'); // Update the chart without animations
    }
  }, [sliderValue]);

  const handleSliderChange = useCallback(debounce((event, newValue) => {
    mixpanel.track("Changed Violin Plot Threshold", { userId: user.uid, organization: organization, title: title, threshold: newValue });
    setSliderValue(newValue);
    if (chartRef.current) {
      chartRef.current.update('quiet'); // Update the chart without animations
    }
  }, 10), []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <canvas ref={canvasRef}></canvas>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {sliderValue !== null && (
          <Slider
            style={{ height: `${gridHeight}px`, padding: '0 13px 0 0' }} // give a height here
            orientation="vertical"
            value={sliderValue}
            onChange={handleSliderChange}
            onChangeCommitted={() => {
              setPlotThreshold(user.uid, organization, title, sliderValue);
            }}
            min={0}
            step={title === 'Sleep Duration Spread' ? 0.1 : 1}
            max={Math.ceil(yMaxValue)}
            aria-labelledby="vertical-slider"
            valueLabelDisplay="on"
            classes={{ track: title === 'Sleep Duration Spread' ? classes.trackSleepDuration : classes.trackRest, rail: title === 'Sleep Duration Spread' ? classes.railSleepDuration : classes.railRest, thumb: classes.thumb, valueLabel: classes.valueLabel }}
          />)}
      </div>
    </div>
  );


}


export default ViolinPlot;
