import React from 'react';
import { Container, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../../src/assets/somnlogo.png";
import socials from "../../src/assets/socials.png";
import styled from '@emotion/styled';

const GOOGLE_FORM_URL = "https://form.typeform.com/to/Plr5zXC4";

function handleFeedbackButtonClick() {
  const newWindow = window.open(GOOGLE_FORM_URL, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}


const FeedbackButton = styled.button`
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-block;  // Changed from block to inline-block

  &:hover {
    filter: contrast(115%);
  }

  &:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.5;
    cursor: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#011D23',
    padding: theme.spacing(4, 0),
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
    
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 'auto',
    marginLeft: theme.spacing (5),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginRight: 0,
      marginLeft: theme.spacing (2),
      width: '100%',
    },
  },


  logo: {
    width: 160,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 160,
      display: 'flex',
      marginRight: 'auto',
      marginLeft: theme.spacing(2)
    },
  },

  mission: {
    width: '41%',
    color: '#546E7A',
    margin: theme.spacing (4, 0),
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },
  },

  socials: {
    width: 250,
    height: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 'auto',
    marginRight: theme.spacing (10),
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
  },

}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt="Somn Logo" />

        <div style={{ textAlign: 'left' }}>
          <FeedbackButton onClick={handleFeedbackButtonClick}>Send feedback</FeedbackButton>
        </div>


        <Typography variant="body2" color="textSecondary" className={classes.mission} >
         Our mission is to help people live healthier lives by improving sleep outcomes through technology.
        </Typography>
        </div>
      </Container>
      <img className={classes.socials} src={socials} alt="socials" />

    </footer>
  );
};

export default Footer;
