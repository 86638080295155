import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { authStates, withAuth } from "../components/auth";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import en from "../utils/greetingsEn";
import { signIn } from "../utils/Firebase";
import { validateEmailPassword } from "../utils/helpers";
import { withStyles } from '@material-ui/core/styles';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import "../styles/login.css";
import logo from "../../src/assets/somnlogo2.png";
import TextField from '@material-ui/core/TextField';
import Loading from "./Loading";
import { logEvent } from "../helpers/utils";
import Mixpanel from 'mixpanel-browser';

const styles = theme => ({
  heroSection: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#011D23',
    padding: theme.spacing(8, 0),
    alignItems: 'center',
    flexDirection: 'row',
    
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  logo: {
    width: '20%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },

  formOutline: {
    border: '1px solid #000',
    borderRadius: '5px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(0),
  },

  textField: {
    backgroundColor: '#1C434B',
    '& label.Mui-focused': {
      color: 'purple',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'purple',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: 'red',
      },
      '&:hover fieldset': {
        // borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        // borderColor: 'green',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#B7B7B7',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',  // You can adjust the values here
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -1px) scale(0.75)',  // You can adjust the values here
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },

  title: {
    fontWeight: 900,
    marginBottom: theme.spacing(4),
    color: '#fff',
    textAlign: 'left',
    fontFamily: 'Figtree',
    fontSize: 70,
    marginRight:'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    color: '#fff',
    textAlign: 'left',
    fontSize: 27,
    fontFamily: 'Figtree',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center',
    },
  },
  image: {
    maxWidth: 530,
    height: 'auto',
    marginRight: 80,
    
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      height: 'auto',
      alignItems: 'center',
      marginRight: 0,
    },
  },

  howItWorks: {
    background: '#011D23', 
    textAlign: 'center',
    fontWeight: 900,
    fontFamily: 'Figtree',
    fontSize: 70,
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
    },
    
  },

  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', 
    flexDirection: 'row',
    background: '#011D23',
  },

  cards: {
    backgroundColor: '#0F3038',
    color: '#fff',
    maxWidth: '25%',
    height: 320,
    paddingBottom: theme.spacing(4),
    margin: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      maxHeight: 170,
      maxWidth: '100%',
      paddingBottom: theme.spacing(3),
    },
  },

  cardTitle: {
    fontFamily: 'Figtree',
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0.5),
    },
  },
  cardDescription: {
    fontFamily: 'Figtree',
    fontSize: 18,
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      fontSize: 7,
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(4)
    },
  },

  comps: {
    width: 94, 
    margin: 40,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      margin: 15,
    },
  },

  loginButton: {
    textTransform: 'none',
    backgroundColor: '#DEE8A1',
    color: 'black',
    fontFamily: 'Figtree',
    fontWeight: 600,
    fontSize: 17.5,
    padding: '12px 24px', 
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, color 0.3s, transform 0.2s',
    '&:hover': {
      backgroundColor: '#524CD8',
      color: '#fff',
      transform: 'scale(1.02)',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
  }
});


function Login(props) {
  const [state, setState] = useState({
    email: "",
    password: "",
    retype: "",
    error: "",
  });
  const navigate = useNavigate();
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [passwordResetMessage, setPasswordResetMessage] = useState("");
  const hasTracked = useRef(false);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setState(prevState => ({
      ...prevState,
      [name]: value,
      error: "",
    }));
  }

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberMeEmail");
    if (rememberedEmail) {
      setState(prevState => ({
        ...prevState,
        email: rememberedEmail,
      }));
    }

    if (!hasTracked.current) {
      Mixpanel.track_pageview({"page": "Login"});
      hasTracked.current = true;
    }
  }, []);

  function handleForgotPassword(event) {
    event.preventDefault();
    const auth = getAuth();
    const emailAddress = state.email;
  
    sendPasswordResetEmail(auth, emailAddress)
      .then(() => {
        // Email sent. Show the message.
        setPasswordResetMessage("Successfully sent email!");
        setShowPasswordReset(false);
      })
      .catch((error) => {
        // An error occurred.
        setState((prevState) => ({
          ...prevState,
          error: `Error: ${error.message}`,
        }));
      });
  }
  
  

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.error) {
      return;
    }

    Mixpanel.track("Web: Login button clicked");
  
    const errorMsg = validateEmailPassword(state.email, state.password);
  
    if (errorMsg) {
      setState(prevState => ({
        ...prevState,
        error: errorMsg,
      }));
      return;
    }
  
    signIn(state.email, state.password)
      .then(userCredential => {
        const user = userCredential.user;

        const temporaryUserId = Mixpanel.get_distinct_id();
        Mixpanel.alias(user.uid, temporaryUserId)

        Mixpanel.identify(user.uid);

        setTimeout(() => {
          Mixpanel.people.set({
            "$email": user.email,
            "$created": user.metadata.creationTime
          });
        }, 500); // Delay in milliseconds

        logEvent(user, "login", user.uid + " logging in ...");
        return user;
      })
      .then(() => {
        navigate('/dashboard'); // Navigate to dashboard after ensuring user properties
      })
      .catch(e => {
        console.error("Error signing in", e);
        if (e.code === "auth/user-not-found") {
          setState(prevState => ({
            ...prevState,
            error: "User not found. Please create an account",
          }));
          return;
        }
  
        if (e.code === "auth/wrong-password") {
          setState(prevState => ({
            ...prevState,
            error: "Incorrect email/password",
          }));
          return;
        }

        // Handle other errors
        setState(prevState => ({
          ...prevState,
          error: "Unknown error occurred",
        }));
        return;

      });
  }
  

  const { classes } = props;
  if (props.authState === authStates.INITIAL_VALUE) {
    return <Loading />;
  }

  if (props.authState === authStates.LOGGED_IN) {
    navigate('/dashboard');
  }

  const errorMsg = state.error;
  
  return (
    <>
      <Navbar />
      <section className="vh-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#011D23' }}>
        <div className="container-fluid h-custom">
          <div className={`${classes.formOutline} card rounded-3 text-white`} style={{ backgroundColor: '#0F3038' }}>
            <div className="row d-flex justify-content-center align-items-center h-100">
              <img className={classes.logo} src={logo} alt="Logo" />
              <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form onSubmit={showPasswordReset ? handleForgotPassword : handleSubmit}>
                  <div className="divider d-flex align-items-center my-4">
                    <p className="text-center fw-bold mb-0" style={{
                      color: '#fff',
                      textAlign: 'left',
                      fontSize: 27,
                      fontFamily: 'Figtree',
                      fontWeight: 600, }}>
                        {showPasswordReset ? 'Reset Password' : 'Log in with email'}
                      </p>
                  </div>
                  {/* Email input */}
                  <div className="form-outline mb-4" style={{ backgroundColor: '#fff' }}>
                    <TextField
                      id="typeEmail"
                      label="Email address"
                      type="email"
                      variant="outlined"
                      placeholder={en.FORM_FIELDS.EMAIL}
                      onChange={handleInputChange}
                      name="email"
                      className={classes.textField}
                      InputProps={{
                        style: { color: '#fff' },
                      }}
                      required
                      fullWidth
                    />
                  </div>
                  {/* Password input - Only show if not resetting password */}
                  {!showPasswordReset && (
                    <div className="form-outline mb-3" style={{ backgroundColor: '#fff' }}>
                      <TextField
                        id="typePassword"
                        label="Password"
                        type="password"
                        variant="outlined"
                        placeholder={en.FORM_FIELDS.PASSWORD}
                        onChange={handleInputChange}
                        name="password"
                        className={classes.textField}
                        InputProps={{
                          style: { color: '#fff' },
                        }}
                        required
                        fullWidth
                      />
                    </div>
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                  </div>
                  <div className="text-center text-lg-start mt-2 pt-1">
                      {passwordResetMessage && <p className="success">{passwordResetMessage}</p>}
                      {errorMsg && <p className="error">{errorMsg}</p>}

                      <div className="d-flex align-items-center justify-content-between"> {/* Adjusted this flexbox container */}
                        <button
                          type="submit"
                          className={classes.loginButton}
                          id="login-button"
                        >
                          {showPasswordReset ? 'Reset Password' : 'Login'}
                        </button>

                        {showPasswordReset && (
                          <a href="#!"   
                            onClick={() => setShowPasswordReset(false)}
                            className="text-body"
                            style={{
                              fontFamily: 'Figtree',
                              fontSize: 18,
                              color: '#fff',
                            }}
                          >
                            Back to login
                          </a>
                        )}
                      </div>
                      <p style={{ fontFamily: 'Figtree', fontSize: 18, marginTop: 20 }}>
                        Don't have an account yet? <Link to="/signup">Sign Up</Link>
                      </p>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
  
  


}

export default withStyles(styles)(withAuth(Login));
