import { React, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip } from '@mui/material';
import { Colors, PRIVACY_LEVELS, getLastSevenSomns, getSleepDiffs } from '../../helpers/utils';
import '../../../src/fonts.css';
import { RestoreFromTrashRounded } from '@material-ui/icons';
import { current } from '@reduxjs/toolkit';

const useStyles = makeStyles({
    averageSleepDuration: {
        fontFamily: 'Figtree',
        fontWeight: 900,
        fontSize: 70,
        textAlign: 'left',
        color: '#fff',
        mr: 1,
        mt: -1,
    },

    hours: {
        fontFamily: 'Figtree',
        fontWeight: 'normal',
        fontSize: '25px',
        color: '#fff'
    },

    durationTitle: {
        fontFamily: 'Figtree',
        fontWeight: 900,
        fontSize: '25px',
        color: '#fff',
        mt: -2,
        mb: -2
    },
})

function CapsuleHeader({ team, selectedUser, calculateValue, memberLabels, teamSleepDurations, currentStartDate }) {
    let [teamData, setTeamData] = useState([]);
    const [showBlurb, setShowBlurb] = useState(false);
    const classes = useStyles();

    const findIndexByProperty = (arr, prop, value) => {
        return arr.reduce((acc, curr, idx) => {
            if (acc !== -1) return acc;  // If the index is already found, return it
            return curr[prop] === value ? idx : -1;
        }, -1);
    };

    const blurbMessage = "Please remind users to open their apps once per day to ensure their sleep is correctly populated";

    useEffect(() => {
        const getFormattedDate = (dateObj) => {
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        }

        let tempDate = new Date(currentStartDate);
        let formattedTempDate = getFormattedDate(tempDate);
    
        let currentDateIndex = findIndexByProperty(teamSleepDurations[selectedUser.uid], 'date', formattedTempDate);

        let offset = 0; // to keep track of how many days we shifted

        if(currentDateIndex === -1) {
            for(let i = 1; i < 7; i++) {
                tempDate.setDate(tempDate.getDate() + 1);
                formattedTempDate = getFormattedDate(tempDate);
    
                currentDateIndex = findIndexByProperty(teamSleepDurations[selectedUser.uid], 'date', formattedTempDate);
                if(currentDateIndex !== -1) {
                    // Found a valid index for one of the days in the 7-day spread
                    offset = i;
                    break;
                }
            }
        }

        const generateExpectedDates = (startDate, days) => {
            const dates = [];
            let currentDate = new Date(startDate);
        
            for(let i = 0; i < days; i++) {
                const year = currentDate.getFullYear();
                const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // +1 because JavaScript months are 0-indexed
                const day = String(currentDate.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                
                dates.push(formattedDate);
                currentDate.setDate(currentDate.getDate() + 1);
            }
        
            return dates;
        }        
        
        const expectedDates = generateExpectedDates(currentStartDate, 7);

        //console.log("expectedDates: ", expectedDates);
        
        const daysWithoutReports = expectedDates.filter(expectedDate => {
            const found = teamSleepDurations[selectedUser.uid].slice(currentDateIndex, currentDateIndex + 7 - offset).find(day => 
                day.date === expectedDate
            );
            //console.log("found: ", found);
            return !found || !(found.sleepTimeReported && found.wakeTimeReported);
        }).length;

        const emptySomnThresholdToShowBlurb = 1;

        // Update the state
        setShowBlurb(daysWithoutReports >= emptySomnThresholdToShowBlurb);

        //console.log("Sliced Data: ", teamSleepDurations[selectedUser.uid].slice(currentDateIndex, currentDateIndex + 7));
        //console.log("Days without reports: ", daysWithoutReports);


        const calculateDatasets = async () => {
            if (!team || team.length === 0) {
                setTeamData([]);
                return;
            }

            let teamData = await Promise.all(team.map(async (user) => {
                if (!team) {
                    return;
                }
                // const lastSevenSomns = await getLastSevenSomns(user.uid);
                // const lastSevenSleepTimes = getSleepDiffs(lastSevenSomns);
                const lastSevenSleepTimes = teamSleepDurations[user.uid];
                const value = calculateValue(lastSevenSleepTimes);
                return {
                    firstName: memberLabels.get(user.uid),
                    value,
                };
            }));

            if (!team && teamData) {
                setTeamData([]);
                teamData = [];
            }

            teamData = teamData.filter(memberData => memberData != null);
            const userNameAndAvgSleep = teamData.filter(elt => elt.firstName === memberLabels.get(selectedUser.uid));

            setTeamData(userNameAndAvgSleep);

        }

        calculateDatasets();
    }, [team, memberLabels, calculateValue, currentStartDate]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {teamData.map((member, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', margin: '10px 40px', mt: 4 }}>

                    {/* First Name */}
                    <Box sx={{
                        minWidth: 350,
                        fontFamily: 'Figtree',
                        fontWeight: 900,
                        fontSize: 70,
                        textAlign: 'left',
                        color: '#fff',
                        marginRight: 2
                    }}>
                        {member.firstName}
                    </Box>

                    {/* Value Card */}
                    <Card style={{
                        backgroundColor: '#28555F',
                        borderRadius: 25,
                        border: '0px',
                        minWidth: 210
                    }}>
                        <CardContent sx={{
                            padding: '0px 20px',  // Adjust as needed. Format: 'top/bottom left/right'.
                            textAlign: 'left'
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>

                                {/* Member Value */}
                                <Typography className={classes.averageSleepDuration} sx={{
                                    fontFamily: 'Figtree',
                                    fontWeight: 900,
                                    fontSize: 70,
                                    textAlign: 'left',
                                    color: '#fff',
                                    mr: 1,
                                    mt: -1,
                                }}>
                                    {Math.round(member.value * Math.pow(10, 1)) / Math.pow(10, 1)}
                                </Typography>

                                {/* Hours with different styling */}
                                <Typography className={classes.hours} sx={{
                                    fontFamily: 'Figtree',
                                    fontWeight: 'normal',
                                    fontSize: '25px',
                                    color: '#fff'
                                }}>
                                    hours
                                </Typography>
                            </Box>
                            <Typography className={classes.durationTitle} sx={{
                                fontFamily: 'Figtree',
                                fontWeight: 900,
                                fontSize: '25px',
                                color: '#fff',
                                mt: -2,
                                mb: -2
                            }}>Avg sleep</Typography>
                        </CardContent>
                    </Card>

                    {showBlurb && (
                        <Box style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <Tooltip title={showBlurb ? blurbMessage : "Click for more info"}>
                                <ErrorIcon
                                    fontSize="large"  // Change "small" to "large"
                                    style={{
                                        marginLeft: '8px',
                                        color: Colors.ErrorRed,
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    )}



                </Box>
            ))}
        </Box>
    );

}

export default CapsuleHeader
