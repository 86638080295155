import React from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Typography, Box, IconButton } from '@material-ui/core';
import '../../../src/fonts.css';
import { logEvent } from '../../helpers/utils';

function DateSpread({ user, startDate, setStartDate, daysBeingFetched, organization }) { // Destructure from props
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);  // Assuming 7-day spread

    const isEndBeforeLastNight = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);  // Resetting to 12:00:00 AM of today
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1); // Subtracting a day to get yesterday
        return endDate < yesterday;
    };

    const isStartAfterMinimumDate = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const earliestAllowedStartDate = new Date(today);
        earliestAllowedStartDate.setDate(today.getDate() - (daysBeingFetched - 7)); // Adjusted logic here

        const resetStartDate = new Date(startDate);
        resetStartDate.setHours(0, 0, 0, 0);

        return resetStartDate >= earliestAllowedStartDate;
    };



    const calculateDateRange = (baseDate) => {
        const days = [];
        for (let i = 0; i <= 6; i++) {
            const date = new Date(baseDate);
            date.setDate(baseDate.getDate() + i);
            const formattedDate = date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
            days.push(formattedDate);
        }
        return days;
    }

    const moveDateRange = (direction) => {
        const newStartDate = new Date(startDate);
        newStartDate.setDate(startDate.getDate() + direction * 7);
        setStartDate(newStartDate);
    }

    const days = calculateDateRange(startDate);

    const hiddenButtonStyle = {
        visibility: 'hidden'
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
            <IconButton
                onClick={() => {
                    if (isStartAfterMinimumDate()) {
                        moveDateRange(-1);
                        logEvent(user?.uid, "date-picker-left-arrow-clicked", 'Clicked the left arrow button on the date spread picker');
                    } else {
                        logEvent(user?.uid, "date-picker-left-arrow-clicked", "Tried to click the left arrow button on the date spread picker but was not allowed");
                    }
                }}
                aria-label="Previous week"
                style={!isStartAfterMinimumDate() ? hiddenButtonStyle : {}}
            >
                <ArrowLeftIcon style={{ color: 'white' }} />
            </IconButton>
            <Typography style={{ fontFamily: 'Figtree', fontWeight: 900, fontSize: 20 }}>
                {`${days[0]} - ${days[6]}`}
            </Typography>
            <IconButton
                onClick={() => {
                    if (isEndBeforeLastNight()) {
                        moveDateRange(1);
                        logEvent(user?.uid, "date-picker-right-arrow-clicked", 'Clicked the right arrow button on the date spread picker');
                    } else {
                        logEvent(user?.uid, "date-picker-right-arrow-clicked", "Tried to click the right arrow button on the date spread picker but was not allowed");
                    }
                }}
                aria-label="Next week"
                style={!isEndBeforeLastNight() ? hiddenButtonStyle : {}}
            >
                <ArrowRightIcon style={{ color: 'white' }} />
            </IconButton>
        </Box>

    );
}

export default DateSpread;
