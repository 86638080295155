import React, { useState } from 'react';
import {AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, Hidden,} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import logo from "../../src/assets/somnlogo.png";
import '../../src/fonts.css';
import { useNavigate } from 'react-router-dom';
import { signOut_ } from '../utils/Firebase';
import { selectUser } from '../slices/UserSlice';
import { useSelector } from 'react-redux';
import { logEvent } from '../helpers/utils';
import Mixpanel from 'mixpanel-browser';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: '#011D23',
    color: '#fff',
    fontFamily: 'Figtree',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#fff', 
      color: '#011D23', 
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 200,
    height: 'auto',
    marginRight: 'auto',
    marginLeft: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      width: '15%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },

  appBar: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(0),
    backgroundColor: '#011D23',
    color: '#fff',
  },

  buttons: {
    fontFamily: 'Figtree',
    textTransform: 'none',
    fontSize: 17.5,
    fontWeight: 600,
    marginLeft: 20,
    color: '#fff',
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      marginLeft: 5,    
    },
  },
    
  downloadButton: {
    textTransform: 'none',
    fontFamily: 'Figtree',
    fontSize: 17.5,
    marginLeft: 20,
    fontWeight: 600,
    backgroundColor: '#DEE8A1',
    color: '#011D23',
    padding: '12px 24px',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, transform 0.2s, box-shadow 0.3s',
    '&:hover': {
      backgroundColor: '#524CD8',
      color: '#fff',
      transform: 'scale(1.05)',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },     
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  menuButton: {
    marginLeft: 'auto',
    alignItems: 'right',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

 
}));


const Navbar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(selectUser);

  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut_()
      .then(() => {
        logEvent(user, "Sign out", user.uid + " signing out ...");
        Mixpanel.reset();
        console.log("Signed out")
      })
      .then(() => navigate('/'))
      .catch((e) => console.log("Error signing out: ", e));
  }

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <img className={classes.logo} src={logo} alt="Logo" onClick={() => navigate('/')}/>
        <Hidden smDown>
        <Typography variant="h6" component="div">
          

          { (user?.user?.user) || (user?.uid) ? (
          <Button className={classes.downloadButton} color="inherit" onClick={() => handleSignOut(user)}>Sign out</Button>
          ) : (<>
          <Button color="inherit" className={classes.buttons}>About</Button>
          <Button color="inherit" className={classes.buttons}>How it works</Button>
          </>)
          }
        </Typography>

        

        
        </Hidden>

        {/* dropdown hamburger icon */}
        <Hidden mdUp>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        </Hidden>

        {/* dropdown menu mobile devices*/}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          className={classes.menuButtons}
        >
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;