import { React, useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { auth } from '../utils/Firebase';
import Navbar from '../components/NavBar';
import Confirmation from '../components/Confirmation';
// import Header from './Header';
import Footer from '../components/Footer';
import { joinURL } from "../helpers/utils";
import { useLocation } from 'react-router-dom';
import { set } from 'date-fns';


const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(8),
  },
}));

const ConfirmationPage = ({
  uniqueCode,
  organization,
  editingName,
  setEditingName,
  updateAndSwitchToReadMode,
  organizationName,
  setOrganizationName,
  showError,
  setShowError
}) => {

  const location = useLocation();
  const classes = useStyles();

  return (
    <div>
      <Navbar />
      
      <Confirmation 
        code={uniqueCode}
        organization={organization} 
        editingName={editingName} 
        setEditingName={setEditingName}
        updateAndSwitchToReadMode={updateAndSwitchToReadMode}
        organizationName={organizationName}
        setOrganizationName={setOrganizationName}
        showError={showError}
        setShowError={setShowError}
      />

      <Footer />
    </div>
  );
};

export default ConfirmationPage;