import React, { useState, useEffect, useRef } from "react";
import { Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { PRIVACY_LEVELS_STRINGS } from '../helpers/utils';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import styled from '@emotion/styled';
import { css, Global } from '@emotion/react';
import '../../src/fonts.css';
import { useSelector } from "react-redux";
import { selectUser } from "../slices/UserSlice";
import Mixpanel from "mixpanel-browser";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  upperPortion: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%'
        
    }
  },

  portion: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        
    }
  },

  styledNumberTextField: {
    '& .MuiInputBase-input': {
      fontSize: '0.9em',
      color: '#fff', // If you want the text to be white
      padding: '10px',
      backgroundColor: '#1C434B',
      border: '1px solid #1C434B',
      borderRadius: '10px',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove underline effect
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none', // Remove underline effect on hover
    },
    width: '100%', // Assumes you want the TextField to take the full width
    marginBottom: '15px', // Margin bottom as specified
  },
  

  form: {
    fontFamily: 'Figtree',
    fontWeight: 900,
    width: 1000,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },

  bigHeader: {
    fontFamily: 'Figtree',
    fontWeight: 900,
    color: '#fff',
    fontSize: 26,
    textAlign: 'left',
    marginTop: 40,
    marginLeft: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    // font color white
    fontFamily: 'Figtree',
    fontWeight: 900,
    minWidth: 120,
    backgroundColor: '#1C434B', // Adjusted to your screenshot background
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white', // Set the border color
      },
      '&:hover fieldset': {
        borderColor: 'white', // Set the border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Set the border color on focus
      },
      '& .MuiSelect-select': {
        color: 'white', // Set text color
      },
    },
    '& .MuiSelect-select.MuiSelect-select': {
      color: 'white', // This ensures the selected text is white
    },    
  },
  address: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  viewingPreference: {
    [theme.breakpoints.down('md')]: {
      width: '110%',
    },
  },

  cardNo: {
    width: '45%',
    [theme.breakpoints.down('md', 'sm')]: {
      width: '100%',
    },
  },

  expiry: {
    width: '20%',
    [theme.breakpoints.down('md', 'sm')]: {
      width: '100%',
    },
  },

  cvc: {
    width: '15%',
    [theme.breakpoints.down('md', 'sm')]: {
      width: '100%',
    },
  }
}))

const Button = styled.button`
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;

  &:hover {
    filter: contrast(115%);
  }

  &:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.5;
    cursor: none;
  }
`;

const Input = styled.input`
  display: block;
  font-size: 0.9em;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #1C434B;
  background-color: #1C434B;
  border-radius: 10px;
`;



export default function OrganizationBuilderForm({ subscription }) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [viewingPreference, setViewingPreference] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [adminUserFirstName, setAdminUserFirstName] = useState('');
  const [adminUserLastName, setAdminUserLastName] = useState('');
  const [adminUserViewingPreference, setAdminUserViewingPreference] = useState('');

  const [organizationName, setOrganizationName] = useState('');
  const [organizationCapacity, setOrganizationCapacity] = useState(5);


  const classes = useStyles();

  const hasTracked = useRef(false);

  useEffect(() => {
    if (!hasTracked.current) {
      Mixpanel.track_pageview({"page": "/organization-builder"});
      hasTracked.current = true;
    }
  }, []);


  const createOrganization = async (user) => {
    const baseUrl = "https://us-central1-somnapp-5fd69.cloudfunctions.net";
    const token = await user.getIdToken();

    if (!organizationName || !organizationCapacity) {
      setMessage("Please fill out all fields");
      throw new Error("Please fill out all fields");
    };

    const organizationData = {
      userId: user.uid,
      organizationName: organizationName,
      organizationCapacity: organizationCapacity,
      adminUserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone 
    };
  
    // Ensure Organization exists and is linked to the user
    const createOrganizationUrl = `${baseUrl}/createOrganization`;
    const orgResponse = await fetch(createOrganizationUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(organizationData)
    });
    const orgResponseFields = await orgResponse.json();
    console.log("orgResponseFields", orgResponseFields);
    const { organizationId, name, createdAt, timeZone, capacity } = orgResponseFields;
    console.log("organizationId", organizationId);
    console.log("name", name);
    console.log("createdAt", createdAt);
    console.log("timeZone", timeZone);
    console.log("capacity", capacity);
  
    try {
      Mixpanel.set_group("Organization", organizationId);
      Mixpanel.add_group("Organization", organizationId);
      Mixpanel.get_group("Organization", organizationId).set({
        name: name,
        createdAt: new Date(createdAt).toISOString(),
        timeZone: timeZone,
        capacity: capacity,
      });
  
      if (Mixpanel.get_distinct_id() !== user.uid) {
        Mixpanel.identify(user.uid);
      }
  
      // Log success
      console.log("Mixpanel group set successfully");
    } catch (error) {
        // Handle errors
        console.error("Error setting Mixpanel group: ", error);
    }
  
  
    return organizationId;
  };

  const updateAdminUser = async (user, organizationId) => {
    const baseUrl = "https://us-central1-somnapp-5fd69.cloudfunctions.net";
    const token = await user.getIdToken();

    // get the first letter of the viewing preference and make it uppercase, and make the rest of it lowercasse
    console.log("adminUserViewingPreference", adminUserViewingPreference);
    const adminUserViewingPreferenceFormatString = adminUserViewingPreference.charAt(0).toUpperCase() + adminUserViewingPreference.slice(1).toLowerCase();
    
    if (!adminUserFirstName || !adminUserLastName || !adminUserViewingPreference) {
      setMessage("Please fill out all fields");
      throw new Error("Please fill out all fields");
    };

    const organizationData = {
      userId: user.uid,
      adminUserFirstName: adminUserFirstName,
      adminUserLastName: adminUserLastName,
      adminUserViewingPreferenceFormatString: adminUserViewingPreferenceFormatString,
    };
  
    // Ensure Organization exists and is linked to the user
    const updateAdminUserUrl = `${baseUrl}/updateAdminUser`;
    const updateAdminUserResponse = await fetch(updateAdminUserUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(organizationData)
    });

    const responseOk = updateAdminUserResponse.ok;

    if (!responseOk) {
      setMessage("Error updating admin user");
      throw new Error("Error updating admin user");
    }
  
    if (responseOk) {
      // add admin user first name to mixpanel using Mixpanel.people.set()
      Mixpanel.people.set({
        "$first_name": adminUserFirstName,
        "$last_name": adminUserLastName,
        "$organization": organizationId,
        "$timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
        "$organiztion_name": organizationName,
        "$name": `${adminUserFirstName} ${adminUserLastName}`,
        "$viewing_preference": adminUserViewingPreferenceFormatString,
        "$platform":"Web"
      });
    } else {
      console.warn("Error updating admin user in mixpanel");
    }
  
    return;

  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
    
    
      setIsLoading(true);

      const organizationId = await createOrganization(user);
      await updateAdminUser(user, organizationId);

      console.log("org builder form subscription", subscription);
      sessionStorage.setItem("comingFromPricing", "true");
      navigate('/payment', { state: { subscription } });
    
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };




  return (
    <>
    <Global styles={css`
        /* Paste your global CSS here */
        :root {
          --light-grey: #F6F9FC;
          --dark-terminal-color: #0A2540;
          --accent-color: #635BFF;
          --radius: 15px;
        }

        body {
          padding: 20px;
          font-family: 'Figtree';
          display: flex;
          justify-content: center;
          font-size: 1.2em;
          color: var(--dark-terminal-color);
        }

        main {
          width: 480px;
        }
        
        form > * {
          margin: 30px 50px;
        }
        
        input, select {
          display: block;
          font-size: 1.1em;
          width: 100%;
          margin-bottom: 10px;
          color: white;
        }
        
        label {
          display: block;
        }
        
        a {
          color: var(--accent-color);
          font-weight: 900;
        }
        
        small {
          font-size: .6em;
        }
        
        fieldset, input, select {
          border: 1px solid #efefef;
        }
        
        #payment-form {
          border: #F6F9FC solid 1px;
          border-radius: var(--radius);
          padding: 20px;
          margin: 20px 0;
          box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
        }
        
        #messages {
          font-family: 'Figtree';
          display: none; /* hide initially, then show once the first message arrives */
          background-color: #0A253C;
          color: #00D924;
          padding: 20px;
          margin: 20px 0;
          border-radius: var(--radius);
          font-size:0.7em;
        }
      `} />

    <form id="payment-form" onSubmit={handleSubmit} className={classes.form}>
    <Typography className={classes.bigHeader}>Admin User Information</Typography>
      <div className={classes.upperPortion}>
        <div className={classes.firstName}>
        <label htmlFor="firstName">First name</label>
        <Input
          type="text" 
          placeholder="Enter first name" 
          onChange={(e) => setAdminUserFirstName(e.target.value)}
          // style={{ width: '200%' }}
        />
        </div>

        <div className={classes.lastName}>
          <label htmlFor="lastName">Last Name:</label>
          <Input
            type="text" 
            placeholder="Enter last name" 
            onChange={(e) => setAdminUserLastName(e.target.value)}
            // style={{ width: '200%' }}
          />
        </div>
      </div>

      <Typography className={classes.bigHeader}>Organization Details</Typography>
      <div>
      <label htmlFor="address">Organization Name</label>
      <Input 
        type="text" 
        placeholder="Organization Name" 
        onChange={(e) => setOrganizationName(e.target.value)}
      />
      </div>

      <div className={classes.upperPortion}>
        <div className={classes.address}>
        <label htmlFor="city">Capacity</label>
        <TextField className={classes.styledNumberTextField}
          type="number" 
          placeholder="5" 
          InputProps={{ inputProps: { min: 1, max: subscription.capacity ? subscription.capacity : 50 } }}
          onChange={(e) => setOrganizationCapacity(e.target.value)}
        />
        </div>
      

        <div className={classes.viewingPreference}>
          <label htmlFor="city">Viewing Preference</label>
          <div className={classes.formControl}>
          <FormControl className={classes.formControl}>
            <Select
              value={adminUserViewingPreference}
              onChange={(e) => setAdminUserViewingPreference(e.target.value)}
            >
              {Object.keys(PRIVACY_LEVELS_STRINGS).map((privacyLevel) => (
                <MenuItem value={privacyLevel}>{privacyLevel}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      </div>

      <Button 
        disabled={isLoading} 
        onClick={handleSubmit}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? <CircularProgress /> : "Save organization"}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    </>
  );
}
