import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';

import '../../../src/fonts.css';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    menuItem: {
        color: 'white',
        backgroundColor: 'rgba(2,28,35, 1)',
        display: 'block',
        width: '100%',
        fontFamily: 'Figtree',
    },
    menuItemHover: {
        '&:hover': {
            backgroundColor: '#27555F',
        }
    },
    menuItemSelected: {
        backgroundColor: '#27555F !important', // Using !important to ensure it overrides other styles
        '&:hover': {
            backgroundColor: '#27555F',
        }
    },
    dropdown: {
        '& .MuiPaper-root': {
            backgroundColor: 'rgba(2,28,35, 1)',
        },
        '& .MuiList-padding': {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
        },
        color: 'white',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        }
    },
    input: {
        color: 'white',
        backgroundColor: '#021C23',
        marginBottom: 10,
    },
    listSubheader: {
        backgroundColor: '#021C23',
    },
    label: {
        color: 'white',
        "&.Mui-focused": { // style when input is focused
            color: 'white'
        }
    },
    select: {
        color: 'white',
    },
    icon: {
        color: 'white',
    }
});

function SearchableDropdown({ users, onSelect, memberLabels }) {
    const classes = useStyles();
    const [selectedUser, setSelectedUser] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const handleChange = (event) => {
        setSelectedUser(event.target.value);
        const user = users.find(u => u.uid === event.target.value);
        onSelect(user);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredUsers = users.filter(user =>
        memberLabels.get(user.uid).toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={classes.container}>
            <FormControl variant="outlined" style={{ flex: 1 }}>
                <InputLabel htmlFor="searchable-dropdown" className={classes.label}>Select User</InputLabel>
                <Select
                    className={classes.dropdown}
                    classes={{ select: classes.select, icon: classes.icon }}
                    label="Select User"
                    value={selectedUser}
                    onChange={handleChange}
                    inputProps={{
                        name: 'user',
                        id: 'searchable-dropdown',
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                    }}
                    renderValue={() => (
                        <span style={{ color: 'white' }}>
                            {memberLabels.get(selectedUser) || ''}
                        </span>
                    )}
                >
                    <ListSubheader className={classes.listSubheader}>
                        <TextField
                            placeholder="Search..."
                            fullWidth
                            className={classes.input}
                            onChange={handleSearchChange}
                            value={searchTerm}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.target.focus();  // This will force focus to the TextField on click
                            }}
                            onKeyDown={(e) => e.stopPropagation()}
                            onKeyUp={(e) => e.stopPropagation()}
                            InputProps={{
                                disableUnderline: true,
                                className: classes.input,
                            }}
                        />
                    </ListSubheader>
                    {filteredUsers.map(user => (
                        <MenuItem
                            value={user.uid}
                            key={user.uid}
                            className={`${classes.menuItem} 
                    ${selectedUser === user.uid ? classes.menuItemSelected : ''} 
                    ${classes.menuItemHover}`}
                        >
                            {memberLabels.get(user.uid)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                style={{ marginLeft: '10px', color: '#fff', fontFamily: 'Figtree', fontWeight: 900 }}
                onClick={() => {
                    setSelectedUser('');
                    setSearchTerm('');
                    onSelect(null);
                }}
            >
                Clear
            </Button>
        </div>
    );
}

export default SearchableDropdown;
