import { createContext, useContext, useState } from 'react';

const ChartContext = createContext();

export const useChartContext = () => {
    return useContext(ChartContext);
};

export const ChartProvider = ({ children }) => {
    const [yMaxViolin, setYMaxViolin] = useState(0);
    const [yMaxBoxAndWhisker, setYMaxBoxAndWhisker] = useState(0);
    const [yMaxSleepDuration, setYMaxSleepDuration] = useState(0);
    const [yMaxSleepDebt, setYMaxSleepDebt] = useState(0);

    return (
        <ChartContext.Provider value={{ yMaxViolin, setYMaxViolin, yMaxBoxAndWhisker, 
        setYMaxBoxAndWhisker, yMaxSleepDuration, setYMaxSleepDuration, yMaxSleepDebt, setYMaxSleepDebt }}>
            {children}
        </ChartContext.Provider>
    );
};
