import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Lottie from 'react-lottie';
import lottieAnimation from '../assets/SomnLogomarkTransparentLottiefile.json'; // Update this path
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from '../helpers/utils';
import { Link } from 'react-router-dom';
import { signOut_ } from '../utils/Firebase';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 200,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 200,
      display: 'flex',
    },
  },
  progress: {
    height: 4,
  },
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: lottieAnimation,
};

async function handleSignOut() {
  await signOut_();
}

export default function Loading() {
  const classes = useStyles();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.DarkTeal900,
      }}
    >
      <div className={classes.logo}>
        <Lottie options={defaultOptions} />
      </div>
      <Box sx={{ width: '70%', marginTop: 4 }}>
        <LinearProgress className={classes.progress} />
      </Box>
    </Box>
  );
}
