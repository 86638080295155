import React from 'react';
import { Container, Typography, Button, Card, CardContent, CardMedia, TextField } from '@material-ui/core';
import TimezoneDropdown from './TimezoneDropdown';
import PrivacyDropdown from './PrivacyDropdown';
import { privacyIntsToNames } from '../../helpers/utils';
import membersCount from '../../../src/assets/group-chat.png';
import TeamCode from '../../../src/assets/one-time-password.png';
import privacySettings from '../../../src/assets/document.png';
import Tooltip from "@mui/material/Tooltip";
import UploadSchedule from '../ActionCenter/UploadSchedule';
import BroadcastNotification from '../ActionCenter/BroadcastNotification';
import Workflow from '../ActionCenter/Workflow';
import { logEvent } from '../../helpers/utils';




function ActionCenter({ user, uniqueCode, teamTimezone, updateTeamTimezone, organization, userViewingPreference, updateViewingPreference }) {
  const privacyLevel = privacyIntsToNames.get(userViewingPreference);

  const handleClick = async (eventName, eventDescription) => {
    await logEvent(user, eventName, eventDescription);
  };

  return (
    <>
      <div className='container' style={{ marginTop: 0, marginBottom: -10 }}>
        <Typography style={{ color: '#fff', fontFamily: 'Figtree', fontSize: 25, fontWeight: 900, marginTop: 15, marginBottom: 14, textAlign: 'left' }}>
          Action Center
        </Typography>
        <h1 onClick={() => handleClick('Schedule Manager Clicked', 'The user clicked on Schedule Manager')} 
            style={{ borderRadius: 12, color: '#fff', fontFamily: 'Figtree', fontSize: 17, fontWeight: 900, marginTop: 15, marginBottom: 5 }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <UploadSchedule organizationId={organization.organizationId} handleClick={handleClick} />
            <span style={{ marginLeft: '-6rem' }}>
              Schedule Manager
            </span>
          </span>
        </h1>
        <h1 onClick={() => handleClick('Broadcast Notification Clicked', 'The user clicked on Broadcast Notification')} 
            style={{ borderRadius: 12, color: '#fff', fontFamily: 'Figtree', fontSize: 17, fontWeight: 900, marginTop: 15, marginBottom: 5 }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <BroadcastNotification organizationId={organization.organizationId} handleClick={handleClick} />
            <span style={{ marginLeft: '-4.4rem' }}>
              Broadcast Notification
            </span>
          </span>
        </h1>
        <h1 onClick={() => handleClick('Workflow Clicked', 'The user clicked on Workflow')} 
            style={{ borderRadius: 12, color: '#fff', fontFamily: 'Figtree', fontSize: 17, fontWeight: 900, marginTop: 15, marginBottom: 5 }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <Workflow organizationId={organization.organizationId} handleClick={handleClick} />
            <span style={{ marginLeft: '-6.2rem' }}>
              Plan a Workflow
            </span>
          </span>
        </h1>
      </div>
    </>
  );
}

export default ActionCenter;
