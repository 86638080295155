// UserDetailsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { createSelector } from 'reselect';

// Async thunk to fetch user details from Firestore
export const fetchUserDetails = createAsyncThunk('userDetails/fetchUserDetails', async (user) => {
  const userId = user.uid;
  const db = getFirestore();
  const userDocRef = doc(db, 'adminUsers', userId);
  const userDoc = await getDoc(userDocRef);
  return userDoc.data();
});

// User Details Slice
const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState: {
    userDetails: null,
    loadingUserDetails: false, // This was updated to match the userSlice.js initialState
  },
  reducers: {
    // Consider adding reducers here similar to userSlice.js if necessary
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
      state.loadingUserDetails = false;
    },
    setLoadingUserDetails: (state, action) => {
      state.loadingUserDetails = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.loadingUserDetails = true;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.loadingUserDetails = false;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        console.error("An error occurred while fetching user details:", action.error);
        state.loadingUserDetails = false;
      });
  }
});

// Selectors for user details
export const selectUserDetails = createSelector(
  (state) => state.userDetails.userDetails,
  (userDetails) => userDetails
);

export const selectLoadingUserDetails = createSelector(
  (state) => state.userDetails.loadingUserDetails,
  (loadingUserDetails) => loadingUserDetails
);

// Export actions similar to userSlice.js if needed
export const { setUserDetails, setLoadingUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
