import React from 'react';

class HowWorks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'HowWorks',
    };
  }
  render() {
    return (
      <div className="how-works">
        <h1>How It Works</h1>
        <p>Our mission is to help people find meaningful work.</p>
      </div>
    );
  }
}

export default HowWorks;