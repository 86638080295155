import { React, useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {
  getLastSevenSomns, getRandomColor, getSleepDiffs,
  getLastSevenTimeZones, getLocationAndStamps, getProcessedLastSevenSomns,
  formatDateLabels, PRIVACY_LEVELS, getSevenDaysAgo, generateDateLabels,
  alignDataWithLabels, formatDateToWeekdayMMDDYY, getProcessedChartData, hexToRgba, Colors
} from '../../helpers/utils';
import { sleep } from '@amcharts/amcharts5/.internal/core/util/Time';
import '../../../src/fonts.css';
import { CircularProgress } from '@material-ui/core';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

const SleepCapsule = ({ team, selectedUser, privacyLevels, memberLabels, teamSleepDurations, recentLocations, currentStartDate }) => {
  const [chartData, setChartData] = useState([]);
  // const { startDate } = getSevenDaysAgo();
  const [isLoading, setIsLoading] = useState(true);
  let labels = generateDateLabels(currentStartDate, 7);
  let hoursOffsetSleep = [];
  let hoursOffsetWake = [];

  const userLocations = new Set();
  useEffect(() => {
    setIsLoading(true);
    hoursOffsetSleep = [];
    hoursOffsetWake = [];

    const calculateDatasets = async () => {
      setIsLoading(true);
      if (!team || team.length === 0) {
        return;
      }
      let allData = [];
      for (let i = 0; i < team.length; i++) {
        const user = team[i];
        const privacyLevel = privacyLevels.get(user.uid);
        if (privacyLevel === PRIVACY_LEVELS.NONE) {
          continue; // skip this iteration of the loop
        }

        const somns = teamSleepDurations[user.uid];

        const lastSevenSomns = getProcessedChartData(labels, somns);

        const memberData = {
          label: memberLabels.get(user.uid),
          data: lastSevenSomns,
          fill: false,
          borderWidth: selectedUser && user.uid === selectedUser.uid ? 2 : 1,
          tension: 0.1,
        };

        allData.push(memberData);
      }
      const userChartData = allData.filter(elt => elt.label === memberLabels.get(selectedUser.uid));


      setChartData(userChartData);
      setIsLoading(false);

      // Introduce a delay before updating the state
      // setTimeout(() => {
      //   setChartData(userChartData);
      //   setIsLoading(false);
      // }, 0);  // 1 second delay, adjust as needed

    }
    calculateDatasets();
  }, [team, selectedUser, currentStartDate]);

  const yLabels = [];
  for (let second = 72000; second < 86400; second++) {
    yLabels.push(getHourString(second));
  }

  for (let second = 0; second <= 57600; second++) {
    yLabels.push(getHourString(second));
  }

  function getHourString(second) {
    const hours = Math.floor(second / 3600);
    return `${((Math.floor(hours / 10) > 0) % 12 >= 12 ? hours : '0' + hours) >= 12 ? (hours % 12 + ' PM') : (hours % 12 + ' AM')}`; // : ${Math.floor(minutes / 10) > 0 ? minutes : '0' + minutes}`;
  }

  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: { display: false },
  //     datalabels: { display: false }
  //   },
  // };

  const userHasData = chartData[0]?.data.map(item => item.sleepTimeReported && item.wakeTimeReported);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    // resizeDelay: 50,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        anchor: 'center',
        align: 'center',
        formatter: function (value, context) {
          // Handling for the "Empty" dataset
          if (context.dataset.label === 'Empty') {
            const userData = context.chart.data.datasets.find(ds => ds.label === 'User Data').data[context.dataIndex];
            if (userData[0] === undefined && userData[1] === undefined) {
              return 'No data';
            } else {
              return ''; // don't show labels for non-empty capsules
            }
          }

          // Handling for the "User Data" dataset
          if (context.dataset.label === 'User Data') {
            const start = context.dataset.data[context.dataIndex][0];
            const end = context.dataset.data[context.dataIndex][1];

            // Ensure that the data points are numbers before doing the calculations
            if (typeof start === 'number' && typeof end === 'number') {
              if (hoursOffsetSleep[context.dataIndex] && hoursOffsetWake[context.dataIndex]) {
                return Math.round(Math.abs(start - end + hoursOffsetSleep[context.dataIndex] + hoursOffsetWake[context.dataIndex]) / 3600);
              }
              return Math.round(Math.abs(start - end) / 3600);
            }
          }

          // Return an empty string for other datasets or unexpected scenarios
          return '';


        },
        color: '#fff',
        font: {
          weight: 'bold',
          size: '30%',
          family: 'Figtree',
        },
      },

      tooltip: {
        enabled: false
      },
    },
    borderRadius: 55,
    borderSkipped: false,
    scales: {
      y: {
        ticks: {
          beginAtZero: false,
          stepSize: 3600,
          callback: function (value, index) {
            return index % 20 === 0 ? yLabels[value] : '';
          },
          color: '#fff',
          font: {
            family: 'Figtree',
            size: 15,
            weight: 900,
          },
        },
        reverse: true,
        max: 72000,
        stacked: false,
        grid: {
          display: true,
          color: '#23383C',
          drawOnChartArea: true,
          drawTicks: true,
          stepSize: 3600,
          lineWidth: 2.5,
        },
        border: {
          display: false,
        },
      },
      x: {
        title: {
          display: true,
          color: '#fff',
          font: {
            size: 20,
          },
        },
        ticks: {
          color: '#fff',
          font: {
            family: 'Figtree',
            size: 14,
            weight: 900,
          },
        },
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  }

  if (isLoading || !chartData || chartData.length === 0) {
    return <CircularProgress />;
  }


  const emptyCapsules = {
    label: 'Empty',
    data: Array(7).fill([0, 72000]),
    backgroundColor: userHasData.map(hasData => hasData ? 'rgba(15, 48, 56, 1)' : hexToRgba(Colors.ErrorRed, 0.5)),
    borderColor: userHasData.map(hasData => hasData ? 'rgba(15, 48, 56, 1)' : hexToRgba(Colors.ErrorRed, 0.5)),
    borderWidth: 0,
  };

  const userDataSet = {
    label: 'User Data',
    data: chartData[0]?.data.map(item => {
      if (item.sleepTimeReported && item.wakeTimeReported) {
        return [
          determineEndpoint(item.sleepTime, true, hoursOffsetSleep),
          determineEndpoint(item.wakeTime, false, hoursOffsetWake)
        ];
      } else {
        // Insert a placeholder value that the rest of the code can handle
        return [undefined, undefined];
      }
    }),
    backgroundColor: 'rgba(75,192,192,1)',
    borderColor: 'rgba(75,192,192,1)',
    borderWidth: 0,
  };
  const datasets = [userDataSet, emptyCapsules];
  const commonTimezonesArray = Array.from(recentLocations); // Assuming commonLocations is a Set
  const mostRecentTimezone = commonTimezonesArray[commonTimezonesArray.length - 1];
  const data = {
    labels: labels.map(dateStr => formatDateToWeekdayMMDDYY(dateStr, mostRecentTimezone)), // I'm assuming we have a similar mostRecentTimezone variable here too.
    datasets: datasets,
  };
  return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
};

function determineEndpoint(itemTime, isSleepTime, hoursOffset) {
  const itemTimeAsDate = new Date(itemTime);
  const itemTimeMinutes = itemTimeAsDate.getMinutes();
  const itemTimeHours = itemTimeAsDate.getHours();
  const itemTimeSeconds = itemTimeAsDate.getSeconds();
  var itemTimeInHMS = itemTimeHours * 3600 + itemTimeMinutes * 60 + itemTimeSeconds + 14400;

  const eightPM = 72000;
  const fourPM = 57600;

  if (isSleepTime) {
    if ((itemTimeHours * 3600) < eightPM && (itemTimeHours * 3600) > fourPM) {
      hoursOffset.push(itemTimeInHMS - (eightPM + 14400));
      return 0;
    }
    hoursOffset.push(0);
  }

  if (!isSleepTime) {
    if ((itemTimeHours * 3600) > fourPM && (itemTimeHours * 3600) < eightPM) {
      hoursOffset.push(itemTimeInHMS - (fourPM + 14400));
      return 72000;
    }
    hoursOffset.push(0);
  }

  switch (itemTimeHours) {
    case 20:
      return itemTimeInHMS - (itemTimeHours * 3600) - (3600 * 4);
    case 21:
      return itemTimeInHMS - (itemTimeHours * 3600) - (3600 * 3);
    case 22:
      return itemTimeInHMS - (itemTimeHours * 3600) - (3600 * 2);
    case 23:
      return itemTimeInHMS - (itemTimeHours * 3600) - 3600;
    default:
      return itemTimeInHMS;
  }
}


export default SleepCapsule;