
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Chart, LinearScale, CategoryScale } from 'chart.js';
import { BoxAndWiskers, BoxPlotController } from '@sgratzl/chartjs-chart-boxplot';
import '../../../src/fonts.css';
import {
    getLastSevenSomns, getRandomColor, getSleepDiffs,
    getLastSevenTimeZones, getLocationAndStamps,
    Colors, getProcessedLastSevenSomns, formatDateLabels,
    PRIVACY_LEVELS, PRIVACY_LEVELS_REVERSED,
    PRIVACY_LEVELS_STRINGS, validateDataAlignment, generateDateLabels, getSevenDaysAgo,
    formatDateToWeekdayMMDDYY, alignDataWithLabels, getProcessedChartData, getLastEightSomns, hexToRgba, setPlotThreshold, getPlotThreshold, logEvent
} from '../../helpers/utils';
import { Button, makeStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import { debounce } from 'lodash';
import { useChartContext } from '../../contexts/ChartContext';

function createGoodBadZonesPlugin(sliderValueRef) {
    return {
        id: 'goodBadZonesPlugin',
        beforeDatasetsDraw: function (chart) {
            const ctx = chart.ctx;
            const xScale = chart.scales['x'];
            const yScale = chart.scales['y'];  // Access the Y-Axis scale
            const titleText = chart.options.titleText;

            // Define the threshold
            const threshold = sliderValueRef.current;
            const thresholdPixel = xScale.getPixelForValue(threshold);

            const padding = 40;
            const yTop = yScale.getPixelForTick(0) - yScale.height / yScale.ticks.length + padding;
            const yBottom = yScale.getPixelForTick(0) + yScale.height / yScale.ticks.length - padding;

            // Shade everything to the left of the threshold in green
            ctx.fillStyle = hexToRgba(Colors.SuccessGreen, 0.5);
            ctx.fillRect(xScale.left, yTop, thresholdPixel - xScale.left, yBottom - yTop);

            // Shade everything to the right of the threshold in red
            ctx.fillStyle = hexToRgba(Colors.ErrorRed, 0.5);
            ctx.fillRect(thresholdPixel, yTop, xScale.right - thresholdPixel, yBottom - yTop);
        }
    };
}


// register controller in chart.js and ensure the defaults are set
Chart.register(BoxPlotController, BoxAndWiskers, LinearScale, CategoryScale);

const useStyles = makeStyles({
    track: {
      height: 12,
      borderRadius: 4,
      backgroundColor: Colors.SuccessGreen,
      opacity: 1,
    },
    rail: {
      height: 12,
      borderRadius: 4,
      backgroundColor: Colors.ErrorRed,
      opacity: 1,
    },
    thumb: {
        height: 24,
        width: 24,
      color: 'rgba(75, 192, 192, 1)',
      marginTop: -8, // adjust the thumb positioning
      marginLeft: -12, // center the thumb
    },
    valueLabel: {
        left: 'calc(-50% + 8px)', // adjust the label positioning to be centered
        top: -24, // adjust as needed for vertical centering
    }
  });

function BoxAndWhiskerPlot({ title, user, team, organization, selectedUser, setSelectedUser, privacyLevels, memberLabels, teamSleepDurations, 
  teamSleepDurationsVBW, calculateValue, currentStartDate, previousDaysCutoff }) {
    const classes = useStyles();
    const { yMaxBoxAndWhisker, setYMaxBoxAndWhisker, yMaxSleepDebt, setYMaxSleepDebt } = useChartContext();
    
    const canvasRef = useRef(null);
    const chartRef = useRef(null);  // Store a reference to the chart instance
    const [chartData, setChartData] = useState([null]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [sliderValue, setSliderValue] = useState(null);

    useEffect(() => {
        async function fetchThreshold() {
            const threshold = await getPlotThreshold(organization, title);
            setSliderValue(threshold);
        }

        fetchThreshold();
    }, [organization, title]);

    useEffect(() => {
        const year = currentStartDate.getFullYear();
        const month = String(currentStartDate.getMonth() + 1).padStart(2, '0'); // +1 because JavaScript months are 0-indexed
        const day = String(currentStartDate.getDate()).padStart(2, '0');
        const formattedCurrentStartDate = `${year}-${month}-${day}`;
        const calculateDatasets = async () => {
            let allData = [];
            // Second loop: Build the datasets using the generated labels
            if (team && Array.isArray(team)) {
                for (let user of team) {
                    const privacyLevel = privacyLevels.get(user.uid);
                    if (privacyLevel === PRIVACY_LEVELS.NONE) {
                        continue;
                    }

                    const hoursForRest = user.hoursForRest.split(' ')[0];
                    const findIndexByProperty = (arr, prop, value) => {
                        return arr.reduce((acc, curr, idx) => {
                            if (acc !== -1) return acc;  // If the index is already found, return it
                            return curr[prop] === value ? idx : -1;
                        }, -1);
                    };
                    const currentDateIndex = findIndexByProperty(teamSleepDurationsVBW[user.uid], 'date', formattedCurrentStartDate);
                    const sleepDiffs = teamSleepDurations[user.uid].slice(currentDateIndex, currentDateIndex + previousDaysCutoff);
                    const sleepDiffsVBW = teamSleepDurationsVBW[user.uid].slice(currentDateIndex, currentDateIndex + previousDaysCutoff + 1);

                    // Get the value array (i.e., the diffs for this user)
                    const value = calculateValue(sleepDiffs, sleepDiffsVBW, hoursForRest, user);


                    if (value > yMaxBoxAndWhisker && title != 'Sleep Debt Distribution') {
                      setYMaxBoxAndWhisker(prevYMaxBoxAndWhisker => Math.round(Math.max(prevYMaxBoxAndWhisker, value), 0));
                    } else if (value > yMaxSleepDebt && title === 'Sleep Debt Distribution') {
                      setYMaxSleepDebt(prevYMaxSleepDebt => Math.round(Math.max(prevYMaxSleepDebt, value), 0));
                    }

                    // Distribute the diffs to the respective days
                    allData.push(value);
                }
            }

            // Filter datasets for members with data privacy set to NONE
            allData = allData.filter(memberData => memberData != null);

            setChartData(allData);
        }

        calculateDatasets();
        setDataLoaded(true);

    }, [calculateValue, privacyLevels, teamSleepDurations, teamSleepDurationsVBW, team, currentStartDate]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            goodBadZonesPlugin: true,
        },
        title: {
            display: true,
            text: 'Chart.js Box Plot Chart'
        },
        indexAxis: 'y',
        scales: {
            y: {
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                }
            },
            x: {
                title: {
                    display: true,
                    text: (title === 'Average Bedtime Variation' || title === 'Average Wake Time Variation') ? 'Minutes' : 'Hours',
                    color: 'white',
                    font: {
                        size: 20,
                        family: 'Figtree',
                    },
                },
                ticks: {
                    color: 'white',
                    font: {
                        size: 14,
                        weight: 900,
                        family: 'Figtree',
                    },
                },
                grid: {
                    display: true,
                    color: '#23383C',
                    drawOnChartArea: true,
                    drawTicks: true,
                    lineWidth: 2.5
                },
                border: {
                    display: false,
                },
                max: title == 'Sleep Debt Distribution' ? yMaxSleepDebt : yMaxBoxAndWhisker,
            }
        },
        titleText: title,
    };

    const [xMaxValue, setXMaxValue] = useState(null);
    const [gridWidth, setGridWidth] = useState(null);

    useEffect(() => {
        if (canvasRef.current && dataLoaded) {
            // Always clean up the previous chart instance before creating a new one
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
    
            const ctx = canvasRef.current.getContext('2d');
    
            chartRef.current = new Chart(ctx, {
                type: 'boxplot',
                data: {
                    labels: [title],
                    datasets: [{
                        label: 'Boxplot',
                        data: [chartData],
                        backgroundColor: 'rgba(75,192,192,1)',
                        borderColor: '#fff',
                        borderWidth: 2.5,
                        meanRadius: 15,
                        meanBackgroundColor: 'rgba(256, 256, 256, 0.5)',
                        meanBorderWidth: 2.5,
                        meanBorderColor: '#fff',
                        outlierBackgroundColor: Colors.ErrorRed,
                        outlierBorderColor: Colors.ErrorRed,
                        outlierRadius: 5,
                    }]
                },
                options: options,
                plugins: [createGoodBadZonesPlugin(sliderValueRef)], // Use the reference here
            });
    
            // After creating the chart:
            const maxX = chartRef.current.scales['x'].max;
            setXMaxValue(maxX);
            const gridWidthValue = chartRef.current.scales['x'].left - chartRef.current.scales['x'].right - chartRef.current.chartArea.right;
            setGridWidth(gridWidthValue);
        }
    
        // Clean up on component unmount
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [chartData, dataLoaded]);
    
    
    const sliderValueRef = useRef(null);

    useEffect(() => {
        sliderValueRef.current = sliderValue;
    }, [sliderValue]);

    const handleSliderChange = useCallback(debounce((event, newValue) => {
        setSliderValue(newValue);
        if (chartRef.current) {
            chartRef.current.update('quiet'); // Update the chart without animations
        }
    }, 10), []);

    return (
        <div>
            <div style={{ height: '150px', width: '100%' }}>
                {dataLoaded ? <canvas ref={canvasRef}></canvas> : <div>Loading...</div>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <div style={{ flex: 1 }}>
                    {sliderValue !== null && (
                        <Slider
                            style={{ width: '100%' }}  // Slider takes the full width of its container
                            value={sliderValue}
                            onChange={handleSliderChange}
                            min={0}
                            step={title === 'Sleep Debt Distribution' ? 0.1 : 1}
                            max={xMaxValue}
                            aria-labelledby="vertical-slider"
                            valueLabelDisplay="on"
                            classes={{ track: classes.track, rail: classes.rail, thumb: classes.thumb, valueLabel: classes.valueLabel }}
                            onChangeCommitted={() => {
                              setPlotThreshold(organization, title, sliderValue);
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
    
    
    

}

export default BoxAndWhiskerPlot;