import React, { useEffect, useState } from 'react';
import { collection, query, where, orderBy, limit, getDoc, doc, getDocs, Timestamp, startAfter, updateDoc, addDoc } from "firebase/firestore";
import { db, auth } from '../utils/Firebase';
import { utcToZonedTime } from 'date-fns-tz';
import { startOfDay, subDays, endOfDay, getDate } from 'date-fns';
import { DateTime } from 'luxon';
import Mixpanel from 'mixpanel-browser';

function isAdminChecker({ user }) {
  return user.isAdmin == 1;
}

function getRandomColor() {
  const min = 100;
  const max = 200;

  const red = Math.floor(Math.random() * (max - min + 1) + min);
  const green = Math.floor(Math.random() * (max - min + 1) + min);
  const blue = Math.floor(Math.random() * (max - min + 1) + min);

  return `rgb(${red}, ${green}, ${blue})`;
}

const Colors = {
  ErrorRed: '#FA5F5A',
  WarningOrange: '#FBA93D',
  SuccessGreen: '#00AF7B',
  SoftYellow: '#DEE8A1',
  Light: '#EFFAFA',
  BlueGreen700: '#25959c',
  BlueGreen500: '#2ABAC7',
  DarkTeal500: "#32636F",
  DarkTeal600: "#28555F",
  DarkTeal700: "#1C434B",
  DarkTeal800: "#0F3038",
  DarkTeal900: "#011D23"
};

const PRIVACY_LEVELS = {
  IDENTIFIED: 3,
  ANONYMOUS: 2,
  AGGREGATED: 1,
  NONE: 0,
};

const PRIVACY_LEVELS_STRINGS = {
  IDENTIFIED: "Identified",
  ANONYMOUS: "Anonymous",
  AGGREGATED: "Aggregated",
  NONE: "None",
};

const PRIVACY_LEVELS_REVERSED = new Map([
  [3, "Identified"],
  [2, "Anonymous"],
  [1, "Aggregated"],
  [0, "None"],
]);

const privacyNamesToIntsMap = new Map([
  ['Identified', 3],
  ['Anonymous', 2],
  ['Aggregated', 1],
  ['None', 0]
]);

const defaultValue = PRIVACY_LEVELS.IDENTIFIED; // Default value for coaches

const privacyNamesToInts = {
  get: (key) => privacyNamesToIntsMap.get(key) !== undefined ? privacyNamesToIntsMap.get(key) : defaultValue
};

const defaultKey = 3; // Default value for reverse lookup

const privacyIntsToNames = {
  get: (key) => PRIVACY_LEVELS_REVERSED.get(key) !== undefined ? PRIVACY_LEVELS_REVERSED.get(key) : PRIVACY_LEVELS_REVERSED.get(defaultKey)
};

function darkenColor(hex, percent) {
  // Ensure the percent is between 0 and 1
  percent = Math.min(1, Math.max(0, percent));

  // Convert hex to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Adjust and clamp each component
  r = Math.floor(r * (1 - percent));
  g = Math.floor(g * (1 - percent));
  b = Math.floor(b * (1 - percent));

  // Convert back to hex and return
  return "#" + r.toString(16).padStart(2, '0') + g.toString(16).padStart(2, '0') + b.toString(16).padStart(2, '0');
}

function hexToRgba(hex, opacity) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

/**
 * Gets the last `n` number of time zones for a user with the id `userId`. The time zones are returned in descending order.
 * 
 * @param {*} userId - the user's id to query
 * @param {*} n - the number of time zones to query
 * @returns an array of time zones sorted in descending order by timestamp
 */
async function getLastNTimeZones(userId, n) {
  try {
    const timeZonesRef = collection(db, `users/${userId}/TimeZones`);
    let timeZonesQuery;

    let lastDocument = null; // This will keep track of the last fetched document for pagination
    const uniqueDates = new Set(); // This set will keep track of unique days
    const uniqueTimeZones = [];   // This array will hold the timezone data of unique days

    let counter = 0;
    while (uniqueDates.size < n && counter < 10) {
      counter += 1;
      // Set the limit to 10 (or another value if you think you may need more to find 7 unique dates)
      // but you can adjust depending on your data characteristics.
      timeZonesQuery = query(timeZonesRef, orderBy('timestamp', 'desc'), limit(10));

      // If lastDocument is set, start after that document for pagination.
      if (lastDocument) {
        timeZonesQuery = query(timeZonesRef, orderBy('timestamp', 'desc'), startAfter(lastDocument), limit(10));
      }
      const querySnapshot = await getDocs(timeZonesQuery);
      // If there are no documents left, break out of the loop.
      if (!querySnapshot.docs.length) {
        break;
      }

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        const dateObj = new Date(data.timestamp.seconds * 1000); // Assuming timestamp is a Firestore Timestamp object.
        const dateString = `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`; // Convert to a unique date string.

        if (!uniqueDates.has(dateString)) {
          uniqueDates.add(dateString);
          uniqueTimeZones.push(data);
        }

        if (uniqueDates.size === n) {
          break;  // If you have 7 unique days, break out of the loop.
        }
      }

      // Set the last document for pagination in the next iteration.
      lastDocument = querySnapshot.docs[querySnapshot.docs.length - 1];
    }
    return uniqueTimeZones;
  } catch (error) {
    console.error("Error fetching last seven time zones:", error);
    return [];
  }
}

function formatDateToWeekdayMMDDYY(dateStr, timeZone) {
  const [year, month, day] = dateStr.split('-').map(str => parseInt(str, 10));
  const dateObj = new Date(year, month - 1, day);  // month is 0-indexed

  const formattedDate = dateObj.toLocaleDateString('en-US', { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit', timezone: timeZone });

  // Remove leading zeroes from month and day
  const formattedDateReplaced = formattedDate.replace(/\/0/g, '/');
  return formattedDateReplaced;
}

function getLocationAndStamps(lastSevenTimeZones) {
  const locAndStamps = lastSevenTimeZones.map(doc => {
    const userLocation = doc.location;
    const userTimestamp = doc.timestamp.toDate();

    return { userLoc: userLocation, userTime: userTimestamp };
  });
  return locAndStamps;
}

/**
 * Return the start and end dates starting from the given `from` int, representing the number of days in the past
 * from today, to `to` int, also representing the number of days in the past from today.
 * 
 * `from` is always expected to be greater than `to`. If `from` is less than `to`, then the function will return null.
 * 
 * @param {number} from - the number of days in the past from today
 * @param {number} to - the number of days in the past from today
 * @param {string} timezone - the time zone identifier
 * @returns an object with the start and end dates as Dates
 */
function getDateRange(from, to, timezone) {
  if (from < to) {
      return null;
  }

  let now = DateTime.now().setZone(timezone);

  let endDate;
  let startDate;

  if (now.hour < 2) {
      // Consider end of previous day
      endDate = now.minus({ days: to + 1 }).endOf("day").toJSDate();

      // Consider start of previous day
      startDate = now.minus({ days: from + 1 }).startOf("day").toJSDate();
  } else {
      endDate = now.minus({ days: to }).endOf("day").toJSDate();
      startDate = now.minus({ days: from }).startOf("day").toJSDate();
  }

  return { startDate, endDate };
}



function getSevenDaysAgo() {
  const now = new Date();
  let endDate;

  if (now.getHours() < 8) { // If before 8:00 AM
    endDate = endOfDay(subDays(now, 1)); // End of yesterday
  } else {
    endDate = endOfDay(now); // End of today
  }

  let startDate;
  if (now.getHours() < 8) {
    endDate = endOfDay(subDays(now, 1));
    startDate = startOfDay(subDays(now, 7));
  } else {
    endDate = endOfDay(now);
    startDate = startOfDay(subDays(now, 6));
  }
  return { startDate: startDate, endDate: endDate };
}


function isSameDate(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate();
}


/**
 * Creates a fake somn with the specified wakeTime to maintain function invariants.
 * A fake somn is a somn with sleepTimeReported and wakeTimeReported set to false.
 * 
 * @param {string} wakeTimeStr - the wakeTime to set for the fake somn in the format "YYYY-MM-DD"
 * @returns a fake somn
 */
function createFakeSomnWithWakeTime(wakeTimeStr) {
  // Parse the wakeTime string into a Date object
  const [year, month, day] = wakeTimeStr.split('-').map(Number);
  const wakeTime = new Date(year, month - 1, day);


  // Use wakeTime to derive sleepTime. SleepTime will be at most 8 hours before wakeTime
  const sleepDuration = Math.random() * 1000 * 60 * 60 * 8; // Random number of milliseconds up to 8 hours
  const sleepTimeDate = new Date(wakeTime.getTime() - sleepDuration);

  // Convert the dates to Timestamp format if necessary
  const sleepTimeTimestamp = Timestamp.fromDate(sleepTimeDate);
  const wakeTimeTimestamp = Timestamp.fromDate(wakeTime);

  const fakeSomn = {
    sleepTime: sleepTimeTimestamp,
    wakeTime: wakeTimeTimestamp,
    sleepTimeReported: false,
    wakeTimeReported: false
  };

  return fakeSomn;
}

/**
 * Given a list of somns, and the start day query and the end day query, adds dummy somns to the list such that
 * there's a somn for each day in the query range. The dummy somns will have random sleep and wake times between
 * 0 and 8 hours and sleepTimeReported and wakeTimeReported set to false.
 * 
 * This method is a mutator for the somns array. It will only add somns if there isn't a complete set.
 * 
 * @param {Array} somns - the list of somns to add dummy somns to
 * @param {Date} startDate - the start day of the query range
 * @param {Date} endDate - the end day of the query range
 * @returns the list of somns with dummy somns added
 */
function addDummySomns(somns, startDate, endDate) {
  // create a map of dates to somns
  const oneWeekAgoIndex = 7;
  // const daysCounted = new Map();

  for (let i = 0; i < oneWeekAgoIndex; i++) {
    const currentDate = new Date(getSevenDaysAgo().startDate);  // This creates a new Date object that matches startDate on every iteration
    currentDate.setDate(currentDate.getDate() + i);     // Modify the new date object, not the original startDate

    // if there is a somn.wakeTime with the same date as d, then add it to the map
    const somn = somns.find(somn => isSameDate(somn.wakeTime.toDate(), currentDate));
    if (!somn) {
      // Create a fake somn with the wakeTime set to d if there wasn't a somn found
      const fakeSomn = createFakeSomnWithWakeTime(getLocalDateISOString(currentDate));
      somns.push(fakeSomn);
    }
  }
}

/**
 * Formats the date so that the day can be used as a key in a map regardless of the time.
 * 
 * @param {*} date - the firebase Timestamp.toDate()
 * @returns a YYYY-MM-DD formatted string
 */
function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}


/**
 * Given a list of somns, a start date, and an end date, removes any duplicate somns that 
 * are within the query range. A duplicate somn is a somn with the same wakeTime as another somn.
 * 
 * It will prefer to remove problematic or fakes somns rather than good somns. Problematic or fake
 * somns are somns with sleepTimeReported or wakeTimeReported set to false.
 * 
 * This method is a mutator for the somns array. It will only remove somns if there are too many.
 * 
 * @param {*} somns 
 * @param {*} startDate 
 * @param {*} endDate 
 */
function removeTooManySomns(somns, startDate, endDate) {
  const somnsInRange = somns.filter(somn => somn.wakeTime.toDate() >= startDate && somn.wakeTime.toDate() <= endDate);
  const groupedByWakeTime = {};
  somnsInRange.forEach(somn => {
    const wakeDate = formatDate(somn.wakeTime.toDate());
    if (!groupedByWakeTime[wakeDate]) {
      groupedByWakeTime[wakeDate] = [];
    }
    groupedByWakeTime[wakeDate].push(somn);
  });


  for (const wakeTime in groupedByWakeTime) {
    const duplicates = groupedByWakeTime[wakeTime];

    // Separate the duplicates into two lists: problematic and good
    const problematicSomns = duplicates.filter(somn => !somn.sleepTimeReported || !somn.wakeTimeReported);
    const goodSomns = duplicates.filter(somn => somn.sleepTimeReported && somn.wakeTimeReported);

    // Determine which somns to remove
    let somnsToRemove = [];
    if (goodSomns.length > 0) {
      // Keep the first good somn and mark all other good and problematic somns for removal
      somnsToRemove = [...goodSomns.slice(1), ...problematicSomns];
    } else if (problematicSomns.length > 1) {
      // If there are no good somns, keep only one problematic somn and mark others for removal
      somnsToRemove = problematicSomns.slice(1);
    }

    // Remove marked somns from the main list
    somnsToRemove.forEach(somnToRemove => {
      const index = somns.indexOf(somnToRemove);
      if (index !== -1) {
        somns.splice(index, 1);
      }
    });
  }

}

async function retry(operation, onSuccess, maxRetries = 3, delayMs = 500) {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const result = await operation();
      return onSuccess(result);
    } catch (err) {
      console.warn(`Attempt ${i + 1} failed. Retrying after ${delayMs}ms...`);
      if (i < maxRetries - 1) { // Only sleep if it's not the last attempt
        await new Promise(resolve => setTimeout(resolve, delayMs));
        delayMs *= 2; // Exponential backoff
      }
    }
  }
  throw new Error(`Operation failed after ${maxRetries} attempts.`);
}


function convertToMilliseconds(seconds) {
  return seconds * 1000;
}

/**
 * Queries and returns the last sevens somns of a user given the user's ID `userId`. 
 * This method will always return 7 somns, even if the user has not reported any somns
 * by creating dummy somns with sleepTimeReported and wakeTimeReported set to false.
 * The dummy somns will have random sleep and wake times between 0 and 8 hours.
 *
 * @param {string} userId - the user's id to query
 * @param {number} nDaysAgo - the number of days ago to query
 */
async function getLastNSomns(userId, nDaysAgo, timezone) {
  try {
    const dateRange = getDateRange(nDaysAgo, 0, timezone);

    if (!dateRange) {
      console.error("Unable to retrieve date range.");
      return; // or handle this case in some other way
    }

    const { startDate, endDate } = dateRange;

    // Convert JavaScript dates to Firestore Timestamps
    const endOfQueryDateTimestamp = Timestamp.fromDate(endDate);
    const sevenDaysAgoTimestamp = Timestamp.fromDate(startDate);

    const somnsRef = collection(db, `users/${userId}/Somns`);
    const somnsQuery = query(
      somnsRef,
      where('wakeTime', '>=', sevenDaysAgoTimestamp), // Filter for data on or after 7 days ago
      where('wakeTime', '<=', endOfQueryDateTimestamp), // Filter for data on or before the end of the determined date
      orderBy('wakeTime', 'desc'), // Order the data by date in descending order
      limit(nDaysAgo)
    );
    const secondsTimeOut = 1;
    const somns = await retry(
      () => withTimeout(convertToMilliseconds(secondsTimeOut), getDocs(somnsQuery)),
      (querySnapshot) => {
        if (!querySnapshot) {
          console.error("Query returned undefined querySnapshot for userId:", userId);
          return [];
        }
        const data = Array.from(querySnapshot.docs.map(doc => doc.data()));
        addDummySomns(data, startDate, endDate);
        removeTooManySomns(data, startDate, endDate);
        return data;
      }
    );
    return somns;
  } catch (error) {
    console.error("Error fetching last seven somns:", error);
    return []; // Or another default value or you might want to throw the error to be handled by the calling function
  }
}

/**
 * Logs an event with name `name` and description `desc` to the eventLogging collection for the given user `user`. If there's an error
 * while logging the event, it will be logged to the console. See firestore rules around eventLogging collection for more details.
 * 
 * @param {*} user - the user to log the events under
 * @param {*} name - the name of the event
 * @param {*} desc - the description of the event
 */
async function logEvent(user, name, desc, useMixpanel=true) {
  // console.log("Logging event: ", name, desc, "for user: ", user);
  if (user !== undefined) {
    try {
      // Log the event to Firestore first
      const adminUserDocRef = user.uid ? doc(db, "adminUsers", user.uid) : doc(db, "adminUsers", user);
      const eventLoggingCollectionRef = collection(adminUserDocRef, "eventLogging");
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
      if (useMixpanel) {
        Mixpanel.track(
          name, 
          { 
            eventDescription: desc, 
            userId: user.uid 
          }
        );
      }
      console.warn("Removed firebase upload to addDoc for debugging!");
      
      // await addDoc(eventLoggingCollectionRef,  {
      //   name: name,
      //   description: desc,
      //   timestamp: Timestamp.fromDate(new Date()),
      //   timeZone: timeZone
      // });
      
      console.log("Successfully logged event!");

      const body = JSON.stringify({ userId: user });
      
      // Now update lastActive field in the server session
      const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, 'update-session'), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,  // Assuming user ID is required to identify the session
        credentials: 'include'
      });
    
      if (response.ok) {
        console.log("Successfully updated lastActive time");
      } else {
        console.log("Failed to update lastActive time", await response.json());
      }
      
    } catch (error) {
      console.log("Error logging event: ", error.message);
      console.log("Error logging event to eventLogging collection for adminUserId: ", user.uid);
    }
  } else { // user is undefined
    console.log("user is undefined");
  }
}

async function setPlotThreshold(userId, organization, title, sliderValue) {
  if (organization !== undefined && Array.isArray(organization.adminUserIds)) {
      if (organization.adminUserIds.includes(userId)) {
          if (organization && organization.uniqueCode !== undefined) {
              const organizationsCollectionRef = collection(db, "organizations");
              const q = query(organizationsCollectionRef, where("uniqueCode", "==", organization.uniqueCode));

              const querySnapshot = await getDocs(q);
              if (!querySnapshot.empty) {
                  const organizationDocRef = querySnapshot.docs[0].ref;
                  const fieldPath = `plotThresholds.${titleToFieldPath(title)}`;

                  if (fieldPath === 'plotThresholds.-1') {
                      console.log("Bad title provided.");
                      return;
                  }
                  await updateDoc(organizationDocRef, {
                      [fieldPath]: sliderValue
                  });

                  logEvent(organization.currentUserId, "threshold-set-button", "Set button clicked setting threshold for " + title + " to " + sliderValue);
              } else {
                  console.log("Organization not found.");
              }
          }
      } else {
          console.log("Current user is not an admin for the organization.");
      }
  } else {
      console.log("Invalid organization object or currentUserId not provided.");
  }
}



async function getPlotThreshold(userId, organization, title) {
  if (organization) {
      // Assuming 'organization.adminUserIds' is an array of user IDs
      const organizationRef = collection(db, "organizations");

      // Create a query to check if the current user's ID is within the array of adminUserIds
      const adminUserQuery = query(organizationRef, where("adminUserIds", "array-contains", userId));
      const organizationQuerySnapshot = await getDocs(adminUserQuery);

      if (!organizationQuerySnapshot.empty) {
          // Here we'll just take the first organization that comes up in the query
          // In practice, you should handle the case where a user might belong to multiple organizations
          const organizationData = organizationQuerySnapshot.docs[0].data();

          const field = titleToFieldPath(title);
          if (field === -1) {
              console.error("Bad title provided.");
              return null;
          }

          const plotThresholds = organizationData.plotThresholds;
          const thresholdValue = plotThresholds ? plotThresholds[field] : null;
          console.log("Field: ", field, "Value: ", thresholdValue);
          return thresholdValue;
      }
  }
  return null;
}



function titleToFieldPath(title) {
  switch (title) {
    case 'Sleep Duration Spread':
      return 'sleepDursSpread';
    case 'Sleep Debt Distribution':
      return 'sleepDebtDist';
    case 'Bedtime Variation Spread':
      return 'btVarSpread';
    case 'Wake Time Variation Spread':
      return 'wtVarSpread';
    case 'Average Bedtime Variation':
      return 'avgBtVar';
    case 'Average Wake Time Variation':
      return 'avgWTVar';
    default:
      return -1;
  }
}

  async function getLastEightSomns(userId) {
    // const { startDate, endDate } = getSevenDaysAgo();
    const dateRange = getDateRange(7, 0);

    if (!dateRange) {
      console.error("Unable to retrieve date range.");
      return; // or handle this case in some other way
    }

    const { startDate, endDate } = dateRange;

    // Convert JavaScript dates to Firestore Timestamps
    const endOfQueryDateTimestamp = Timestamp.fromDate(endDate);
    const sevenDaysAgoTimestamp = Timestamp.fromDate(startDate);

    const somnsRef = collection(db, `users/${userId}/Somns`);
    const somnsQuery = query(
      somnsRef,
      where('wakeTime', '>=', sevenDaysAgoTimestamp), // Filter for data on or after 7 days ago
      where('wakeTime', '<=', endOfQueryDateTimestamp), // Filter for data on or before the end of the determined date
      orderBy('wakeTime', 'desc') // Order the data by date in descending order
    );

    const querySnapshot = await getDocs(somnsQuery);
    let somns = Array.from(querySnapshot.docs.map(doc => doc.data()));
    addDummySomns(somns, startDate, endDate);
    removeTooManySomns(somns, startDate, endDate);
    return somns;
  }

  function getLocalDateISOString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  function getSleepDiffs(querySnapshot, recentLocationsAndStamps, firstLocation) {
    // Convert recentLocationsAndStamps timestamps to 'YYYY-MM-DD' format for easier comparison
    const formattedLocationsAndStamps = recentLocationsAndStamps?.map(loc => {
      return {
        userLoc: loc.userLoc,
        userTime: getLocalDateISOString(loc.userTime)
      };
    });

    function findClosestLocation(date) {
      if (!formattedLocationsAndStamps) return firstLocation;

      let closestLocation = firstLocation;
      let minDiff = Infinity;

      for (const loc of formattedLocationsAndStamps) {
        const diff = Math.abs(new Date(loc.userTime) - new Date(date));
        if (diff < minDiff) {
          minDiff = diff;
          closestLocation = loc.userLoc;
        }
      }
      return closestLocation;
    }

    const diffs = querySnapshot.map(data => {
      const sleepTimeUTC = data.sleepTime.toDate();
      const wakeTimeUTC = data.wakeTime.toDate();
      const date = wakeTimeUTC.toISOString().split('T')[0];

      // Find the closest location for the given wakeTime date
      const currentLocation = findClosestLocation(date);

      const sleepTime = utcToZonedTime(sleepTimeUTC, currentLocation);
      const wakeTime = utcToZonedTime(wakeTimeUTC, currentLocation);

      const diffHours = Math.abs(wakeTime - sleepTime) / 1000 / 60 / 60;
      const somnData = {
        date,
        diff: diffHours,
        sleepTime: sleepTime.toString(),
        wakeTime: wakeTime.toString(),
        sleepTimeReported: data.sleepTimeReported,
        wakeTimeReported: data.wakeTimeReported,
      };
      return somnData;
    }).sort((a, b) => new Date(a.date) - new Date(b.date));

    return diffs;
  }

  /**
   * Given labels and sleep durations, cleans data to be readble by a bar chart. 
   * 
   * @param {*} labels - an array of dates in the format MM-DD-YYYY
   * @param {*} sleepDiffs - sleep durations of a user
   * @returns plottable objects containing the `y` attribute, or the sleep duration being
   * plotted, and the `t` attribute, or the date being plotted
   */
  function getProcessedChartData(labels, sleepDiffs) {
    // Defensive checks here
    if (!labels || !Array.isArray(labels) || !sleepDiffs || !Array.isArray(sleepDiffs)) {
      console.error('Invalid arguments for getProcessedChartData');
      return [];
    }

    const chartData = labels.map(date => {
      const diff = sleepDiffs.find(iterDiff => iterDiff.date === date);
      const emptySomnPlaceholder = {
        t: date,
        y: 0,
        sleepTime: '',
        wakeTime: '',
        sleepTimeReported: false,
        wakeTimeReported: false,
        pointRadius: 10
      };
      if (!diff) {
        return emptySomnPlaceholder;
      } else {
        const processedSomn = {
          t: diff.date,
          y: diff.diff,
          sleepTime: diff.sleepTime,
          wakeTime: diff.wakeTime,
          sleepTimeReported: diff.sleepTimeReported,
          wakeTimeReported: diff.wakeTimeReported,
          pointRadius: (!diff.sleepTimeReported || !diff.wakeTimeReported) ? 10 : 4
        };
        return processedSomn;
      }
    });
    return chartData;
  }

  /**
   * Given a list of user objects, return a map of the userId mapped to the user's sleep durations for
   * each member of the team. 
   * 
   * @param {*} users - an array of firestore user objects
   * @param {*} n - the number of days to query
   * @returns a map mapping user ids to the user's sleep durations
   */
  async function getTeamSleepDurations(users, n) {
    const teamSleepDurations = new Map();
    await Promise.all(users.map(async (user) => {
      const userId = user.uid;
      const somns = await getLastNSomns(userId, n);
      const lastTimeZones = await getLastNTimeZones(userId, n);
      const recentLocations = getLocationAndStamps(lastTimeZones);
      const firstLocation = recentLocations[0].userLoc;
      const sleepDurations = getSleepDiffs(somns, recentLocations, firstLocation);
      teamSleepDurations.set(userId, sleepDurations);
    }));
    return teamSleepDurations;
  }

  /**
   * Fetches `n` number of somns for the user with the id `userId` and returns the sleep durations.
   * 
   * @param {*} userId - a userId
   * @returns the sleep durations of the user
   */
  async function getMemberSleepDurations(userId, n, teamTimeZone) {
    const somns = await getLastNSomns(userId, n, teamTimeZone);
    const lastSevenTimeZones = await getLastNTimeZones(userId, n + 1);
    const recentLocations = getLocationAndStamps(lastSevenTimeZones);
    const firstLocation = recentLocations[0].userLoc;
    const sleepDurations = getSleepDiffs(somns, recentLocations, firstLocation);
    return { sleepDurations, recentLocations };
  }

  async function fetchUserDataAndSleep(memberUid, numDays, teamTimeZone) {
    const usersRef = collection(db, 'users');
    const userDocRef = doc(usersRef, memberUid);
    const userSnapshot = await getDoc(userDocRef);

    let userData = userSnapshot.data();
    if (userData) {
      try {
        const { sleepDurations, recentLocations } = await getMemberSleepDurations(memberUid, numDays, teamTimeZone);
        userData = {
          ...userData,
          sleepDurations: sleepDurations,
          recentLocations: recentLocations
        };
      } catch (error) {
        console.error(`Failed to get sleep data for member UID: ${memberUid}`, error);
        userData.error = `Failed to get sleep data for member UID: ${memberUid}`;
      }
    } else {
      console.error(`No user found for UID: ${memberUid}`);
    }

    return userData;
  }






  function getProcessedLastSevenSomns(querySnapshot, recentLocations, labels, firstLocation) {
    const sleepDiffs = getSleepDiffs(querySnapshot, recentLocations, firstLocation);
    return labels.map(date => {
      const diff = sleepDiffs.find(iterDiff => iterDiff.date === date);
      const emptySomnPlaceholder = {
        t: date,
        y: 0,
        sleepTime: '',
        wakeTime: '',
        sleepTimeReported: false,
        wakeTimeReported: false,
        pointRadius: 10
      };
      if (!diff) {
        return emptySomnPlaceholder;
      } else {
        const processedSomn = {
          t: diff.date,
          y: diff.diff,
          sleepTime: diff.sleepTime,
          wakeTime: diff.wakeTime,
          sleepTimeReported: diff.sleepTimeReported,
          wakeTimeReported: diff.wakeTimeReported,
          pointRadius: (!diff.sleepTimeReported || !diff.wakeTimeReported) ? 10 : 4
        };
        return processedSomn;
      }
    });
  }

  function validateDataAlignment(labels, processedData) {
    for (let i = 0; i < labels.length; i++) {
      let date = labels[i];

      // Boolean flag to keep track if date is found or not
      let dateFound = false;

      for (let j = 0; j < processedData.length; j++) {
        let dataset = processedData[j].data;

        // Check if the date exists in this dataset
        if (dataset.some(dataPoint => dataPoint.t === date)) {
          dateFound = true;
          break; // Break out of inner loop if date is found
        }
      }

      if (!dateFound) {
        console.error(`Missing data for date: ${date}`);
      }
    }
  }



  const getPrivacyLevel = async (user, db) => {
    const organizationsRef = collection(db, 'users');
    if (user && user.uid !== undefined && user.uid !== null) {
      const q = query(organizationsRef, where('uid', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const userDoc = querySnapshot.docs.map(doc => doc.data())[0];
      let privacyLevel = userDoc.organizationAccessLevel.toLowerCase();
      privacyLevel = privacyLevel[0].toUpperCase() + privacyLevel.slice(1).toLowerCase();
      if (userDoc) {
        switch (privacyLevel) {
          case 'Identified':
            return PRIVACY_LEVELS.IDENTIFIED;
          case 'Anonymous':
            return PRIVACY_LEVELS.ANONYMOUS;
          case 'Aggregated':
            return PRIVACY_LEVELS.AGGREGATED;
          case 'None':
            return PRIVACY_LEVELS.NONE;
          default:
            return null; // or return an appropriate default value
        }
      }
    }
    return null; // or return an appropriate default value if no organization is found
  };

  function getAccessLevel(userPrivacyLevel, adminViewingPreference) {
    // Return the minimum of the user's privacy setting and the admin's viewing preference
    return Math.min(userPrivacyLevel, adminViewingPreference);
  }

  // creates a default team name
  const getRandomUser = () => {
    const randomNumber = Math.floor(Math.random() * 10000);
    return `#${randomNumber}`;
  };


  function formatDateLabels() {
    const latestDate = new Date();
    const oldestDate = new Date(latestDate);
    oldestDate.setDate(oldestDate.getDate() - 6); // Six days back from the latest date

    const labels = [];
    for (let i = 0; i < 7; i++) {
      const d = new Date(oldestDate);
      d.setDate(oldestDate.getDate() + i);
      const options = { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' };
      let dateStr = d.toLocaleDateString('en-US', options);
      dateStr = dateStr.replace(', ', '\n');
      labels.push(dateStr);
    }

    return labels;
  }

  function calculateAverage(arr) {
    const sum = arr.reduce((acc, current) => acc + current, 0);
    const average = sum / arr.length;
    return parseFloat(average.toFixed(1));
  }

  function calculateSleepVariation(sleepTimes) {
    sleepTimes = sleepTimes.filter(b => b.sleepTimeReported && b.wakeTimeReported);
    const avg = sleepTimes.reduce((a, b) => a + b.diff, 0) / sleepTimes.length;
    const squareDiffs = sleepTimes.map(time => Math.pow(time.diff - avg, 2));
    const avgSquareDiff = squareDiffs.reduce((a, b) => a + b, 0) / squareDiffs.length;
    return Math.sqrt(avgSquareDiff);
  }

  // clips to [0, 8]
  function clip(value) {
    return Math.max(Math.min(value, 8), 0);
  }

  async function getCurrentUser() {
    try {
      // Assuming `auth` is Firebase auth object
      const user = await auth.currentUser;
      if (user) {
        // User is signed in.
        return user;
      } else {
        // No user is signed in.
        return null;
      }
    } catch (error) {
      console.error("Error getting user: ", error);
    }
  }

  function joinURL(baseURL, path, queryParams = '') {
    const url = `${baseURL.replace(/\/$/, '')}/${path.replace(/^\//, '')}`;
    return queryParams ? `${url}?${queryParams}` : url;
  }

  const checkSubscription = async (user) => {
    if (!user) {
      return;
    }
    const userDocRef = doc(db, "adminUsers", user.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (!userDocSnapshot.exists()) {
      console.error("User document not yet available in Firestore.");
      return;
    }
    const token = user ? await user.getIdToken() : "";
    const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, 'check-subscription'), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({ userId: user.uid }), // assuming user ID is required to check subscription
    });
    if (response.ok) {
      const subscriptionData = await response.json();
      if (subscriptionData && subscriptionData.subscriptions && subscriptionData.subscriptions.length > 0) {
        return subscriptionData?.subscriptions[0];
      } else {
        return null;
      }

    } else {
      const errorData = await response.json();
      console.error('Error checking subscription:', errorData);
      throw new Error(errorData.error.message);
    }
  };

  function generateDateLabels(startDate, numberOfDays) {
    const labels = [];
    const date = new Date(startDate);

    for (let i = 0; i < numberOfDays; i++) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because JavaScript months are 0-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      labels.push(formattedDate);
      date.setDate(date.getDate() + 1);
    }
    // console.log("labels: ", labels);
    return labels;
  }

  function alignDataWithLabels(labels, data) {
    const indexMapping = [];
    const alignedData = [];

    labels.forEach((label, i) => {
      let dataIndex = data.findIndex(d => d.t === label);
      if (dataIndex !== -1) {
        indexMapping.push(dataIndex);
        alignedData.push(data[dataIndex].y);
      } else {
        // No data for this label
        indexMapping.push(null);
        alignedData.push(null);
      }
    });

    return { alignedData, indexMapping };
  }

  function toSeconds(itemTime) {
    const itemTimeAsDate = new Date(itemTime);
    const itemTimeMinutes = itemTimeAsDate.getMinutes();
    const itemTimeHours = itemTimeAsDate.getHours();
    const itemTimeSeconds = itemTimeAsDate.getSeconds();
    return itemTimeHours * 3600 + itemTimeMinutes * 60 + itemTimeSeconds;
  }

  const withTimeout = (ms, promise) => {
    let timeout = new Promise((_, reject) => {
      let id = setTimeout(() => {
        clearTimeout(id);
        reject(`Timed out in ${ms} ms.`);
      }, ms)
    });
    return Promise.race([
      promise,
      timeout
    ]);
  };

  function asyncThrottle(fn, wait) {
    let inProgress = false;
    let queue = [];

    const wrapped = async (...args) => {
      if (inProgress) {
        return new Promise((resolve, reject) => {
          queue.push({ args, resolve, reject });
        });
      }

      inProgress = true;
      try {
        const result = await fn(...args);
        inProgress = false;

        if (queue.length > 0) {
          const nextCall = queue.shift();
          setTimeout(() => {
            wrapped(...nextCall.args).then(nextCall.resolve).catch(nextCall.reject);
          }, wait);
        }

        return result;
      } catch (error) {
        inProgress = false;
        if (queue.length > 0) {
          const nextCall = queue.shift();
          setTimeout(() => {
            wrapped(...nextCall.args).then(nextCall.resolve).catch(nextCall.reject);
          }, wait);
        }
        throw error;
      }
    };

    return wrapped;
  }

  function timestampObjectToISOString(timestampObj) {
    // Convert the Firestore timestamp object to a Date object
    console.log(timestampObj);
    const date = new Date(timestampObj.seconds * 1000 + timestampObj.nanoseconds / 1000000);
    
    // Convert the Date object to an ISO string
    return date.toISOString();
  }

  /**
   * Delays the code for `ms` milliseconds. Equivalent to python's time.sleep(ms)
   * 
   * @param {} ms - the milliseconds to sleep for
   */
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }



  export {
    isAdminChecker, getLastEightSomns, getLastNSomns, getLastNTimeZones,
    getRandomColor, getSleepDiffs, getLocationAndStamps,
    calculateAverage, calculateSleepVariation,
    clip,
    getCurrentUser, joinURL, Colors,
    checkSubscription, getProcessedLastSevenSomns, formatDateLabels,
    PRIVACY_LEVELS, getPrivacyLevel, getRandomUser, getAccessLevel, darkenColor, privacyNamesToInts, PRIVACY_LEVELS_REVERSED,
    privacyNamesToIntsMap, privacyIntsToNames, PRIVACY_LEVELS_STRINGS, validateDataAlignment,
    generateDateLabels, getSevenDaysAgo, formatDateToWeekdayMMDDYY, toSeconds,
    alignDataWithLabels, getTeamSleepDurations, 
    getMemberSleepDurations, getProcessedChartData, 
    withTimeout, asyncThrottle, fetchUserDataAndSleep, 
    hexToRgba, setPlotThreshold, getPlotThreshold, logEvent, timestampObjectToISOString, sleep
  };