import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Card, CardContent, TextField, CircularProgress, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../../src/fonts.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { auth } from '../utils/Firebase';
import { getSelectedPlanDetails } from '../helpers/utils';
import { joinURL } from "../helpers/utils";
import { selectUser } from '../slices/UserSlice';
import { useSelector } from 'react-redux';
import Mixpanel from 'mixpanel-browser';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const useStyles = makeStyles((theme) => ({
    page: {
        background: '#011D23',
        marginBottom: theme.spacing(0),
    },

    heroSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(8, 0),
        flexDirection: 'row',   
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },

    title: {
        maxWidth: '70%',
        fontWeight: 900,
        margin: theme.spacing(2,8),
        color: '#fff',
        textAlign: 'left',
        fontFamily: 'Figtree',
        fontSize: 70,
        marginRight:'auto',
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(4, 4),
          maxWidth: '100%',
          fontSize: 100,
          textAlign: 'left',
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(4, 4),
          maxWidth: '100%',
          fontSize: 50,
          textAlign: 'left',
        },
      },

      productTitleVariant: {
        maxWidth: '70%',
        fontWeight: 900,
        margin: theme.spacing(2, 8),
        color: '#fff',
        textAlign: 'left',
        fontFamily: 'Figtree',
        fontSize: 40, // Adjust the size as desired for the 'product' variant
        marginRight: 'auto',
        fontStyle: 'italic', // Add italic style to the 'product' variant
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(4, 4),
          maxWidth: '100%',
          fontSize: 70, // Adjust the size as desired for the 'product' variant on md breakpoint
          textAlign: 'left',
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(4, 4),
          maxWidth: '100%',
          fontSize: 30, // Adjust the size as desired for the 'product' variant on sm breakpoint
          textAlign: 'left',
        },
      },
      

      cardTitle: {
        fontFamily: 'Figtree',
        fontWeight: 'bold',
        color: '#fff',
        fontSize: 24,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
          fontSize: 19,
        },
      },

      cardDescription: {
        fontFamily: 'Figtree',
        fontSize: 15,
        textAlign: 'left',
        width: '43%',
        lineHeight: 2,
        marginLeft: theme.spacing(10),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
          fontWeight: 500,
          padding: theme.spacing(1, 0),
          marginLeft: theme.spacing(4),
        },
      },

      downloadCard: {
        backgroundColor: '#0F3038',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        margin: theme.spacing (4, 8),
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(0, 4),
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(0, 0),
        },
      },
      upperPortion: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
            
        }
      },

      joinheading: {
        fontSize: '2rem',
        textAlign: 'left',
        fontWeight: 1000,
        fontFamily: 'Figtree',
        margin: theme.spacing (5, 10),
        width: '100%',
        [theme.breakpoints.down('md')]: {
          fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.5rem',
          textAlign: 'center',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '1.5rem',
          textAlign: 'center',
        },
      },
    
      cardButton: {
        textTransform: 'none',
        backgroundColor: '#DEE8A1',
        color: '#011D23',
        fontFamily: 'Figtree',
        fontSize: 18,
        fontWeight: 800, 
        height: 45,
        width: '10%',
        padding: 10,
        display: 'flex', 
        justifyContent: 'center',
        marginLeft: theme.spacing (10),
        marginTop: theme.spacing (4),
        
        [theme.breakpoints.down('sm')]: {
          textTransform: 'none',
          margin: 'auto',
          height: 57,
          width: '90%',
          marginTop: theme.spacing (4),
          marginBottom: theme.spacing (0),
          
        },
      },

      form: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        marginLeft: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          margin: theme.spacing(0, 3),
        },  
      },

      inputContainer: {
        margin: theme.spacing (0, 7),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing (0, 0)
        }
      },
    
      input: {
        backgroundColor: '#1C434B',
        color: '#fff',
        height: 57,
        width: 500,
        margin: theme.spacing (3, 0),
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItem: 'center',
          margin: theme.spacing (2, 0),
          width: '100%',
          
        }    
      }, 
      bill: {
        fontFamily: 'Figtree',
        fontWeight: 'bold',
        color: '#fff',
        fontSize: 24,
        textAlign: 'left',
        marginTop: theme.spacing (5),
        [theme.breakpoints.down('sm')]: {
          fontSize: 19,
        },
      },

      totalBill: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing (0, 10),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing (0, 4),
        }

      },

      subTotal: {
        fontFamily: 'Figtree',
        fontWeight: 'bold',
        color: '#fff',
        fontSize: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        }

      },

      subtotalContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing (4, 2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing (4, 4),
          },

      },

      elements: {
        width: '100%'
      }
}))




const OfferComp = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  // checkSubscriptionStatus(navigate); // go straight to the dashboard if actually has a sub
  const [errorMessage, setErrorMessage] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  let location = useLocation();
  let subscription = location.state ? location.state.subscription : null;


  const stripeSetupIntent = async () => {
    if (user) {
      try {
        const token = await user.getIdToken();
        const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, 'setup-intent'), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            userId: user.uid,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  useEffect(() => {
    stripeSetupIntent();
  }, [user]);

  const appearance = {
      theme: 'stripe',
  };

  const options = {
      appearance,
  };
return (
  <>
    <div className={classes.page}>
      <div className={classes.heroSection}>
        <Typography variant="h2" className={classes.title}>
          Your members are one step away from better sleep.
        </Typography>
        <Typography variant="h3" className={classes.productTitleVariant}>
          {subscription && subscription.name}
        </Typography>
      </div>

        <div>
          <Card className={classes.downloadCard}>
            <CardContent className={classes.content}>
              <div className={classes.downloadSection}>
                <Typography variant="h1" component="h1" className={classes.joinheading}>
                  Payment Information
                </Typography>

                <Elements options={options} stripe={stripePromise} className={classes.elements}>
                  {subscription && 
                  <CheckoutForm
                    clientSecret={clientSecret}
                    user={user}
                    selectedPriceId={subscription.priceId}
                    setErrorMessage={setErrorMessage}
                    subscription={subscription}
                  />
                  }
                </Elements>

                {errorMessage && (
                  <Typography variant="body2" color="error">
                    {errorMessage}
                  </Typography>
                )}

                <svg width="100%" height="2" style={{ margin: 'auto', marginTop: 30 }}>
                  <line x1="0" y1="0" x2="100%" y2="0" stroke="#D8D8D8" strokeWidth="2" />
                </svg>
              </div>
              <div className={classes.subtotalContainer}>
                <Typography variant="h5" component="h2" className={classes.subTotal}>Sub Total</Typography>
                <Typography variant="h5" component="h2" className={classes.subTotal}> ${subscription.price}</Typography>
              </div>
            </CardContent>
          </Card>
        </div>

      <div>
        <div className={classes.totalBill}>
          <Typography variant="h5" component="h2" className={classes.bill}>
            Total billed today
          </Typography>
          <Typography variant="h5" component="h2" className={classes.bill}>
            ${subscription.price}
          </Typography>
        </div>
        <Typography variant="body4" style={{ color: '#fff' }} component="p" className={classes.cardDescription}>
          All prices are in USD and all sales are final. You will be charged ${subscription.price} immediately. You will be charged every 30 days thereafter while the subscription is active. Cancel at any time.
        </Typography>
      </div>
    </div>
  </>
);



};

export default OfferComp;