// store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/UserSlice';
import userDetailsReducer from './slices/UserDetailsSlice';
import subscriptionReducer from './slices/SubscriptionSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    userDetails: userDetailsReducer,
    subscription: subscriptionReducer,
  },
});

export default store;
