// userSlice.js

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { createNewUser } from "../utils/Firebase";

const VERIFY_EMAIL_ON = false; // Set to false to disable email verification

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    loadingUser: false // Adjusted to false since it won't be loading initially
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loadingUser = false;
    },
    setLoadingUser: (state, action) => {
      state.loadingUser = action.payload;
    }
  },
  // extraReducers block is removed as the Firestore interaction is now handled in UserDetailsSlice.js
});

export const selectIsUserLoaded = createSelector(
  (state) => state.user.loadingUser,
  (loadingUser) => !loadingUser // Notice the negation here
);

export const createUserAndNavigate = (userData, navigate) => {
  return (dispatch, getState) => {
    // Set loading state to true
    dispatch(setLoadingUser(true));
    // Here you can create the user
    dispatch(createNewUser(userData.email, userData.password, VERIFY_EMAIL_ON)).then(() => {
      // Set loading state to false once done
      dispatch(setLoadingUser(false));
      // Assuming you've a selector or logic to confirm user data is loaded
      const isUserLoaded = selectIsUserLoaded(getState());
      
      if (isUserLoaded) {
        // Navigate to the pricing page or wherever you need to go
        // Make sure you have a method to navigate in your application logic
        navigate("/pricing");
      }
    });
  };
};

export const { setUser, setLoadingUser } = userSlice.actions;

export const selectUser = createSelector(
  (state) => state.user.user,
  (user) => user
);

export const selectLoadingUser = createSelector(
  (state) => state.user.loadingUser,
  (loadingUser) => loadingUser
);

export default userSlice.reducer;
