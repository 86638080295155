import React, { useCallback, useEffect, useState } from 'react';
import LoadingWithValueLabel from '../../pages/LoadingDeterminant';
import { sleep } from '../../helpers/utils';
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import BroadcastButton from '../../assets/broadcast.png'; // https://icons8.com/icons/set
import CloseIcon from '@material-ui/icons/Close'; // Import Close Icon
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton'; // Import IconButton
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import socketIOClient from "socket.io-client";
import Tooltip from "@mui/material/Tooltip";
import { db } from '../../utils/Firebase';
import { collection, getDocs, writeBatch, addDoc } from "firebase/firestore";
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Timestamp } from "firebase/firestore";
import { logEvent } from '../../helpers/utils';


const useStyles = makeStyles((theme) => ({
  uploadButtonStyling: {
      width: '20%',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      marginRight: 20,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
},
title: {
    flex: 1
}
  
}));


const BroadcastNotification = ({ organizationId, handleClick }) => {
    const [parsedData, setParsedData] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const classes = useStyles();

    const handleSave = async () => {
        handleClick("BroadcastNotification", "Send to team button clicked");
        setIsSaving(true);

        await sleep(1000);

        setIsSaving(false);
        setShowDialog(false);

        parsedData.forEach(event => {
            if (!(typeof event.eventDate === 'string')) {
                event.eventDate = event.eventDate.toISOString();
            }
        });

        // const collectionRef = collection(db, `organizations/${organizationId}/notifications`);

        // const snapshot = await getDocs(collectionRef);
        // const batch = writeBatch(db);
        // snapshot.docs.forEach(doc => {
        //     batch.delete(doc.ref);
        // });
        // await batch.commit();

        // parsedData.forEach(event => {
        //     addDoc(collectionRef, event);
        // });

        try {
            const url = "https://us-central1-somnapp-5fd69.cloudfunctions.net/broadcastNotification";
            const response = await axios.post(url, {
                parsedData,
                organizationId,
            });
            console.log('Data saved successfully:', response);
        } catch (error) {
            console.error('Data save failed:', error);
        }
    };

    const handleChange = (eventOrValue, rowIndex, field, immediate = false) => {
      let value = eventOrValue && eventOrValue.target ? eventOrValue.target.value : eventOrValue;
  
      const newData = [...parsedData];
      newData[rowIndex][field] = value;
      newData[rowIndex].immediate = immediate;
      setParsedData(newData);
    };
  

    const handleInput = async () => {
      handleClick("BroadcastNotification", "Input button clicked");
      try {
          console.log("About to fetch data from Firestore");
          const teamScheduleRef = collection(db, `organizations/${organizationId}/notifications`);
          const teamScheduleSnapshot = await getDocs(teamScheduleRef);
  
          if (teamScheduleSnapshot.empty) {
              // No documents found in Firestore, show an empty row
              setParsedData([{ title: '', venue: '', eventDate: new Date(), immediate: false }]);
          } else {
            const fetchedData = teamScheduleSnapshot.docs.map(doc => {
              const data = doc.data();
              console.log("data: ", data);
              const eventDate = data.eventDate instanceof Timestamp ? data.eventDate.toDate() : data.eventDate;
              return { ...data, eventDate, immediate: false };
            });
            setParsedData(fetchedData);
            }
      } catch (error) {
          console.error("Error fetching events from Firestore: ", error);
      }

      setShowDialog(true);
    };

    const handleDeleteRow = (index) => {
      const newParsedData = [...parsedData];
      newParsedData.splice(index, 1);
      setParsedData(newParsedData);
      handleClick("BroadcastNotification", "Delete row button clicked");
    };

    const handleAddRow = () => {
      setParsedData([...parsedData, {title: '', venue: '', eventDate: new Date() }]);
      handleClick("BroadcastNotification", "Add row button clicked");
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div>
              <Tooltip title="Schedule Notification">
                <img className={classes.uploadButtonStyling} src={BroadcastButton} onClick={handleInput} alt="Upload Button" />
              </Tooltip>

              <Dialog open={showDialog} maxWidth="lg" fullWidth={true}>
              <div className={classes.titleContainer}>
                  <DialogTitle className={classes.title}>
                      Broadcast Notifications
                  </DialogTitle>
                  <IconButton onClick={() => setShowDialog(false)}>
                      <CloseIcon />
                  </IconButton>
              </div>
                  <DialogContent>
                      <Table>
                          <TableHead>
                              <TableRow>
                                  <TableCell>Title</TableCell>
                                  <TableCell>Body</TableCell>
                                  <TableCell>Scheduled Date</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {parsedData.map((row, rowIndex) => (
                                  <TableRow key={rowIndex}>
                                      {['title', 'body', 'eventDate'].map((field, colIndex) => (
                                          <TableCell key={colIndex}>
                                              {field === 'eventDate' ? (
                                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                                      <DateTimePicker
                                                          value={new Date(row[field])}
                                                          onChange={newValue => handleChange(newValue, rowIndex, field)}
                                                      />
                                                      <Button
                                                          variant="outlined"
                                                          color="primary"
                                                          onClick={() => {
                                                              const twoMinutesFromNow = new Date(Date.now() + 2 * 60 * 1000);
                                                              handleChange(twoMinutesFromNow, rowIndex, field, true);  // Set immediate to true
                                                          }}
                                                          style={{ marginLeft: '10px' }}
                                                      >
                                                          Schedule Now
                                                      </Button>

                                                  </div>
                                              ) : (
                                                  <TextField
                                                      value={row[field]}
                                                      onChange={e => handleChange(e, rowIndex, field)}
                                                  />
                                              )}
                                          </TableCell>
                                      ))}

                                      <TableCell>
                                          <IconButton onClick={() => handleDeleteRow(rowIndex)}>
                                              <DeleteIcon />
                                          </IconButton>
                                      </TableCell>
                                  </TableRow>
                              ))}
                              <TableRow>
                                  <TableCell colSpan={3}></TableCell>
                                  <TableCell>
                                      <IconButton onClick={handleAddRow}>
                                          <AddCircleIcon />
                                      </IconButton>
                                  </TableCell>
                              </TableRow>
                          </TableBody>
                      </Table>
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={handleAddRow} color="primary">
                          <AddCircleIcon />
                      </Button>
                      {isSaving ? (
                          <CircularProgress size={24} />
                      ) : (
                          <Button onClick={handleSave} color="primary">
                              Send to team
                          </Button>
                      )}
                  </DialogActions>
              </Dialog>
          </div>
      </LocalizationProvider>
    );
};

export default BroadcastNotification;
