// TeamInsights.js

import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import List from '@mui/material/List';
import UserListItem from './UserListItem';
import '../../styles/TeamInsights.css';
import '../../../src/fonts.css';
import { set } from 'date-fns';
import { PRIVACY_LEVELS_REVERSED, PRIVACY_LEVELS_STRINGS, logEvent } from '../../helpers/utils';

// {PRIVACY_LEVELS_REVERSED.get(privacySetting) !== PRIVACY_LEVELS_STRINGS.AGGREGATED && (
//   <ButtonGroup
//     activeButtonIndex={activeButtonIndex}
//     handleButtonClick={(index) => {
//       setActiveButtonIndex(index);
//       setIsBarGraphVisible(false);
//     }}
//   />
// )}


function TeamInsights({ user, team, handleClickUser, selectedUser, setSelectedUser, selectedUserForModal, setSelectedUserForModal, open, setOpen, privacyLevels, memberLabels, teamSleepDurations, teamSleepDurationsVBW, recentLocations, currentStartDate, organization}) {
  const [sortedTeam, setSortedTeam] = useState([]);
  useEffect(() => {
    if (team) {
      team.sort((a, b) => b.somnScore - a.somnScore);
      setSortedTeam([...team]);
    }
  }, [team, privacyLevels]);

  return (
    <div className='container' style={{marginTop: 0, marginBottom: -10 }}>
      <h1 style={{color: '#fff', fontFamily: 'Figtree', fontSize: 22, fontWeight: 900, marginTop: 15, marginBottom: 20 }}>Team members</h1>
      <List sx={{ width: '100%', bgcolor: '#0F3038' }}>
        {(!team || team.length === 0) ? <div>No viewable team members! Check privacy settings.</div>
        :
        team.map((member, index) => (
          <div key={index} onClick={() => {
            handleClickUser(member);
            logEvent(user.uid, "user-clicked-capsules-open", "Modal opened for " + member.firstName + " with id: " + member.uid);
          }}>
            <UserListItem 
              user={member}
              selectedUserForModal={selectedUserForModal}
              setSelectedUserForModal={setSelectedUserForModal}
              team={sortedTeam}
              className={selectedUserForModal && selectedUserForModal.uid === member.uid ? 'selected' : ''}
              open={open}
              setOpen={setOpen}
              handleClickUser={handleClickUser}
              privacyLevels={privacyLevels}
              memberLabels={memberLabels}
              teamSleepDurations={teamSleepDurations}
              teamSleepDurationsVBW={teamSleepDurationsVBW}
              recentLocations={recentLocations}
              currentStartDate={currentStartDate}
              organization={organization}
            />
          </div>
        ))}        
      </List>
      </div>
  )
}

export default TeamInsights;
