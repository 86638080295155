import React, { useState } from "react";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from "react-router-dom";
import styled from '@emotion/styled';
import { css, Global } from '@emotion/react';
import { joinURL } from "../helpers/utils";
import '../../src/fonts.css';
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/UserSlice";
import Mixpanel from "mixpanel-browser";

const useStyles = makeStyles((theme) => ({
  upperPortion: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '70%'
        
    }
  },

  portion: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10%',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        
    }
  },

  form: {
    fontFamily: 'Figtree',
    fontWeight: 900,
    width: 1000,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },

  billing: {
    fontFamily: 'Figtree',
    fontWeight: 900,
    color: '#fff',
    fontSize: 26,
    textAlign: 'left',
    marginTop: 40,
    marginLeft: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
  },

  cardNumber: {
    backgroundColor: '#1C434B',
    padding: 15,
    borderRadius: 10,
    width: '100%'
  },

  firstName: {
    width: '100%',
    
  },

  lastName: {
    width: '100%',
  },

  address: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  cardNo: {
    width: '45%',
    [theme.breakpoints.down('md', 'sm')]: {
      width: '100%',
    },
  },

  expiry: {
    width: '20%',
    [theme.breakpoints.down('md', 'sm')]: {
      width: '100%',
    },
  },

  cvc: {
    width: '15%',
    [theme.breakpoints.down('md', 'sm')]: {
      width: '100%',
    },
  }

}))

const Button = styled.button`
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;

  &:hover {
    filter: contrast(115%);
  }

  &:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.5;
    cursor: none;
  }
`;

const Input = styled.input`
  display: block;
  font-size: 0.9em;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #1C434B;
  background-color: #1C434B;
  border-radius: 10px;
`;



export default function CheckoutForm({ clientSecret, selectedPriceId, setErrorMessage, subscription }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  

  const classes = useStyles();

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements || !clientSecret) {
      setMessage('Payment cannot be processed at the moment. Please try again later.');
      return;
    }
  
    setIsLoading(true);
  
    // const cardElement = elements.getElement(CardElement);
    const cardElement = elements.getElement(CardNumberElement);

    const {error, setupIntent} = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: `${firstName} ${lastName}`,
          email: email,
          address: {
            line1: address,
            city: city,
            state: state,
            postal_code: postalCode,
          },
        },
      }
    });
    
    if (error) {
      setMessage(error.message);
      setIsLoading(false);
    } else {
      try {
        const reqBody = {
          userId: user.uid,
          priceId: selectedPriceId,
          paymentMethodId: setupIntent.payment_method,
          firstName: firstName,
          lastName: lastName,
          email: email,
          address: address,
          city: city,
          state: state,
          postalCode: postalCode,
          subscription: subscription,
          promoCode: promoCode
        };
        const token = user ? await user.getIdToken() : "";
        const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, 'create-subscription'), {
          method: "POST",
          headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify(reqBody),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
  
        setIsLoading(false);

        // refreshSubscription();
        // requestRefresh();
        sessionStorage.setItem("paymentSuccessful", "true");
        navigate('/dashboard');
        // navigate('/signup', { state: { subscription } });
      } catch (error) {
        console.error(error);
        setMessage('Error navigating to confirmation page.');
        setIsLoading(false);
      }
    }
  };


  
  const cardElementOptions = {
    layout: "tabs",
    style: {
      base: {
        fontFamily: 'Figtree',
        fontSize: 25,
        color: '#fff',
        '::placeholder': {
          color: '#B7B7B7',
          backgroundColor: '#1C434B',
          padding: '10px',
        },
        border: '1px solid #1C434B', // Add a border to the input fields
        borderRadius: '5px', // Add rounded corners
        padding: '10px', // Add padding
      },
      '::selection': {
        backgroundColor: '#a9ddf3', // Add background color for selected text
        color: 'white'
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }

  return (
    <>
    <Global styles={css`
        /* Paste your global CSS here */
        :root {
          --light-grey: #F6F9FC;
          --dark-terminal-color: #0A2540;
          --accent-color: #635BFF;
          --radius: 15px;
        }

        body {
          padding: 20px;
          font-family: 'Figtree';
          display: flex;
          justify-content: center;
          font-size: 1.2em;
          color: var(--dark-terminal-color);
        }

        main {
          width: 480px;
        }
        
        form > * {
          margin: 30px 50px;
        }
        
        input, select {
          display: block;
          font-size: 1.1em;
          width: 100%;
          margin-bottom: 10px;
          color: white;
        }
        
        label {
          display: block;
        }
        
        a {
          color: var(--accent-color);
          font-weight: 900;
        }
        
        small {
          font-size: .6em;
        }
        
        fieldset, input, select {
          border: 1px solid #efefef;
        }
        
        #payment-form {
          border: #F6F9FC solid 1px;
          border-radius: var(--radius);
          padding: 20px;
          margin: 20px 0;
          box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
        }
        
        #messages {
          font-family: 'Figtree';
          display: none; /* hide initially, then show once the first message arrives */
          background-color: #0A253C;
          color: #00D924;
          padding: 20px;
          margin: 20px 0;
          border-radius: var(--radius);
          font-size:0.7em;
        }
      `} />

    <form id="payment-form" onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.upperPortion}>
      <div className={classes.firstName}>
      <label htmlFor="firstName">First Name:</label>
      <Input
        type="text" 
        placeholder="Enter first name" 
        onChange={(e) => setFirstName(e.target.value)}
        // style={{ width: '200%' }}
      />
      </div>

      <div className={classes.lastName}>
      <label htmlFor="lastName">Last Name:</label>
      <Input
        type="text" 
        placeholder="Enter last name" 
        onChange={(e) => setLastName(e.target.value)}
        // style={{ width: '200%' }}
      />
      </div>
      </div>
      
      <div>
      <label htmlFor="email">Email:</label>
      <Input 
        type="email" 
        placeholder="Enter email" 
        onChange={(e) => setEmail(e.target.value)}
      />
      </div>

      <Typography className={classes.billing}>Billing Address</Typography>
      <div>
      <label htmlFor="address">Street Address:</label>
      <Input 
        type="text" 
        placeholder="Street Address" 
        onChange={(e) => setAddress(e.target.value)}
      />
      </div>

      <div className={classes.upperPortion}>
      <div className={classes.address}>
      <label htmlFor="city">City:</label>
      <Input 
        type="text" 
        placeholder="City" 
        onChange={(e) => setCity(e.target.value)}
      />
      </div>
      <div className={classes.address}>
      <label htmlFor="state">State:</label>
      <Input 
        type="text" 
        placeholder="State" 
        onChange={(e) => setState(e.target.value)}
      />
      </div>
      <div className={classes.address}>
      <label htmlFor="Postal/ZipCode">Postal/Zip Code:</label>
      <Input 
        type="text" 
        placeholder="Postal/Zip Code" 
        onChange={(e) => setPostalCode(e.target.value)}
      />
      </div>
      </div>


      <div className={classes.portion}>
      <div className={classes.cardNo}>
      <label>Card Number:</label>
      <CardNumberElement className={classes.cardNumber} options={cardElementOptions} />
      </div>

      <div className={classes.expiry}>
      <label>Expiry Date:</label>
      <CardExpiryElement className={classes.cardNumber} options={cardElementOptions} />
      </div>

      <div className={classes.cvc}>
      <label>CVC:</label>
      <CardCvcElement className={classes.cardNumber} options={cardElementOptions} />
      </div>
      </div>

      <div className={classes.portion}>
        <div className={classes.cardNo}>
          <label>Promo Code:</label>
          <Input
          type="text" 
          placeholder="Enter Promo Code" 
          onChange={(e) => setPromoCode(e.target.value)}
          />
        </div>
      </div>

      {/* <CardElement 
        options={paymentElementOptions} 
      /> */}

      <Button 
      disabled={isLoading || !stripe || !elements} 
      onClick={() => Mixpanel.track("Payment Button Clicked")}
      id="submit"
      >
        <span id="button-text">
          {isLoading ? <CircularProgress /> : "Pay now"}
        </span>
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    </>
  );
}
