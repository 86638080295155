import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authStates, withAuth } from "../components/auth";
import en from "../utils/greetingsEn";
import { withStyles } from '@material-ui/core/styles';
import { validateEmailPassword } from "../utils/helpers";
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import "../styles/login.css";
import logo from "../../src/assets/somnlogo2.png";
import TextField from '@material-ui/core/TextField';
import Loading from "../pages/Loading";
import { useSelector, useDispatch } from 'react-redux';
import { createUserAndNavigate } from '../slices/UserSlice';
import { selectUser, selectLoadingUser } from '../slices/UserSlice';
import { selectSubscription, selectLoadingSubscription } from '../slices/SubscriptionSlice';
import { createNewUser } from "../utils/Firebase";
import Mixpanel from 'mixpanel-browser';

const VERIFY_EMAIL_ON = false;

const styles = theme => ({
  heroSection: {
    display: 'flex',
    justifyContent: 'space-between',
    background: '#011D23',
    padding: theme.spacing(8, 0),
    alignItems: 'center',
    flexDirection: 'row',
    
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  formOutline: {
    border: '1px solid #000',
    borderRadius: '5px',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  logo: {
    width: '20%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },

  textField: { 
    backgroundColor: '#1C434B',
    '& label.Mui-focused': {
      color: 'purple',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'purple',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // borderColor: 'red',
      },
      '&:hover fieldset': {
        // borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        // borderColor: 'green',
      },
    },
    '& .MuiInputLabel-root': {
      color: '#B7B7B7',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)',  // You can adjust the values here
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -1px) scale(0.75)',  // You can adjust the values here
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },


  title: {
    fontWeight: 900,
    marginBottom: theme.spacing(4),
    color: '#fff',
    textAlign: 'left',
    fontFamily: 'Figtree',
    fontSize: 70,
    marginRight:'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(6),
    color: '#fff',
    textAlign: 'left',
    fontSize: 27,
    fontFamily: 'Figtree',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center',
    },
  },
  image: {
    maxWidth: 530,
    height: 'auto',
    marginRight: 80,
    
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80%',
      height: 'auto',
      alignItems: 'center',
      marginRight: 0,
    },
  },

  howItWorks: {
    background: '#011D23', 
    textAlign: 'center',
    fontWeight: 900,
    fontFamily: 'Figtree',
    fontSize: 70,
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
      textAlign: 'center',
    },
    
  },

  cardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', 
    flexDirection: 'row',
    background: '#011D23',
  },

  cards: {
    backgroundColor: '#0F3038',
    color: '#fff',
    maxWidth: '25%',
    height: 320,
    paddingBottom: theme.spacing(4),
    margin: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      maxHeight: 170,
      maxWidth: '100%',
      paddingBottom: theme.spacing(3),
    },
  },

  cardTitle: {
    fontFamily: 'Figtree',
    fontWeight: 600,
    fontSize: 20,
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(0.5),
    },
  },
  cardDescription: {
    fontFamily: 'Figtree',
    fontSize: 18,
    textAlign: 'left',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      fontSize: 7,
      paddingLeft: theme.spacing(0),
      paddingBottom: theme.spacing(4)
    },
  },

  comps: {
    width: 94, 
    margin: 40,
    [theme.breakpoints.down('sm')]: {
      width: 40,
      margin: 15,
    },
  },

  signup: {
    textTransform: 'none',
    backgroundColor: '#DEE8A1',
    color: 'black',
    fontFamily: 'Figtree',
    fontWeight: 600,
    fontSize: 17.5,
    padding: '12px 24px',
    borderRadius: theme.spacing(1), 
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, color 0.3s, transform 0.2s',
    '&:hover': {
      backgroundColor: '#524CD8',
      color: '#fff',
      transform: 'scale(1.02)',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
  }

});

function SignUp(props) {
  const [state, setState] = useState({
    email: "",
    password: "",
    retype: "",
    error: "",
    loading: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useDispatch(selectUser);
  const { loadingUser } = useDispatch(selectLoadingUser);
  const { subscription } = useDispatch(selectSubscription);
  const { loadingSubscription } = useDispatch(selectLoadingSubscription);
  const hasTracked = useRef(false);


  const { classes } = props;

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
  
    let error = "";
    if (target.type === "password" && name === "password" && value !== state.retype) {
      error = en.ERRORS.PASSWORD_MISMATCH;
    }
    if (target.type === "password" && name === "retype" && value !== state.password) {
      error = en.ERRORS.PASSWORD_MISMATCH;
    }
  
    setState({
      ...state,
      [name]: value,
      error: error || ""
    });
  };

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberMeEmail");
    if (rememberedEmail) {
      setState(prevState => ({
        ...prevState,
        email: rememberedEmail,
      }));
    }

    // Ensure Mixpanel tracking is only executed once
    if (!hasTracked.current) {
      Mixpanel.track_pageview({"page": "Signup"});
      hasTracked.current = true;
    }
  }, []);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    Mixpanel.track('Sign Up button Clicked');

    setState({ ...state, loading: true });

    if (state.error) {
      return;
    }

    const errorMsg = validateEmailPassword(state.email, state.password);

    if (errorMsg) {
      setState({ ...state, error: errorMsg });
      return;
    }

    try {
      const user = await createNewUser(state.email, state.password);
      navigate('/pricing');
    } catch (e) {
      setState({ ...state, loading: false });
    
      // Check for an email already in use error
      if (e.code === "auth/email-already-in-use") {
        setState({ ...state, error: "Email already in use. Please log in." });
        return;
      }
      
      // Check for a weak password error
      if (e.code === "auth/weak-password") {
        setState({ ...state, error: "Password should be at least 6 characters" });
        return;
      }
      
      // Handle other errors (you can add more specific error codes if needed)
      setState({ ...state, error: "An unexpected error occurred. Please try again." });
    }  
  };

  if (loadingUser || loadingSubscription || state.loading) {
    return <Loading />;
  }

  if (props.authState === authStates.INITIAL_VALUE) {
    return <Loading />;
  }

  if (props.authState === authStates.LOGGED_IN) {
    return navigate('/');
  }

  const errorMsg = state.error;

  return (
        <>
        <Navbar />
        <section className="vh-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#011D23' }}>
        <div className="container-fluid h-custom">
        <div className={`${classes.formOutline} card rounded-3 text-white`} style={{ backgroundColor: '#0F3038'}}>
          <div className="row d-flex justify-content-center align-items-center h-100">
          <img className={classes.logo} src={logo} alt="Logo"/>
            <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form>
                <div className="divider d-flex align-items-center my-4">
                  <p className="text-center fw-bold mb-0" style={{
                    color: '#fff',
                    textAlign: 'left',
                    fontSize: 27,
                    fontFamily: 'Figtree',
                    fontWeight: 600,}}>Sign up with email</p>
                </div>
                {/* Email input */}
                <div className="form-outline mb-4" style={{ backgroundColor: '#fff' }}>
                <TextField
                  id="typeEmail"
                  label="Email address"
                  type="email"
                  variant="outlined"
                  placeholder={en.FORM_FIELDS.EMAIL}
                  onChange={handleInputChange}
                  name="email"
                  className={classes.textField}
                  InputProps={{
                    style: { color: '#fff' },
                  }}
                  required
                  fullWidth
                />
                </div>
                {/* Password input */}
                <div className="form-outline mb-3" style={{ backgroundColor: '#fff' }}>
                  <TextField
                    id="typePassword"
                    label="Password"
                    type="password"
                    variant="outlined"
                    placeholder={en.FORM_FIELDS.PASSWORD}
                    onChange={handleInputChange}
                    name="password"
                    className={classes.textField}
                    InputProps={{
                      style: { color: '#fff' },
                    }}
                    required
                    fullWidth
                  />
                  
                </div>
                {/* Retype password input */}
                <div className="form-outline mb-4" style={{ backgroundColor: '#fff' }}>
                  <TextField
                    id="retype"
                    label="Password"
                    type="password"
                    variant="outlined"
                    placeholder={en.FORM_FIELDS.RETYPE_PASSWORD}
                    onChange={handleInputChange}
                    name="retype"
                    className={classes.textField}
                    InputProps={{
                      style: { color: '#fff' },
                    }}
                    required
                    fullWidth
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  {/* Checkbox */}
                  {/* <div className="form-check mb-0">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      defaultValue=""
                      id="rememberMe"
                    />
                    <label className="form-check-label" htmlFor="rememberMe" style={{ 
                      fontFamily: 'Figtree', 
                      fontSize: 18,
                      textAlign: 'left'}}>
                      Remember me
                    </label>
                  </div> */}
                </div>
                <div className="text-center text-lg-start mt-4 pt-2">
                {errorMsg && <p className="error">{errorMsg}</p>}
                  <button
                    type="submit"
                    className={classes.signup}
                    onClick={handleSubmit}
                    id="login-button"
                  >
                    Sign up
                  </button>
                  <p className="small mt-2 pt-1 mb-0" style={{fontFamily: 'Figtree', fontSize: 18}}>
                    Already a member?{" "}
                    {/* <a href="#!" className="link-danger">
                      Register
                    </a> */}
                    <Link to="/login">Login</Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
        </section>
        <Footer />
        </>
      );

}

export default withStyles(styles)(withAuth(SignUp));
