import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import lottieAnimation from '../assets/SomnLogomarkTransparentLottiefile.json';  // Update this path
import { Colors } from '../helpers/utils';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 200,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 200,
      display: 'flex',
    },
  },
    progress: {
        height: 4,
    },
    progressLabel: {
        fontSize: '50px !important',
        fontWeight: '900 !important',
        fontFamily: 'Figtree !important',
        color: `#FFFFFF !important`,
        textAlign: 'center !important',
    }
}));

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lottieAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet'
    }
};

function LinearProgressWithLabel(props) {
    const classes = useStyles();
    console.log("LinearProgressWithLabel props.value: ", props.value);

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '70%', marginTop: 4 }}>
          <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} className={classes.progress} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
              {isFinite(props.value) && (
                  <Typography variant="body2" className={classes.progressLabel}>
                      {`${Math.round(props.value)}%`}
                  </Typography>
              )}
          </Box>
      </Box>
  );  
}

export default function LoadingWithValueLabel({ progress, message = '' }) {
    const classes = useStyles();

    return (
      <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.DarkTeal900,
      }}
    >
            <div className={classes.logo}>
                <Lottie options={defaultOptions} />
            </div>
            <LinearProgressWithLabel value={progress} />
            {message && <Typography className={classes.progressLabel}>{message}</Typography>}
        </Box>
    );
}
