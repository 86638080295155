import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../components/NavBar';
import EmployersComp from '../components/Employers';
// import Header from './Header';
import Footer from '../components/Footer';
import SubscriptionComp from '../components/SubscriptionComp';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(8),
  },
}));

const EmployersPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Navbar />

      <SubscriptionComp 
        title="Help your employees get better sleep." 
        url="office_subscriptions" 
        subscriptionType="employer" 
        overCountText="Over 1000 employees?"
/>


      <Footer />
    </div>
  );
};

export default EmployersPage;