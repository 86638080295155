import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
// import { BarController } from 'chart.js';
// import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import {
  getLastSevenSomns, getSleepDiffs, formatDateLabels, getProcessedLastSevenSomns, getLocationAndStamps, getLastSevenTimeZones, PRIVACY_LEVELS,
  getSevenDaysAgo, generateDateLabels, formatDateToWeekdayMMDDYY, alignDataWithLabels, getProcessedChartData
} from '../../helpers/utils';
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import '../../../src/fonts.css';
import { Chart as ChartJS, BarController, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import DateSpread from './DateSpread';

ChartJS.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BarGraph = ({ team, averageSleepDurationByDay, privacyLevels, memberLabels,
  teamSleepDurations, recentLocations, currentStartDate, previousDaysCutoff }) => {
  const [chartData, setChartData] = useState([]);
  let labels = generateDateLabels(currentStartDate, previousDaysCutoff);
  const userLocations = new Set();
  useEffect(() => {
    const calculateDatasets = async () => {
      const allData = [];

      for (let index = 0; index < team.length; index++) {
        const user = team[index];
        const privacyLevel = privacyLevels.get(user.uid);

        if (privacyLevel === PRIVACY_LEVELS.NONE) {
          allData.push(null);
          continue;
        }

        const firstLocation = recentLocations[0];
        userLocations.add(firstLocation); // collect the unique user locations
        const somns = teamSleepDurations[user.uid];
        const lastSevenSomns = getProcessedChartData(labels, somns).map(somn => {
          if (!somn.sleepTimeReported && !somn.wakeTimeReported) {
            somn.y = 0; // Setting the default value to 0
          }
          return somn;
        });

        const userSleepDataByDay = {};

        for (const somn of lastSevenSomns) {
          if (somn.sleepTimeReported && somn.wakeTimeReported) {
            const date = somn.t; // assuming the date is in 't' property

            // Calculating the difference in hours
            const diffInMilliseconds = new Date(somn.wakeTime) - new Date(somn.sleepTime);
            const diff = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2);

            if (!userSleepDataByDay[date]) {
              userSleepDataByDay[date] = { date, totalSleep: +diff, count: 1 };
            } else {
              userSleepDataByDay[date].totalSleep += +diff;
              userSleepDataByDay[date].count += 1;
            }
          }
        }

        if (Object.keys(userSleepDataByDay).length === 0) {
          continue;
        }
        allData.push(Object.values(userSleepDataByDay).map(data => ({
          date: data.date,
          averageSleep: data.totalSleep / data.count,
        })));
      }

      const filteredData = allData.filter(item => item !== null);


      // Combine all user data to calculate the average sleep duration by day
      const averageSleepDurationByDay = {};
      filteredData.forEach((userSleepData, userIndex) => { // Note the userIndex as the second parameter
        userSleepData && userSleepData.forEach(data => { // Make sure userSleepData is not undefined
          if (!averageSleepDurationByDay[data.date]) {
            averageSleepDurationByDay[data.date] = { date: data.date, totalSleep: data.averageSleep, count: 1, contributingUsers: [team[userIndex].uid] }; // Initialize the array with the current user's UID
          } else {
            averageSleepDurationByDay[data.date].totalSleep += data.averageSleep;
            averageSleepDurationByDay[data.date].count += 1;
            averageSleepDurationByDay[data.date].contributingUsers.push(team[userIndex].uid); // Add the current user's UID to the array
          }
        });
      });


      const finalData = Object.values(averageSleepDurationByDay).map(data => ({
        date: data.date,
        averageSleep: data.totalSleep / data.count,
        contributingUsers: data.contributingUsers || [],
      }));
      setChartData(finalData);
    };

    calculateDatasets();
  }, [team, currentStartDate]);

  // const labels = generateLabels();
  // const chartDataMap = chartData.reduce((acc, data) => {
  //   // Reformatting the date to match the labels
  //   const [year, month, day] = data.date.split('-').map(Number);
  //   const dateObj = new Date(year, month - 1, day);
  //   const options = { weekday: 'short', year: '2-digit', month: '2-digit', day: '2-digit' };
  //   let dateStr = dateObj.toLocaleDateString('en-US', options);
  //   dateStr = dateStr.replace(', ', '\n');
  //   acc[dateStr] = data.averageSleep;
  //   return acc;
  // }, {});

  // Then, create the data array by using the generated labels
  // const dataValues = labels.map(label => chartDataMap[label] || 0);
  // const dataValues = labels.map(label => chartDataMap[label] || 0);
  const data = {
    labels: chartData.map(data => data.date),
    datasets: [
      {
        label: 'Aggregate Sleep Duration',
        data: chartData.map(data => ({ t: data.date, y: data.averageSleep })),
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 0,
      },
    ],
  };


  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: context => {
            const tooltipItem = context.parsed;
            const originalDataIndex = indexMapping[context.dataIndex];
            const contributingUsers = (originalDataIndex !== null && chartData[originalDataIndex])
              ? chartData[originalDataIndex].contributingUsers
              : [];
            const userList = contributingUsers.length;
            return [
              `Average Sleep: ${tooltipItem.y.toFixed(1)} hours`,
              `Contributors: ${userList}`
            ];
          },
        },
        render: {
          labels: {
            render: ({ label, value }) => {
              return `${label}: ${value.toFixed(1)}`; // Modify this to display your desired text
            },
            font: {
              size: 14,
              weight: 'bold',
              family: 'Figtree',
            },
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Hours',
          color: 'white',
          font: {
            size: 20,
            family: 'Figtree',
          },
        },
        ticks: {
          color: 'white',
          font: {
            size: 15,
            weight: 900,
            family: 'Figtree',
          },
        },
        grid: {
          display: true,
          color: '#23383C',
          drawOnChartArea: true,
          drawTicks: true,
          lineWidth: 2.5,
        },
        border: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: 'white',
          font: {
            size: 14,
            weight: 900,
            family: 'Figtree',
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    }
  };



  const commonTimezonesArray = Array.from(userLocations);
  const mostRecentTimezone = commonTimezonesArray[commonTimezonesArray.length - 1];
  // Check for the existence of datasets and its data before calling alignDataWithLabels
  let { alignedData, indexMapping } = data.datasets && data.datasets[0] && data.datasets[0].data
    ? alignDataWithLabels(labels, data.datasets[0].data)
    : { alignedData: [], indexMapping: [] };

  let modifiedData = {
    ...data,
    datasets: data.datasets.map((dataset, index) => ({
      ...dataset,
      // Only align the first dataset for now. If you have more datasets, you can loop and align each one.
      data: index === 0 ? alignedData : dataset.data
    })),
    labels: labels.map(dateStr => formatDateToWeekdayMMDDYY(dateStr, mostRecentTimezone)),
  };

  return (
    team.length > 0 ? (
        <Bar options={options} data={modifiedData} />
    ) : (
      <div className="no-data-message">No viewable team members! Check privacy settings.</div>
    )
  );
};

export default BarGraph;

