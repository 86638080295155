import React, { useCallback, useEffect, useState } from 'react';
import LoadingWithValueLabel from '../../pages/LoadingDeterminant';
import { joinURL, sleep } from '../../helpers/utils';
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import UploadButton from '../../assets/box.png'; // https://icons8.com/icons/set
import ManualInput from '../../assets/plus.png'; // https://icons8.com/icons/set
import CloseIcon from '@material-ui/icons/Close'; // Import Close Icon
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton'; // Import IconButton
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import socketIOClient from "socket.io-client";
import Tooltip from "@mui/material/Tooltip";
import { db } from '../../utils/Firebase';
import { collection, getDocs, writeBatch, addDoc } from "firebase/firestore";
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Timestamp } from "firebase/firestore";
import { logEvent } from '../../helpers/utils';




const useStyles = makeStyles((theme) => ({
  uploadButtonStyling: {
      width: '20%',
      height: 'auto',
      display: 'flex',
      alignItems: 'center',
      marginRight: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    flex: 1
  }
}));

export default function UploadSchedule({ organizationId, handleClick }) {
    const [parsedData, setParsedData] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [socket, setSocket] = useState(null);
    const [progress, setProgress] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [isUpload, setIsUpload] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    const classes = useStyles();

    // For testing, manually set these
    // const [showDialog, setShowDialog] = useState(true); // Manually set to true
    // const [progress, setProgress] = useState(50); // Manually set to 50
    // const [loadingMessage, setLoadingMessage] = useState('Testing...'); // Manually set to 'Testing...'

    const handleClose = () => {
      setShowDialog(false);
    };

    const handleManualInput = async () => {
      console.log("Manual input clicked");
      handleClick('UploadSchedule', 'Manual input clicked');
      try {
          console.log("About to fetch data from Firestore");
          const teamScheduleRef = collection(db, `organizations/${organizationId}/teamSchedule`);
          const teamScheduleSnapshot = await getDocs(teamScheduleRef);
  
          if (teamScheduleSnapshot.empty) {
              // No documents found in Firestore, show an empty row
              setParsedData([{ title: '', venue: '', eventDate: new Date() }]);
          } else {
            const fetchedData = teamScheduleSnapshot.docs.map(doc => {
              const data = doc.data();
              let eventDate;

              if (data.eventDate instanceof Timestamp) {
                  eventDate = data.eventDate.toDate();
              } else {
                  eventDate = data.eventDate; // or you can parse it if needed: new Date(data.eventDate);
              }

              return { ...data, eventDate };
            });
          console.log("fetchedData: ", fetchedData);
          setParsedData(fetchedData);
          }
  
      } catch (error) {
          console.error("Error fetching events from Firestore: ", error);
      }

      setShowDialog(true);
      setIsUpload(false);
    };
  

    const handleAddRow = () => {
      setParsedData([...parsedData, {title: '', venue: '', eventDate: new Date() }]);
      handleClick('UploadSchedule', 'Add row clicked');
    };

    const handleDeleteRow = (index) => {
      const newParsedData = [...parsedData];
      newParsedData.splice(index, 1);
      setParsedData(newParsedData);
      handleClick('UploadSchedule', 'Delete row clicked');
    };

    useEffect(() => {
      // Connect to WebSocket server
      const newSocket = socketIOClient(joinURL(process.env.REACT_APP_API_BASE_URL, "/firebase"), {
          transports: ['websocket'],
          path: '/socket.io'  // This should be the path on the server where the Socket.IO server serves its client files
      });
      
      console.log("Connecting to:", joinURL(process.env.REACT_APP_API_BASE_URL, "/firebase"));
      console.log("Organization ID:", organizationId);
      
      setSocket(newSocket);
  
      // Register organizationId (you say you already have this, so just sending it)
      newSocket.emit('register', organizationId);
    
      newSocket.on('connect', () => {
        console.log('Socket Connected:', newSocket.connected);
      });
  
      newSocket.on('connect_error', (error) => {
        console.log('Connection Error:', error);
      });
  
      newSocket.on('disconnect', (reason) => {
        console.log('Disconnected:', reason);
      });
  
      // Listen for updates from WebSocket server
      newSocket.on('update', (message) => {
        console.log('Received update:', message);
        setProgress(message.progress);
        setLoadingMessage(message.text);
        if (message.type === 'upload') {
            setShowDialog(true);  // Show the dialog only when a new upload comes in
        }
      });
    
  
      return () => {
          newSocket.disconnect();
      };
  }, []);
  
  

  const onDrop = useCallback(async (acceptedFiles) => {
    handleClick('UploadSchedule', 'User dropped a pdf file');
    setIsUpload(true);
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('organizationId', organizationId);

    try {
        // const url = joinURL(process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL, '/uploadSchedule');
        const url = "https://us-central1-somnapp-5fd69.cloudfunctions.net/uploadSchedule";
        console.log("sending request to: ", url);
        
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        
        const response = await axios.post(url, formData, config);
        setIsUpload(false); // override the loading screen
        console.log('Upload successful:', response);

        let parsedData = response.data;

        if (!parsedData) {
            console.error(`Invalid backend response: ${JSON.stringify(response.data)}`);
            return; // exit early if parsedData is undefined
        }
        console.log("parsedData from uploadSchedule onDrop: ", parsedData);

        // check if parsedData actually has anything
        if (parsedData.length === 0) {
          console.warn("No events found in uploaded schedule, defaulting to empty row...");
          handleAddRow();
        } else {
          setParsedData(parsedData);
        }
        setShowDialog(true);
        
    } catch (error) {
        console.error('Upload failed:', error);
        return; // exit early if upload failed
    }
}, []);


const handleSave = async () => {
  handleClick('UploadSchedule', 'User hit save');
  setIsSaving(true);

  await sleep(1000);

  setIsSaving(false);
  setShowDialog(false);
  // Convert the Scheduled Time to JS Date

  console.log("parsedData: ", parsedData);

  parsedData.forEach(event => {
    // if it's a date, convert to ISO string
    if (!event.eventDate instanceof String) {
      event.eventDate = event.eventDate.toISOString();
    }
  });

  console.log("parsedData: ", parsedData);

  const collectionRef = collection(db, `organizations/${organizationId}/teamSchedule`);
  console.log(`organizations/${organizationId}/teamSchedule`);
  console.log("collectionRef: ", collectionRef);

  // Delete all documents in the Firestore collection
  const snapshot = await getDocs(collectionRef);
  const batch = writeBatch(db);
  snapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
  });
  await batch.commit();

  // Add each entry in parsedData to the Firestore collection
  parsedData.forEach(event => {
      addDoc(collectionRef, event);
  });

  // Call cloud function to update Firestore with edited schedule
  // This part can be omitted if you're handling everything client-side
  try {
      const url = "https://us-central1-somnapp-5fd69.cloudfunctions.net/uploadEditedSchedule";
      const response = await axios.post(url, {
          parsedData: parsedData,
          organizationId,
      });
      console.log('Data saved successfully:', response);
  } catch (error) {
      console.error('Data save failed:', error);
  }

  
  
};


  const handleChange = (eventOrValue, rowIndex, field) => {
    let value;
    if (eventOrValue && eventOrValue.target) {
        // For TextField
        value = eventOrValue.target.value;
    } else {
        // For DatePicker
        value = eventOrValue;
    }
    
    const newData = [...parsedData];
    newData[rowIndex][field] = value;
    setParsedData(newData);
  };


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
            <Tooltip title="Manual">
                <img className={classes.uploadButtonStyling} src={ManualInput} onClick={handleManualInput} alt="Upload Button" />
            </Tooltip>

            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ?
                    <Tooltip title="Upload pdf">
                        <img className={classes.uploadButtonStyling} src={UploadButton} alt="Upload Button Active" />
                    </Tooltip> :
                    <Tooltip title="Upload pdf">
                        <img className={classes.uploadButtonStyling} src={UploadButton} alt="Upload Button" />
                    </Tooltip>
                }
            </div>

            <Dialog
                open={showDialog}
                maxWidth="lg"
                fullWidth={true}
                PaperProps={{
                    style: {
                        height: '80%',
                        width: '80%',
                    },
                }}
            >
                <div className={classes.titleContainer}>
                    <DialogTitle className={classes.title}>
                        Edit Schedule
                    </DialogTitle>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {progress >= 0 && progress < 100 && isUpload ? (
                    <LoadingWithValueLabel progress={progress} message={loadingMessage} />
                ) : !isUpload || progress >= 100 ? (
                    <>
                        <DialogContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Venue</TableCell>
                                        <TableCell>Scheduled Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {parsedData.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {['title', 'venue', 'eventDate'].map((field, colIndex) => (
                                                <TableCell key={colIndex}>
                                                    {field === 'eventDate' ? (
                                                        <DateTimePicker
                                                            value={new Date(row[field])}
                                                            onChange={(newValue) => handleChange(newValue, rowIndex, field)}
                                                        />
                                                    ) : (
                                                        <TextField
                                                            value={row[field]}
                                                            onChange={(e) => handleChange(e, rowIndex, field)}
                                                        />
                                                    )}
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                <IconButton onClick={() => handleDeleteRow(rowIndex)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={4}></TableCell>
                                        <TableCell>
                                            <IconButton onClick={handleAddRow}>
                                                <AddCircleIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAddRow} color="primary">
                                <AddCircleIcon />
                            </Button>
                            <div style={{ height: '36px' }}>  {/* Adjust the height as needed */}
                                {isSaving ? (
                                    <CircularProgress size={24} />
                                ) : (
                                    <Button onClick={handleSave} color="primary">
                                        Save
                                    </Button>
                                )}
                            </div>
                        </DialogActions>

                    </>
                ) : null }
            </Dialog>
        </div>
      </LocalizationProvider>
    );
  
}
