import React, { useContext, useEffect, useState, useRef } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../components/NavBar';
// import Header from './Header';
import Footer from '../components/Footer';
import SubscriptionComp from '../components/SubscriptionComp';
import { useNavigate, Redirect } from 'react-router-dom';
import Loading from '../pages/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSubscription, selectSubscription, selectLoadingSubscription } from '../slices/SubscriptionSlice';
import { selectUser } from '../slices/UserSlice';
import Mixpanel from 'mixpanel-browser';


const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(8),
  },
}));

const Pricing = () => {
  const user = useSelector(selectUser);
  const subscription = useSelector(selectSubscription);
  const loadingSubscription = useSelector(selectLoadingSubscription);
  const [hasRequestedRefresh, setHasRequestedRefresh] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasTracked = useRef(false);

  if (!hasTracked.current) {
    Mixpanel.track_pageview({"page": "Pricing"});
    hasTracked.current = true;
  }


  if (loadingSubscription) {
    return <Loading />;
  }

  if (subscription) {
    navigate('/dashboard');
  }

  return (
    <div>
      <Navbar />

      <SubscriptionComp 
        title="Help your team get better sleep." 
        url="team_subscriptions" 
        subscriptionType="team" 
        overCountText="Over 100 players?"
      />

      <Footer />
    </div>
  );
};

export default Pricing;