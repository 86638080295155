import React from 'react';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'About',
    };
  }
  render() {
    return (
      <div className="about">
        <h1>About</h1>
        <p>Our mission is to help people find meaningful work.</p>
      </div>
    );
  }
}

export default About;