// PrivacyDropdown.js

import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { privacyNamesToIntsMap, PRIVACY_LEVELS_REVERSED } from '../../helpers/utils';
import '../../../src/fonts.css';

const useStyles = makeStyles({
  dropdownPaper: {
    backgroundColor: '#28555F', // Set the background color
    color: '#fff', // Set the font color
    fontFamily: 'Figtree', // Set the font family
    fontSize: '13px', // Set the font size
  },
});

export default function PrivacyDropdown({ selectedPrivacy, onUpdate }) {
  const classes = useStyles();

  const options = Array.from(PRIVACY_LEVELS_REVERSED.values());
  const handleValueChange = (event, newValue) => {
    // Call the onUpdate function with the new value
    const intValue = privacyNamesToIntsMap.get(newValue);
    onUpdate(intValue);
  };

  return (
    <Autocomplete
      options={options} // Use the keys of PRIVACY_LEVELS as options
      getOptionLabel={(option) => option}
      value={selectedPrivacy}
      onChange={handleValueChange}
      style={{ width: '100%', height: 'auto'}}
      PaperComponent={(props) => (
        <Paper {...props} className={classes.dropdownPaper} />
      )}
      renderInput={(params) => 
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            style: {
              fontFamily: 'Figtree',
              fontSize: '13px',
              color: '#fff',
              backgroundColor: '#28555F',
            },
          }}
        />}
    />
  );
  
}
