// App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import TeamPage from './pages/Team';
import { auth } from './utils/Firebase';
import './App.css';
import Pricing from './pages/Pricing';
import LoginWrapper from './pages/Login';
import Loading from './pages/Loading';
import OfferPage from './pages/Schoolspage';
import EmployersPage from './pages/Employerspage';
import PaymentPage from './pages/PaymentPage';
import VerifyEmail from './pages/VerifyEmail'; // Adjust the path to your file structure
import ConfirmationPage from './pages/ConfirmationPage';
import SignUp from './pages/Signup';
import About from './pages/About';
import HowWorks from './pages/HowWorks';
import ErrorPage from './pages/ErrorPage';
import OrganizationBuilderComp from './pages/OrganizationBuilder';
import { useSelector } from 'react-redux';
import { selectUser, selectLoadingUser } from './slices/UserSlice';
import { selectSubscription, selectLoadingSubscription } from './slices/SubscriptionSlice';
import { useDispatch } from 'react-redux';
import { setUser } from './slices/UserSlice';
import { fetchSubscription } from './slices/SubscriptionSlice';
import { fetchUserDetails } from './slices/UserDetailsSlice';
import { joinURL } from "./helpers/utils";

import { ChartProvider } from './contexts/ChartContext';
import { trackEventWithMixpanel } from './helpers/mixpanelUtils';
import Mixpanel from 'mixpanel-browser';


function MainRoutes() {
  const user = useSelector(selectUser);
  const subscription = useSelector(selectSubscription);
  const navigate = useNavigate(); // Use the navigate function

  const renderTeamPageOrRedirect = () => {
    const paymentSuccessful = sessionStorage.getItem("paymentSuccessful") === "true";
    if (user && (subscription || paymentSuccessful)) {
      sessionStorage.removeItem("paymentSuccessful"); // Clear the flag
      return (
        <ChartProvider>
          <TeamPage />
        </ChartProvider>
      );
    } else if (user && !subscription) {
      return <Navigate to="/pricing" />;
    } else if (!user) {
      return <Navigate to="/login" />;
    }
  };
  

  const renderPaymentPageOrRedirect = () => {
    if (user) {
      const comingFromPricing = sessionStorage.getItem("comingFromPricing") === "true";
      if (comingFromPricing || subscription) {
        // Clear the state variable so it won't affect future navigations
        sessionStorage.removeItem("comingFromPricing");
        return <PaymentPage />;
      } else {
        return <Navigate to="/pricing" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  // Redirect to the dashboard if the user has a subscription
  useEffect(() => {
    if (user && subscription) {
      navigate('/dashboard');
    }
  }, [user, subscription, navigate]);

  return (
    <Routes>
      <Route path="/" element={user ? (subscription ? <TeamPage /> : <Pricing />) : <LoginWrapper />} />
      <Route path="/dashboard" element={renderTeamPageOrRedirect()} />
      <Route path="/login" element={<LoginWrapper />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/about" element={<About />} />
      <Route path="/for_schools" element={<OfferPage />} />
      <Route path="/how_it_works" element={<HowWorks />} />
      <Route path="/confirmation-page" element={<ConfirmationPage />} />
      <Route path="/Offerpage" element={<OfferPage />} />
      <Route path="/Employerspage" element={<EmployersPage />} />
      <Route path="/payment" element={renderPaymentPageOrRedirect()} />
      <Route path="/confirmation-page" element={<ConfirmationPage />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/organization-builder" element={<OrganizationBuilderComp />} />
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  );
}

function App() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const loadingUser = useSelector(selectLoadingUser);
  const loadingSubscription = useSelector(selectLoadingSubscription);
  Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: false, // remove for prod
    ignore_dnt: true,
    track_pageview: true, 
    // persistence: 'localStorage'
  });

  useEffect(() => {
    // Listener for authentication changes (user logging in/out)
    const unsubscribeUser = auth.onAuthStateChanged(async (currentUser) => {
      dispatch(setUser(currentUser));
      if (currentUser) {
        dispatch(fetchSubscription(currentUser)); // Fetch subscription when user logs in
        dispatch(fetchUserDetails(currentUser));
  
        // Start a new session
        const token = await currentUser.getIdToken(); // Assuming you're using Firebase auth tokens

        
        try {
          const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, 'start-session'), {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            credentials: 'include',
            body: JSON.stringify({ userId: currentUser.uid }),
          });
          console.log("response.cookies", response.cookies);
          if (response.ok) {
            console.log("Session started");
          } else {
            console.log("Failed to start session", await response.json());
          }
        } catch (error) {
          console.error("Error starting session:", error);
        }
      }
    });
  
    // Return a cleanup function to unsubscribe the listener when the component unmounts
    return () => {
      unsubscribeUser();
    };
  }, [dispatch]);
  

  if (loadingUser || loadingSubscription) return <Loading />;

  return (
    <Router>
      <MainRoutes />
    </Router>
  );
}

export default App;