import React, { useState } from 'react';
import { Colors } from '../helpers/utils';
import UploadButton from './UploadButton';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';


const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  display: block;
  font-size: 0.9em;
  width: 20%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #1C434B;
  background-color: #1C434B;
`;

const NameEditor = ({
  organization,
  updateAndSwitchToReadMode,
  editingName,
  setEditingName,
  organizationName,
  setOrganizationName,
  showError,
  setShowError
}) => {

  return (
    editingName ? (
      <InputContainer>
        <Input
          type="text"
          value={organizationName !== null && organizationName !== undefined ? organizationName : organization.name}
          onChange={e => {
            setOrganizationName(e.target.value);
            if (e.target.value.trim()) {
              setShowError(false);  // Hide the error if there's any input
            }
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              updateAndSwitchToReadMode();
            }
          }}
        />
        <UploadButton onClick={updateAndSwitchToReadMode} showError={showError} />
        {showError && <span style={{ color: Colors.ErrorRed, marginLeft: '10px' }}>Team name cannot be empty</span>}
      </InputContainer>
    ) : (
      <>
        {organization.name}
        <EditIcon
          style={{ marginLeft: '1rem', cursor: 'pointer' }}
          onClick={() => setEditingName(true)}
        />
      </>
    )
  );
};

export default NameEditor;
