// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendEmailVerification } from 'firebase/auth';
import { getFirestore, collection, getDocs, doc, setDoc, getDoc, updateDoc, addDoc, where, query, Timestamp } from 'firebase/firestore';
import { PRIVACY_LEVELS, joinURL, PRIVACY_LEVELS_REVERSED, logEvent } from "../helpers/utils";
import { send } from "process";
import { Axios } from "axios";
import Mixpanel from 'mixpanel-browser';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_KEY);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

// const db = getFirestore(app);
const auth = getAuth(app);

// Get a list of cities from your database
async function getUsers(db) {
  const usersCol = collection(db, 'users');
  const userSnapshot = await getDocs(usersCol);
  const userList = userSnapshot.docs.map(doc => doc.data());
  return userList;
}

export function attachAuthListener(handler) {
  return onAuthStateChanged(auth, user => {
    handler(user);
  });
}

// creates a default team name
const generateRandomTeamName = () => {
  const randomNumber = Math.floor(Math.random() * 10000);
  return `Team#${randomNumber}`;
};

const createAdminUser = async (user) => {
  console.log("create admin user called...");
  const baseUrl = "https://us-central1-somnapp-5fd69.cloudfunctions.net";
  const token = await user.getIdToken();

  console.log("user.uid: ", user.uid);

  // do mixpanel identification here
  const temporaryUserId = Mixpanel.get_distinct_id();
  console.log("temporaryUserId", temporaryUserId);
  Mixpanel.alias(user.uid, temporaryUserId);
  console.log("Mixpanel.get_distinct_id()", Mixpanel.get_distinct_id());

  Mixpanel.identify(user.uid);
  
  // set mixpanel properties
  setTimeout(() => {
    Mixpanel.people.set({
      "$email": user.email,
      "$created": user.metadata.creationTime
    });

    console.log("Mixpanel.get_distinct_id() after identify and then people.set", Mixpanel.get_distinct_id());

    // Other operations to perform after a delay
  }, 500); // Delay in milliseconds


  const createAdminUserUrl = `${baseUrl}/createAdminUser`;
  const response = await fetch(createAdminUserUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId: user.uid, email: user.email })
  });

  if (response.ok) {
    console.log("Created admin user");
    return;
  } else {
    console.error("Error creating admin user");
    throw new Error("Error creating admin user");
  }
};

const organizationHasValidSubscription = async (user) => {
  const baseUrl = process.env.REACT_APP_FIREBASE_FUNCTIONS_BASE_URL;
  const token = await user.getIdToken();

  const createAdminUserUrl = `${baseUrl}/organizationHasValidSubscription`;
  const response = await fetch(createAdminUserUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ userId: user.uid, email: user.email })
  });

  if (response.ok && response.data && response.data.organizationHasValidSubscription) {
    console.log("Created admin user");
    return response.data.organizationHasValidSubscription;
  } else {
    console.error("Error creating admin user");
    throw new Error("Error creating admin user");
  }
};



export async function createNewUser(email, password, verifyEmail = false) {
  console.log("createNewUser called...");
  const auth = getAuth();
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);

  const user = userCredential.user;

  // Ensure the user properties are set (adminUser, organization, stripeId)
  try {
    console.log("Ensuring user properties...");
    await createAdminUser(user);
    console.log("Ensured user properties");
  } catch (error) {
    console.error("Error ensuring user properties:", error, ". Please contact support.");
    return;
  }

  if (verifyEmail) {
    const token = user ? await user.getIdToken() : ""; // Generate or fetch your verification token
    const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, 'send-verification-email'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify({ userId: user.uid, email: email, token: token })
    });
    
    if (response.ok) {
    } else {
      console.error("Error sending custom verification email");
    }
  }

  logEvent(user, "sign-up", "created new user: " + user.uid, false);

  return user;
}



export async function signIn(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export async function signOut_() {
  Mixpanel.reset();
  await signOut(auth);
}

export { app, db, analytics, auth };