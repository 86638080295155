// subscriptionSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { checkSubscription } from '../helpers/utils';
import { createSelector } from 'reselect';

export const fetchSubscription = createAsyncThunk('subscription/fetchSubscription', async (user) => {
  const subscription = await checkSubscription(user);
  return subscription;
});

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscription: null,
    loadingSubscription: false // Renamed to loadingSubscription
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscription.pending, (state) => {
        state.loadingSubscription = true; // Updated to loadingSubscription
      })
      .addCase(fetchSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loadingSubscription = false; // Updated to loadingSubscription
      });
  }
});

export const selectSubscription = createSelector(
  (state) => state.subscription.subscription,
  (subscription) => subscription
);

export const selectLoadingSubscription = createSelector(
  (state) => state.subscription.loadingSubscription, // Updated to loadingSubscription
  (loadingSubscription) => loadingSubscription
);

export default subscriptionSlice.reducer;
