import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Button, Card, CardContent, CardMedia, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../../src/fonts.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { joinURL, useCheckSubscriptionStatus } from "../helpers/utils";
import { auth } from '../utils/Firebase';
import Loading from '../pages/Loading';
import { selectUser } from '../slices/UserSlice';
import { useSelector } from 'react-redux';
import Mixpanel from 'mixpanel-browser';


const useStyles = makeStyles((theme) => ({
    page: {
        background: '#011D23',
        marginBottom: theme.spacing(0)
    },

    heroSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(8, 0),
        flexDirection: 'row',   
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
          alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },

    title: {
        maxWidth: '45%',
        fontWeight: 900,
        margin: theme.spacing(2,8),
        color: '#fff',
        textAlign: 'left',
        fontFamily: 'Figtree',
        fontSize: 70,
        marginRight:'auto',
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(4, 4),
          maxWidth: '100%',
          fontSize: 100,
          textAlign: 'left',
        },
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(4, 4),
          maxWidth: '100%',
          fontSize: 50,
          textAlign: 'left',
        },
      },

      cardContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', 
        flexDirection: 'row',
        margin: theme.spacing(4, 8),
        [theme.breakpoints.down('sm')]: {
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          maxWidth: '80%',
          paddingBottom: theme.spacing(3),
        },
      },
    
      cards: {
        backgroundColor: '#0F3038',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        width: '32%',
        height: '100%',
        margin: theme.spacing (0),
        
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          padding: theme.spacing(1, 1),
          margin: theme.spacing (2),
        },
      },
    
      content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: 'auto',
        padding: theme.spacing (5, 2)
      },
    
      cardTitle: {
        fontFamily: 'Figtree',
        fontWeight: 'bold',
        fontSize: 30,
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
          fontSize: 19,
          padding: theme.spacing(0),
        },
      },
      cardDescription: {
        fontFamily: 'Figtree',
        fontSize: 15,
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          fontSize: 16,
          fontWeight: 500,
          padding: theme.spacing(1, 0),
        },
      },

      price: {
        fontFamily: 'Figtree',
        fontWeight: 'bold',
        fontSize: 43,
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1.5),
        margin: theme.spacing(3, 0),
        [theme.breakpoints.down('sm')]: {
          fontSize: 19,
          padding: theme.spacing(0),
        },
      },

      downloadSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3, 0),
        alignItems: 'center',
        flexDirection: 'row',
        padding: theme.spacing (5, 0),
      
       
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'center',
        },
      },

      inquiryHeading: {
        fontSize: '2rem',
        textAlign: 'left',
        fontWeight: 700,
        fontFamily: 'Figtree',
        marginBottom: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
          fontSize: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.5rem',
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: '1rem',
        },
      },
    
      cardButton: {
        textTransform: 'none',
        backgroundColor: '#DEE8A1',
        color: '#011D23',
        fontFamily: 'Figtree',
        fontSize: 18,
        fontWeight: 800,
        height: 45,
        width: '90%',
        padding: 10,
        margin: 'auto',
    
        [theme.breakpoints.down('sm')]: {
          textTransform: 'none',
          marginLeft: 0,
          height: 57,
          width: '100%',
          marginBottom: theme.spacing (3),
        },
      },
    
      contactButton: {
        textTransform: 'none',
        backgroundColor: '#DEE8A1',
        color: '#011D23',
        fontFamily: 'Figtree',
        fontSize: 15,
        fontWeight: 800,
        height: 57,
        width: 150,
        padding: 10,
        marginLeft: 10,
    
        [theme.breakpoints.down('sm')]: {
          textTransform: 'none',
          marginLeft: 0,
          alignItems: 'center',
          height: 57,
          width: '100%',
          marginBottom: theme.spacing (3),
        },
      },

      form: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: theme.spacing(20),
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          margin: theme.spacing(0, 3),
        },
       
        
      },
    
      input: {
        backgroundColor: '#1C434B',
        color: '#fff',
        height: 57,
        width: 400, 
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: theme.spacing (2, 0),
          width: '100%',
          maxWidth: 500,
        } 
    
      },
    
    
      downloadCard: {
        marginBottom: theme.spacing(0),
        backgroundColor: '#0F3038',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        margin: theme.spacing (4, 8),
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(0, 4),
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(0, 3),
        },
      }
     
    
}))


const SubscriptionComp = ({ title, url }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    // useCheckSubscriptionStatus(navigate); // go straight to the dashboard if actually has a sub
    const [products, setProducts] = useState([]);
    const hasTracked = useRef(false);

    const fetchStripeProducts = async () => {
      try {
        const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, url), {
          headers: {
            "Content-Type": "application/json",
          }
        });
      
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error:', error);
      }
    }
    
    useEffect(() => {
      // Fetch your products as needed
      fetchStripeProducts();
    }, []);
    

    const handleSubmit = (event) => {
      event.preventDefault();
  
      const email = event.target.email.value;
      const url = `https://somnapp.us5.list-manage.com/subscribe/post?u=f5c8d8e542c0edaf0769221fe&id=4c39e093b0&f_id=002c59e6f0&EMAIL=${encodeURIComponent(email)}`;
  
      window.location.href = url;
    };
  
  return (
      <>
      <div className={classes.page}>
      <div className={classes.heroSection}>
          <Typography variant="h2" className={classes.title}>
          {title}
          </Typography>
      </div>
      <div className={classes.cardContainer}>  
      {products && products.length > 0 ? 
      products.map((subscription, index) => (
          <Card key={index} className={classes.cards}>
              <CardContent className={classes.content}>
                  <Typography variant="h5" component="h2" className={classes.cardTitle}>
                      {subscription.name || "No Name Provided"}
                  </Typography>
                  <Typography variant="body4" style={{ color: '#fff' }} component="p" className={classes.cardDescription}>
                      {subscription.description || "No Description Provided"}
                  </Typography>
                  <Typography variant="h5" component="h2" className={classes.price}>
                      ${subscription.price || "No Price Provided"}/month
                  </Typography>
                  <Button
                    onClick={() => {
                      if (user) {
                        // Save state to remember that the user is coming from pricing
                        sessionStorage.setItem("comingFromPricing", "true");
                        Mixpanel.track("Clicked plan", { plan: subscription.name });
                        navigate('/organization-builder', { state: { subscription } });
                      } else {
                        navigate('/signup');
                      }
                    }}
                    color="inherit"
                    type="submit"
                    className={classes.cardButton}
                  >
                    Choose Plan
                  </Button>

              </CardContent>
          </Card>
      ))
      :
      <div className={classes.error}>
        <Loading />
      </div>
  }

      </div>

        <div >
          <Card className={classes.downloadCard}>
          <CardContent className={classes.content}>
          <div className={classes.downloadSection}>
          <Container >
          <Typography  className={classes.inquiryHeading}>
            Over 1000 members?
          </Typography>       
        </Container>

        <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              type="email"
              name="email"
              placeholder="Enter your email"
              variant="outlined"
              className={classes.input}
              InputProps={{
                style: { color: '#fff' },
              }}
              
            />
            <Button color="inherit" type="submit" className={classes.contactButton}>Contact Us</Button>
        </form>
      </div>
          </CardContent>
          </Card>
        </div>
        </div>
      </>
  );
  };

export default SubscriptionComp;
