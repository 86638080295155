import React from 'react';
import { Container, Typography, Button, Card, CardContent, CardMedia, TextField } from '@material-ui/core';
import TimezoneDropdown from './TimezoneDropdown';
import PrivacyDropdown from './PrivacyDropdown';
import { privacyIntsToNames } from '../../helpers/utils';
import membersCount from '../../../src/assets/group-chat.png';
import TeamCode from '../../../src/assets/one-time-password.png';
import privacySettings from '../../../src/assets/document.png';
import Tooltip from "@mui/material/Tooltip";




function MiscInfo({ uniqueCode, teamTimezone, updateTeamTimezone, organization, userViewingPreference, updateViewingPreference }) {
  const privacyLevel = privacyIntsToNames.get(userViewingPreference);
  return (
    <>
        <div className='container' style={{ marginTop: 0, marginBottom: -10 }}>
            <Typography style={{ color: '#fff', fontFamily: 'Figtree', fontSize: 25, fontWeight: 900, marginTop: 15, marginBottom: 14, textAlign: 'left' }}>
                Team Info
            </Typography>
            <h1 style={{ borderRadius: 12, color: '#fff', fontFamily: 'Figtree', fontSize: 17, fontWeight: 900, marginTop: 15, marginBottom: 5 }}>
                <Tooltip title="Team Code">
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={TeamCode} style={{ marginRight: '2rem', width: 35 }} alt="Team Code" />
                        {uniqueCode}
                    </span>
                </Tooltip>
            </h1>
            <h1 style={{ borderRadius: 12, color: '#fff', fontFamily: 'Figtree', fontSize: 17, fontWeight: 900, marginTop: 15, marginBottom: 5 }}>
                <Tooltip title="Team members">
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={membersCount} style={{ marginRight: '2rem', width: 35 }} alt="Members Count" />
                        {organization.members.length} / {organization.capacity}
                    </span>
                </Tooltip>
            </h1>
        </div>
    </>
);
}

export default MiscInfo;
