import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import styled from '@emotion/styled';
import { Colors } from '../helpers/utils';

const Button = styled.button`
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;

  &:hover {
    filter: contrast(115%);
  }

  &:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.5;
    cursor: none;
  }
`;


const UploadButton = ({ onClick, showError }) => {
  const [state, setState] = useState('idle'); // idle, uploading, done

  const handleClick = () => {
    if (showError) {
      return; // If there's an error, don't proceed
    }

    setState('uploading');
    onClick && onClick();

    // Simulate upload
    setTimeout(() => {
      setState('done');

      // Reset after some time
      setTimeout(() => {
        setState('idle');
      }, 2000);
    }, 1000);
  };

  return (
    <div>
      {showError && <CloseIcon style={{ color: Colors.ErrorRed }} />}
      {state === 'idle' && !showError && <Button onClick={handleClick}>Save</Button>}
      {state === 'uploading' && !showError && <CircularProgress />}  {/* Added condition here */}
      {state === 'done' && !showError && <CheckCircleIcon style={{ color: 'green' }} />}
    </div>
  );
};


export default UploadButton;
