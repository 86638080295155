import React from 'react';
import SleepDoughnut from '../components/Dashboard/SleepDoughnut';
import SleepCard from '../components/Dashboard/SleepCard';
import Footer from '../components/Footer';
import { Colors, clip } from '../helpers/utils';

const TeamLeaderboard = ({
  classes,
  selectedUser,
  selectedUserPrivacyLevel,
  PRIVACY_LEVELS_STRINGS,
  PRIVACY_LEVELS_REVERSED,
  privacyLevels,
  calculateSleepVariation,
  anonymousFilteredTeam,
  setAnonymousFilteredTeam,
  memberLabels,
  teamSleepDurations
}) => {
  return (
    <>
      <div>
        <h2 className={classes.headings}>Team Leaderboard</h2>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <>
            <div className={classes.leaderboard}>
              <SleepCard
                className={classes.sleepCard}
                title="Sleep Duration"
                team={anonymousFilteredTeam}
                setTeam={setAnonymousFilteredTeam}
                calculateValue={(times) => { times = times.filter(b => b.sleepTimeReported && b.wakeTimeReported); return Math.max(times.reduce((a, b) => a + b.diff, 0) / times.length, 0); }}
                warningCondition={(value) => value < 7}
                sortOrder="asc"
                warningMessage="Low average sleep!"
                memberLabels={memberLabels}
                sx={{ flex: '1 1 calc(30% - 40px)', margin: '5px' }}
                teamSleepDurations={teamSleepDurations}
              />
              <SleepCard
                className={classes.sleepCard}
                title="Sleep Debt"
                team={anonymousFilteredTeam}
                setTeam={setAnonymousFilteredTeam}
                calculateValue={(times, hoursForRest) => {
                  times = times.filter(b => b.sleepTimeReported && b.wakeTimeReported);
                  let mappedValues = times.map(time => hoursForRest - time.diff);
                  let value = mappedValues.reduce((a, b) => a + b, 0);
                  return Math.max(value, 0);
                }}
                warningCondition={(value) => value > 3}
                sortOrder="desc"
                warningMessage="High sleep debt!"
                memberLabels={memberLabels}
                sx={{ flex: '1 1 calc(30% - 40px)', margin: '5px' }}
                teamSleepDurations={teamSleepDurations}
              />
              <SleepCard
                className={classes.sleepCard}
                title="Sleep Variation"
                team={anonymousFilteredTeam}
                setTeam={setAnonymousFilteredTeam}
                calculateValue={calculateSleepVariation}
                warningCondition={(value) => value > 2}
                sortOrder="desc"
                warningMessage="High sleep variation!"
                memberLabels={memberLabels}
                sx={{ flex: '1 1 calc(30% - 40px)', margin: '5px' }}
                teamSleepDurations={teamSleepDurations}
              />
            </div>
          </>
      </div>
    </>
  );
}

export default TeamLeaderboard;
