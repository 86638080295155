// UserListItem.js

import React, { useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { Button, Modal, Box } from '@material-ui/core';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import SleepCapsule from './SleepCapsule';
import SleepDoughnut from './SleepDoughnut';
import Gauge from './Gauge';
import CapsuleHeader from './CapsuleHeader';
import { getLastSevenSomns, getSleepDiffs, PRIVACY_LEVELS, Colors, clip, calculateSleepVariation, toSeconds } from '../../helpers/utils';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from '@mui/material';

import '../../../src/fonts.css';
import { Autorenew, CompassCalibrationOutlined } from '@material-ui/icons';

function UserListItem({
  user,
  selectedUserForModal, // Rename this to selectedUserForModal for clarity
  setSelectedUserForModal, // Rename this to setSelectedUserForModal for clarity
  team,
  className,
  open,
  setOpen,
  handleClickUser,
  privacyLevels,
  memberLabels,
  teamSleepDurations,
  teamSleepDurationsVBW,
  recentLocations,
  currentStartDate,
  organization
}) {

  const [userBlurb, setUserBlurb] = useState(null);
  const [lastSleepDuration, setLastSleepDuration] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const days = [];
  for (let i = 0; i <= 6; i++) {
      const date = new Date(currentStartDate);
      date.setDate(currentStartDate.getDate() + i);
      const formattedDate = date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' });
      days.push(formattedDate);
  }

  useEffect(() => {
    const getLastNightSleep = async () => {
      const privacyLevel = privacyLevels.get(user.uid);
      if (privacyLevel === PRIVACY_LEVELS.NONE) {
        setUserBlurb("Not sharing any data.");
        return null;
      }
      // const querySnapshot = await getLastSevenSomns(user.uid);
      // const somns = getSleepDiffs(querySnapshot);
      const somns = teamSleepDurations[user.uid];
      if (somns && somns.length > 0) {
        const lastSomn = somns[somns.length - 1];
        setUserBlurb(`Hours slept last night: ${parseFloat(lastSomn.diff.toFixed(1))}`)
      }
    };

    getLastNightSleep();
  }, [user]);

  return (
    <>
      <ListItem
        alignItems="flex-start"
        style={{
          backgroundColor: '#28555F',
          borderRadius: 20,
          marginBottom: 10,
          border: className === 'selected' ? '3px solid #00AF7B' : 'none',
        }}
      >
        <ListItemText
          primary={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>

              <Typography
                variant="body1"
                style={{
                  fontFamily: 'Figtree',
                  fontWeight: 900,
                  color: 'white',
                  fontSize: '17px',
                }}
              >
                {memberLabels.get(user.uid)}
              </Typography>

              <Tooltip title="Click for more info">
                <InfoIcon
                  fontSize="small" // Adjust the size as needed
                  style={{
                    marginLeft: '8px', // Add some spacing between the icon and the text
                    color: className === 'selected' ? 'blue' : 'white',
                  }}
                  onClick={() => setIsModalOpen(true)}
                />
              </Tooltip>

            </div>
          }
          secondary={
            <>
              <Modal
                open={open && selectedUserForModal === user} // Use the 'open' state here and check selectedUserForModal
                onClose={(event) => {
                  event.stopPropagation();
                  setOpen(false);
                  setSelectedUserForModal(null);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  marginTop: 100,
                  marginBottom: 40,
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  border: '0px'
                }}>
                <Box sx={{
                  position: 'relative',
                  width: 'calc(100vw - 240px)',
                  overflowY: 'auto', // Allow content inside Box to scroll
                  margin: '0 auto',
                  backgroundColor: '#011D23',
                  borderRadius: 25,
                  color: '#fff',
                }}>
                  <Button onClick={(event) => {
                    event.stopPropagation();
                    setOpen(false);
                    setSelectedUserForModal(null);
                  }} style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    color: '#fff',
                    minWidth: '32px',
                    padding: '0 8px'
                  }}>X</Button>

                  <Box sx={{
                    height: 'calc(100vh - 140px)', // Setting fixed height
                    overflowY: 'auto', // Allow content inside this Box to scroll
                  }}>
                    <CapsuleHeader
                      team={team}
                      selectedUser={selectedUserForModal}
                      calculateValue={(times) => { times = times.filter(b => b.sleepTimeReported && b.wakeTimeReported); return Math.max(times.reduce((a, b) => a + b.diff, 0) / times.length, 0); }}
                      colorBands={[
                        { title: "Needs work", color: Colors.ErrorRed, lowScore: 0, highScore: 6 },
                        { title: "Moderate", color: Colors.SoftYellow, lowScore: 6, highScore: 7.5 },
                        { title: "Good", color: Colors.SuccessGreen, lowScore: 7.5, highScore: 9 }
                      ]}
                      memberLabels={memberLabels}
                      teamSleepDurations={teamSleepDurations}
                      currentStartDate={currentStartDate}
                    />

                    <Typography variant="h5" style={{
                      color: '#fff',
                      fontFamily: 'Figtree',
                      fontSize: 25,
                      fontWeight: 900,
                      marginRight: 'auto',
                      marginBottom: 40,
                      marginLeft: 40,
                    }}>
                      {`Sleep times for ${days[0]} - ${days[6]}`}
                    </Typography>

                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',  // Vertically center
                      padding: '0px 10%',
                    }}>
                      <SleepCapsule
                        team={team}
                        selectedUser={selectedUserForModal}
                        privacyLevels={privacyLevels}
                        memberLabels={memberLabels}
                        teamSleepDurations={teamSleepDurations}
                        recentLocations={recentLocations}
                        currentStartDate={currentStartDate}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                      <Box sx={{ minWidth: 100, maxWidth: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 50 }}>
                        <Gauge
                          title="Sleep Duration"
                          user={selectedUserForModal}
                          calculateValue={(times) => { times = times.filter(b => b.sleepTimeReported && b.wakeTimeReported); return Math.max(times.reduce((a, b) => a + b.diff, 0) / times.length, 0); }}
                          warningCondition={(value) => value < 7}
                          colorBands={[
                            { title: "Needs work", color: Colors.ErrorRed, lowScore: 0, highScore: 6 },
                            { title: "Moderate", color: Colors.SoftYellow, lowScore: 6, highScore: 7.5 },
                            { title: "Good", color: Colors.SuccessGreen, lowScore: 7.5, highScore: 9 }
                          ]}
                          valueUnit="hours"
                          warningMessage="Low sleep duration!"
                          teamSleepDurations={teamSleepDurations}
                          teamSleepDurationsVBW={teamSleepDurationsVBW}
                          currentStartDate={currentStartDate}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', minWidth: 100, maxWidth: '30%', justifyContent: 'center', alignItems: 'center', padding: 50 }}>
                        <Gauge
                          title="Sleep Debt"
                          user={selectedUserForModal}
                          calculateValue={(times, hoursForRest) => {
                            times = times.filter(b => b.sleepTimeReported && b.wakeTimeReported);
                            let mappedValues = times.map(time => hoursForRest - time.diff);
                            let value = mappedValues.reduce((a, b) => a + b, 0);
                            return value;
                          }}
                          displayValue={(times) => {
                            let value = times.map(time => 8 - time.diff).reduce((a, b) => a + b, 0);
                            return value > 8 ? '>8' : (value < 0 ? 0 : value);
                          }}
                          warningCondition={(value) => value > 3}
                          colorBands={[
                            { title: "Good", color: Colors.SuccessGreen, lowScore: 0, highScore: 4 },
                            { title: "Moderate", color: Colors.SoftYellow, lowScore: 4, highScore: 6 },
                            { title: "Needs work", color: Colors.ErrorRed, lowScore: 6, highScore: 8 }
                          ]}
                          valueUnit="hours"
                          warningMessage="High sleep debt!"
                          teamSleepDurations={teamSleepDurations}
                          teamSleepDurationsVBW={teamSleepDurationsVBW}
                          currentStartDate={currentStartDate}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', minWidth: 100, maxWidth: '30%', justifyContent: 'center', alignItems: 'center', padding: 50 }}>
                        <Gauge
                          title="Sleep Variation"
                          user={selectedUserForModal}
                          calculateValue={(times) => calculateSleepVariation(times)}
                          warningCondition={(value) => value > 2}
                          colorBands={[
                            { title: "Good", color: Colors.SuccessGreen, lowScore: 0, highScore: 2 },
                            { title: "Moderate", color: Colors.SoftYellow, lowScore: 2, highScore: 3 },
                            { title: "Needs work", color: Colors.ErrorRed, lowScore: 3, highScore: 10 }
                          ]}
                          valueUnit="hours"
                          warningMessage="High sleep variation!"
                          teamSleepDurations={teamSleepDurations}
                          teamSleepDurationsVBW={teamSleepDurationsVBW}
                          currentStartDate={currentStartDate}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                      <Box sx={{ minWidth: 100, maxWidth: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 50 }}>
                        <Gauge
                          title="Bedtime Variation"
                          user={selectedUserForModal}
                          calculateValue={(times, hoursForRest, naturalSleepTime, naturalWakeTime, lastEightSomns) => {
                            lastEightSomns = lastEightSomns.filter(b => b.sleepTimeReported && b.wakeTimeReported);
                            let btVars = [];

                            function timeInMinutesFromMidnight(dateString) {
                              let timePart = dateString.split(" ")[4]; // Extracts "22:46:23"
                              let [hours, minutes] = timePart.split(":").map(Number); // Only consider hours and minutes
                              return hours * 60 + minutes;
                            }

                            for (let i = 1; i < lastEightSomns.length; i++) {
                              let sleepTime1 = timeInMinutesFromMidnight(lastEightSomns[i - 1].sleepTime);
                              let sleepTime2 = timeInMinutesFromMidnight(lastEightSomns[i].sleepTime);

                              let difference;

                              if (sleepTime2 < sleepTime1) {
                                difference = (24 * 60 - sleepTime1) + sleepTime2;
                              } else {
                                difference = sleepTime2 - sleepTime1;
                              }

                              // Ensuring that the difference considers only the day's time and not the transition of dates
                              if (difference > 720) {
                                difference = 1440 - difference;
                              }

                              btVars.push(difference);
                            }


                            if (btVars.length === 0) {
                              return 0;
                            }

                            return btVars.reduce((a, b) => a + b, 0) / (btVars.length + 1);
                          }}
                          warningCondition={(value) => value < 7}
                          colorBands={[
                            { title: "Needs work", color: Colors.ErrorRed, lowScore: 0, highScore: 6 },
                            { title: "Moderate", color: Colors.SoftYellow, lowScore: 6, highScore: 7.5 },
                            { title: "Good", color: Colors.SuccessGreen, lowScore: 7.5, highScore: 9 }
                          ]}
                          valueUnit="hours"
                          warningMessage="Low sleep duration!"
                          teamSleepDurations={teamSleepDurations}
                          teamSleepDurationsVBW={teamSleepDurationsVBW}
                          currentStartDate={currentStartDate}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', minWidth: 100, maxWidth: '30%', justifyContent: 'center', alignItems: 'center', padding: 50 }}>
                        <Gauge
                          title="Wake Time Variation"
                          user={selectedUserForModal}
                          calculateValue={(times, hoursForRest, naturalSleepTime, naturalWakeTime, lastEightSomns) => {
                            lastEightSomns = lastEightSomns.filter(b => b.sleepTimeReported && b.wakeTimeReported);

                            let wtVars = []; // wake time variations

                            function timeInMinutesFromMidnight(dateString) {
                              let timePart = dateString.split(" ")[4];
                              let [hours, minutes] = timePart.split(":").map(Number);
                              return hours * 60 + minutes;
                            }

                            for (let i = 1; i < lastEightSomns.length; i++) {
                              let wakeTime1 = timeInMinutesFromMidnight(lastEightSomns[i - 1].wakeTime);
                              let wakeTime2 = timeInMinutesFromMidnight(lastEightSomns[i].wakeTime);

                              // Calculate wake time difference
                              let wakeDifference;
                              if (wakeTime2 < wakeTime1) {
                                wakeDifference = (24 * 60 - wakeTime1) + wakeTime2;
                              } else {
                                wakeDifference = wakeTime2 - wakeTime1;
                              }

                              // Ensure difference considers only the day's time and not the transition of dates
                              if (wakeDifference > 720) {
                                wakeDifference = 1440 - wakeDifference;
                              }

                              wtVars.push(wakeDifference);
                            }


                            if (wtVars.length === 0) {
                              return 0;
                            }

                            return wtVars.reduce((a, b) => a + b, 0) / wtVars.length;

                          }}
                          warningCondition={(value) => value > 2}
                          colorBands={[
                            { title: "Good", color: Colors.SuccessGreen, lowScore: 0, highScore: 2 },
                            { title: "Moderate", color: Colors.SoftYellow, lowScore: 2, highScore: 3 },
                            { title: "Needs work", color: Colors.ErrorRed, lowScore: 3, highScore: 10 }
                          ]}
                          valueUnit="hours"
                          warningMessage="High sleep variation!"
                          teamSleepDurations={teamSleepDurations}
                          teamSleepDurationsVBW={teamSleepDurationsVBW}
                          currentStartDate={currentStartDate}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </>
          }
        />
      </ListItem>
    </>
  );
}

export default UserListItem;
