import React from 'react';
import '../../src/fonts.css';

const ButtonGroup = ({activeButtonIndex, handleButtonClick}) => {
    const activeButtonStyle = {
        textTransform: 'none', 
        fontFamily: 'Figtree',
        fontSize: 19,
        fontWeight: 900,
        backgroundColor: '#2ABAC7',
        color: '#FFFFFF',
      };

      const buttonStyle = {
        textTransform: 'none', 
        fontFamily: 'Figtree',
        fontSize: 19,
        fontWeight: 500,
        backgroundColor: '#28555F',
        color: '#FFFFFF',
      };

  return (
    <div className="btn-group">
       <a href="#!"
        className={`btn btn-primary ${activeButtonIndex === 0 ? 'active' : ''}`}
        onClick={() => handleButtonClick(0)}
        style={{ ...buttonStyle, ...(activeButtonIndex === 0 ? activeButtonStyle : {}) }}
      >
        Team Insights
      </a>
      <a href="#!"
        className={`btn btn-primary ${activeButtonIndex === 1 ? 'active' : ''}`}
        onClick={() => handleButtonClick(1)}
        style={{ ...buttonStyle, ...(activeButtonIndex === 1 ? activeButtonStyle : {}) }}
      >
        Sleep Analysis
      </a>
    </div>
  );
};

export default ButtonGroup;
