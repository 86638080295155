import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { joinURL } from "../helpers/utils";
import { db } from '../utils/Firebase';
import { doc, onSnapshot } from "firebase/firestore";
import styled from '@emotion/styled';


const Button = styled.button`
  background: var(--accent-color);
  border-radius: var(--radius);
  color: blue;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;

  &:hover {
    filter: contrast(115%);
  }

  &:active {
    transform: translateY(0px) scale(0.98);
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.5;
    cursor: none;
  }
`;

function VerifyEmail() {
  const [status, setStatus] = useState('Click the button to verify your email.');
  const location = useLocation();
  
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const userId = params.get('userId');

  const fetchVerifyEmail = async () => {
    setStatus('Verifying...');
    try {
      const response = await fetch(joinURL(process.env.REACT_APP_API_BASE_URL, "verify-email"), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
  
      if (!response.ok) {
        throw new Error(`Verification failed. HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.success) {
        setStatus('Email verified successfully!');
      } else {
        setStatus('Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('An error occurred. Please try again.');
    }
  };
  
  useEffect(() => {
    try {
      const userDocRef = doc(db, "adminUsers", userId);

      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          if (userData.isVerified) {
            setStatus('Email verified successfully!');
          }
        }
      });
      return () => unsubscribe();
    } catch (error) {
      console.error('Error:', error);
      setStatus('An error occurred. Please try again.');
    }
    
  }, [userId]);

  return (
    <>
      <div>
        <h1>Click here to verify</h1>
        <Button onClick={fetchVerifyEmail}>Verify Email</Button>
        {status}
      </div>
    </>
  );
}

export default VerifyEmail;
