import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../components/NavBar';
// import Header from './Header';
import Footer from '../components/Footer';
import SubscriptionComp from '../components/SubscriptionComp';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(8),
  },
}));

const SchoolPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Navbar />

      <SubscriptionComp 
        title="Help your students get better sleep." 
        url="school_subscriptions" 
        subscriptionType="school" 
        overCountText="Over 1000 students?"
      />

      <Footer />
    </div>
  );
};

export default SchoolPage;