import React, { useContext, useEffect, useState, useRef } from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../components/NavBar';
import PaymentComp from '../components/PaymentComp';
// import Header from './Header';
import Footer from '../components/Footer';
import Mixpanel from 'mixpanel-browser';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: '#011D23',
    margin: -20
  },
}));

const PaymentPage = () => {
  const classes = useStyles();
  const hasTracked = useRef(false);

  if (!hasTracked.current) {
    Mixpanel.track_pageview({"page": "Payment"});
    hasTracked.current = true;
  }

  return (
    <div className={classes.content}>
      <Navbar />

      <PaymentComp />

      <Footer />
    </div>
  );
};

export default PaymentPage;
