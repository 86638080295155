// SleepCard.js

import { React, useState, useEffect } from 'react';
import { getLastSevenSomns, getSleepDiffs, Colors, getLastSevenTimeZones, getLocationAndStamps } from '../../helpers/utils';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import '../../../src/fonts.css';
import { Container } from '@mui/material';
import styled from '@emotion/styled';
import { makeStyles } from '@material-ui/core/styles';

const InlineDiv = styled.div`
  display: inline-block;
  margin-right: -4px;
  white-space: nowrap;
`;

const useStyles = makeStyles({
  leaderboardBox: {
    width: '100%', margin: '10px 0px', display: 'inline-block' 
  }
})

function SleepCard({ title, team, setTeam, calculateValue, warningCondition, sortOrder, warningMessage, memberLabels, teamSleepDurations }) {
  const [isLoading, setIsLoading] = useState(true);
  let [teamData, setTeamData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    
    const calculateDatasets = async () => {
      setIsLoading(true);
      if (!team || team.length === 0) {
        setTeamData([]);
        setIsLoading(false);
        return;
      }

      const teamData = await Promise.all(team.map(async (user) => {
        if (!team) {
          return;
        }
        const hoursForRest = user.hoursForRest;
        const lastSevenSleepTimes = teamSleepDurations[user.uid];
        const value = calculateValue(lastSevenSleepTimes, hoursForRest.split(' ')[0]);
        return {
          firstName: memberLabels.get(user.uid),
          value,
          hoursForRest
        };
      }));

      const sortedResult = teamData.sort(sortOrder === 'asc' ? (a, b) => a.value - b.value : (a, b) => b.value - a.value);
      
      if (!team && teamData) {
        setTeamData([]);
        teamData = [];
      }

      setTeamData(sortedResult);
      setIsLoading(false); // stop loading when data is ready
    };
    
    calculateDatasets();
    
  }, [team, memberLabels, calculateValue, sortOrder]);
  
  return (
    <Container style={{width: '40%'}}>
    <Box className={classes.leaderboardBox}>
      <Card style={{ backgroundColor: '#28555F', borderRadius: 30 }}>
        <CardContent>
        <Typography 
          sx={{ 
            fontFamily: 'Figtree', 
            fontWeight: 900, 
            fontSize: 20, 
            marginLeft: 3, 
            color: '#fff' // Set the color here
          }} 
          //gutterBottom
        >
          {title}
        </Typography>

          {isLoading ? 
            <CircularProgress /> // render spinner while loading
             :
            ((teamData.length === 0 || team.length === 0) ? 
              <Typography variant="body2">
                No viewable team members! Check privacy settings.
              </Typography>
              :
              teamData.map((member, index) => (
                <Box 
                  key={member.firstName}
                  sx={{ 
                    backgroundColor: '#32636F',
                    color: '#fff',
                    padding: 1,
                    paddingLeft: 3,
                    margin: 1,
                    borderRadius: 5,
                    display: 'flex', 
                    justifyContent: 'space-between',
                    gap: '30px',
                    alignItems: 'center',
                    fontFamily: 'Figtree',
                    fontWeight: 700,
                  }}
                >
                  <InlineDiv>{member.firstName.split(' ')[0] + ' ' + member.firstName.split(' ')[1].substring(0, 1) + '.'}</InlineDiv>
                  <Box 
                    sx={{
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '65px',
                      fontFamily: 'Figtree'
                    }}
                  >
                    <div>{Math.round(member.value * Math.pow(10, 1)) / Math.pow(10, 1)}</div>
                    <Box sx={{ color: 'orange', marginTop: '-3px', width: '24px', visibility: warningCondition(member.value) ? 'visible' : 'hidden' }}>
                      <Tooltip title={warningMessage}> 
                        <WarningIcon style={{ color: Colors.SoftYellow }} />
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              ))
            )
          }
        </CardContent>
      </Card>
    </Box>
    </Container>
  );}


export default SleepCard;