import React, { useState } from 'react';
import axios from 'axios';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@material-ui/core';
import ScrollIcon from '../../assets/scroll.png'; // https://icons8.com/icons/set
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { sleep } from '../../helpers/utils';


const useStyles = makeStyles((theme) => ({
    uploadButtonStyling: {
        width: '20%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    title: {
        flex: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const Workflow = ({ organizationId, handleClick }) => {
    const [notifications, setNotifications] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const classes = useStyles();

    const handleAddNotification = () => {
        const newNotification = {
            variable: 'Sleep Duration',
            threshold: 0,
            titleIfYes: '',
            bodyIfYes: '',
            titleIfNo: '',
            bodyIfNo: '',
            scheduledDate: new Date(),
        };
        setNotifications([...notifications, newNotification]);
    };

    const handleChange = (index, field, value) => {
        const newNotifications = [...notifications];
        newNotifications[index][field] = value;
        setNotifications(newNotifications);
    };

    const handleRemoveNotification = (index) => {
        const newNotifications = [...notifications];
        newNotifications.splice(index, 1);
        setNotifications(newNotifications);
    };

    // Add your handleSave logic here to send the notifications
    const handleSave = async () => {
      // Trigger any UI action that indicates the save process has started
      setIsSaving(true);

      await sleep(1000);

      setIsSaving(false);
      setShowDialog(false);
  
      // Ensure all dates are in the correct format
      const notificationsForSubmission = notifications.map(notification => {
          return {
              ...notification,
              // If the notification eventDate exists and is a Date object, convert it to an ISO string
              eventDate: notification.scheduledDate instanceof Date ? notification.scheduledDate.toISOString() : notification.scheduledDate
          };
      });
  
      try {
          // The URL for the Cloud Function - replace with your actual Cloud Function URL
          const workflowUrl = "https://us-central1-somnapp-5fd69.cloudfunctions.net/handleWorkflow";
          
          // Making the HTTP request to your Cloud Function
          const response = await axios.post(workflowUrl, {
              notificationsArray: notificationsForSubmission,
              organizationId: organizationId
          });
  
          // Handle the response from the Cloud Function
          console.log('Workflow handled successfully:', response.data);
      } catch (error) {
          // Handle any errors that occur during the HTTP request
          console.error('Error handling workflow:', error);
      }
  };
  


    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
            <Tooltip title="Plan a Workflow">
                <img
                    className={classes.uploadButtonStyling}
                    src={ScrollIcon}
                    onClick={() => setShowDialog(true)}
                    alt="Workflow"
                />
            </Tooltip>

            <Dialog open={showDialog} maxWidth="lg" fullWidth={true}>
                  <div className={classes.titleContainer}>
                    <DialogTitle className={classes.title}>
                        Plan a Workflow
                    </DialogTitle>
                    <IconButton onClick={() => setShowDialog(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Variable</TableCell>
                                <TableCell>Threshold</TableCell>
                                <TableCell>Title if Yes</TableCell>
                                <TableCell>Body if Yes</TableCell>
                                <TableCell>Title if No</TableCell>
                                <TableCell>Body if No</TableCell>
                                <TableCell>Scheduled Date</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifications.map((notification, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel>Variable</InputLabel>
                                            <Select
                                                value={notification.variable}
                                                onChange={(e) => handleChange(index, 'variable', e.target.value)}
                                            >
                                                <MenuItem value="Sleep Duration">Sleep Duration</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={notification.threshold}
                                            onChange={(e) => handleChange(index, 'threshold', Number(e.target.value))}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={notification.titleIfYes}
                                            onChange={(e) => handleChange(index, 'titleIfYes', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={notification.bodyIfYes}
                                            onChange={(e) => handleChange(index, 'bodyIfYes', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={notification.titleIfNo}
                                            onChange={(e) => handleChange(index, 'titleIfNo', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            value={notification.bodyIfNo}
                                            onChange={(e) => handleChange(index, 'bodyIfNo', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                            <DateTimePicker
                                                label="Event Date"
                                                value={notification.eventDate}
                                                onChange={(date) => handleChange(index, 'scheduledDate', date)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {/* <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => {
                                                    const twoMinutesFromNow = new Date(Date.now() + 2 * 60 * 1000);
                                                    handleChange(index, 'scheduledDate', twoMinutesFromNow);
                                                }}
                                                style={{ marginLeft: '10px' }}
                                            >
                                                Schedule Now
                                            </Button> */}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleRemoveNotification(index)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button
                        startIcon={<AddCircleIcon />}
                        onClick={handleAddNotification}
                    >
                        Add Notification
                    </Button>
                </DialogContent>
                <DialogActions>
                  {isSaving ? (
                        <CircularProgress size={24} />
                    ) : (
                      <Button onClick={handleSave} color="primary">
                          Send to team
                      </Button>
                  )}
                </DialogActions>
            </Dialog>
        </div>
      </LocalizationProvider>
    );
};

export default Workflow;
