import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { BrowserRouter as Route, Link } from "react-router-dom";
import { app, db } from '../utils/Firebase';
import { Container, Typography, Button, Card, CardContent, CardMedia, TextField } from '@material-ui/core';
import { useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc, query, where, getDocs, updateDoc, documentId } from "firebase/firestore";
import {
  isAdminChecker, calculateSleepVariation, clip, Colors,
  PRIVACY_LEVELS, getRandomUser, privacyNamesToInts, getMemberSleepDurations,
  PRIVACY_LEVELS_STRINGS, getLastSevenSomns, getSleepDiffs, withTimeout, asyncThrottle, fetchUserDataAndSleep, logEvent
} from '../helpers/utils';
import LoadingWithValueLabel from './LoadingDeterminant';
import SleepChart from '../components/Dashboard/SleepChart';
import SleepCard from '../components/Dashboard/SleepCard';
import SleepDoughnut from '../components/Dashboard/SleepDoughnut';
import SleepCapsule from '../components/Dashboard/SleepCapsule';
import { makeStyles } from '@material-ui/core/styles';
import '../../src/fonts.css';
import ButtonGroup from '../components/ButtonGroup.js';
import DateSpread from '../components/Dashboard/DateSpread';
import BarGraph from '../components/Dashboard/BarGraph';
import ViolinPlot from '../components/Dashboard/ViolinPlot';
import BoxAndWhiskerPlot from '../components/Dashboard/BoxAndWhiskerPlot';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import '../../src/styles/toggle.css'
import { PRIVACY_LEVELS_REVERSED } from "../helpers/utils";
import Loading from '../pages/Loading';
import ConfirmationPage from '../pages/ConfirmationPage';
import { selectUser, selectLoadingUser } from '../slices/UserSlice';
import { selectSubscription, selectLoadingSubscription } from '../slices/SubscriptionSlice';
import { selectUserDetails, selectLoadingUserDetails, fetchUserDetails } from '../slices/UserDetailsSlice';
import UploadButton from '../components/UploadButton';
import styled from '@emotion/styled';
import EditIcon from '@material-ui/icons/Edit';
import TeamLeaderboard from './TeamLeaderboard';
import NameEditor from '../components/NameEditor';
import sleepWellImage from '../../src/assets/sleepwell.png';
import throttle from 'lodash.throttle';

// Sub components
import TeamInsights from '../components/Dashboard/TeamInsights';
import { useSelector, useDispatch } from 'react-redux';
import ErrorPage from './ErrorPage';
import MiscInfo from '../components/Dashboard/MiscInfo';
import ActionCenter from '../components/Dashboard/ActionCenter';
import { VoicemailOutlined } from '@material-ui/icons';
import { current } from '@reduxjs/toolkit';
import Mixpanel from 'mixpanel-browser';

// constants
const CHUNK_SIZE = 10;
const FULL_MONTH_SOMNS = 28;
const PREVIOUS_DAYS_CUTOFF = 7;

const Input = styled.input`
  display: block;
  font-size: 0.9em;
  width: 20%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #1C434B;
  background-color: #1C434B;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 900, // custom value
      md: 960, // custom value
      lg: 1440, // custom value
      xl: 1920
    },
  },
});


const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: '#011D23',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  teamPage: {
    background: '#011D23',
    marginBottom: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    margin: "0px",
    width: '100%'
  },

  title: {
    fontSize: 70,
    textAlign: 'left',
    fontWeight: 900,
    fontFamily: 'Figtree',
    color: '#fff',
    marginTop: theme.spacing(0),
    marginLeft: 30
  },

  name: {
    fontSize: 30,
    textAlign: 'left',
    fontWeight: 900,
    fontFamily: 'Figtree',
    color: '#fff',
    marginTop: theme.spacing(0),
    marginLeft: 30
  },

  headings: {
    fontSize: 50,
    textAlign: 'center',
    fontWeight: 900,
    fontFamily: 'Figtree',
    color: '#fff',
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(5),
  },

  heading: {
    fontSize: 40,
    textAlign: 'center',
    fontWeight: 500,
    fontFamily: 'Figtree',
    color: 'white',
    marginTop: theme.spacing(7),
    // backgroundColor: '#FA5F5A', 
    padding: '10px',

  },

  buttonGroup: {
    display: 'flex',
    alignItems: 'center'
  },

  cards: {
    backgroundColor: '#0F3038',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    maxHeight: 500,
    marginTop: theme.spacing(5),
    borderRadius: 20,
  },

  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    overflowY: 'auto'
  },

  container: {
    width: '70%'
  },

  mainCard: {
    backgroundColor: '#0F3038',
    color: '#fff',
    display: 'flex',
    width: '100%',
    //height: '100%',
    marginTop: theme.spacing(5),
    padding: 10,
    borderRadius: 15,
    marginLeft: 40
  },

  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    //justifyContent: 'center',
    alignItems: 'center',
    padding: 10, /* Add padding for spacing */
  },

  toggleButton: {
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    border: 'none',
    backgroundColor: '#95959B',
    cursor: 'pointer',
    textTransform: 'none',
    transition: 'background-color 0.3s ease',
    '&.active': {
      backgroundColor: '#00AF7B',
    },
  },

  button: {
    textTransform: 'none',
    backgroundColor: '#DEE8A1',
    color: '#011D23',
    fontFamily: 'Figtree',
    fontSize: 18,
    fontWeight: 600,
    marginTop: 20,
    padding: '12px 24px',
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, transform 0.2s, box-shadow 0.3s',
    '&:hover': {
      backgroundColor: '#524CD8',
      color: '#fff',
      transform: 'scale(1.05)',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
  },

  graphTitle: {
    color: '#fff',
    fontFamily: 'Figtree',
    fontSize: 22,
    fontWeight: 900,
    marginRight: 'auto',
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 15,
      marginLeft: -100,
      fontWeight: 700,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',  // Setting minFontSize for smaller screens
    },
    messageContainer: {
      display: 'flex',
      justifyContent: 'center', // Horizontally centers child elements
      alignItems: 'center',     // Vertically centers child elements
      height: '100%',           // Assumes it takes the full height of its parent, adjust as needed
    },

    message: {
      color: Colors.Light,  // Softer color than white
      fontFamily: 'Figtree',
      fontSize: 18,
      fontWeight: 500,   // Lighter than 900
      marginRight: 'auto',
      marginBottom: 20,
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
        marginLeft: -80, // Slightly less offset
        fontWeight: 400, // Even lighter for medium screens
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '7px', // Smaller font for small screens but not too small
      },
      transition: 'all 0.3s ease', // Soft transition for any changes
    },
    sleepWellImage: {
      width: '65%',
      height: 'auto',
      marginRight: 80,
      marginTop: 60,
      [theme.breakpoints.down('md')]: {
        width: '60%',
        margin: 'auto',
        height: 'auto',
      },

      [theme.breakpoints.down('sm')]: {
        width: '90%',
        height: 'auto',
        margin: 'auto',
      },
    },
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'center', // Horizontally centers child elements
    alignItems: 'center',     // Vertically centers child elements
    height: '100%',           // Assumes it takes the full height of its parent, adjust as needed
  },

  message: {
    color: Colors.Light,  // Softer color than white
    fontFamily: 'Figtree',
    fontSize: 18,
    fontWeight: 500,   // Lighter than 900
    marginRight: 'auto',
    marginBottom: 20,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      marginLeft: -80, // Slightly less offset
      fontWeight: 400, // Even lighter for medium screens
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '7px', // Smaller font for small screens but not too small
    },
    transition: 'all 0.3s ease', // Soft transition for any changes
  },
  toggleTitle: {
    marginRight: 10,
    marginLeft: 'auto',
    color: "#0E3038",
    fontFamily: 'Figtree',
    fontSize: 17,
    fontWeight: 900,
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',  // Setting minFontSize for smaller screens
    },
  },

  leaderboard: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    width: '100%'
  },

  gap: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 400,
    [theme.breakpoints.down('md')]: {
      gap: 270,
    },
    [theme.breakpoints.down('sm')]: {
      gap: 150,
    }
  },

  graphs: {
    width: 900,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      width: 800,
    },
    [theme.breakpoints.down('sm')]: {
      width: 500,
    }
  },

  compressedGraphs: {
    width: 900,
    paddingBottom: 60,
    [theme.breakpoints.down('md')]: {
      width: 800,
    },
    [theme.breakpoints.down('sm')]: {
      width: 500,
    }
  },
  sleepWellImage: {
    width: '65%',
    height: 'auto',
    marginRight: 80,
    marginTop: 60,
    [theme.breakpoints.down('md')]: {
      width: '60%',
      margin: 'auto',
      height: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      margin: 'auto',
    },
  },

  sleepCard: {
    flex: '1 1 calc(30% - 40px)',
    margin: '5px',
    width: '50%',
    [theme.breakpoints.down('md')]: {
      flex: '1 1 calc(50% - 40px)',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%',
    }
  },

  graphSpacing: {
    padding: 30
  }

}))


function TeamPage({ averageSleepDurationByDay }) {
  // get adminuser
  const hasTracked = useRef(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  console.log("user", user);
  const subscription = useSelector(selectSubscription);
  const classes = useStyles();

  const navigate = useNavigate();

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserForModal, setSelectedUserForModal] = useState(null);
  const [isBarGraphVisible, setIsBarGraphVisible] = useState(false);
  const [activeButtonIndex, setActiveButtonIndex] = useState(1);
  const [open, setOpen] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [loadingOrganization, setLoadingOrganization] = useState(true);
  const loadingUser = useSelector(selectLoadingUser);
  const loadingSubscription = useSelector(selectLoadingSubscription);
  const [organizationName, setOrganizationName] = useState(null);
  const [editingName, setEditingName] = useState(false);
  const [teamTimezone, setTeamTimezone] = useState('Default');
  const [privacyLevels, setPrivacyLevels] = useState(new Map());
  const [memberLabels, setMemberLabels] = useState(new Map());
  const userDetails = useSelector(selectUserDetails);
  const loadingUserDetails = useSelector(selectLoadingUserDetails);
  const [team, setTeam] = useState([]); // "identified" privacy settings
  const [anonymousFilteredTeam, setAnonymousFilteredTeam] = useState([]);
  const [aggregatedFilteredTeam, setAggregatedFilteredTeam] = useState([]);
  const [privacySetting, setPrivacySetting] = useState(null);
  const [selectedUserPrivacyLevel, setSelectedUserPrivacyLevel] = useState(null);
  const [teamMemberSleepDiffs, setTeamMemberSleepDiffs] = useState(new Map());
  const initialState = {
    members: [],
    progress: 10,
  };
  const [progressState, progressDispatch] = useReducer(reducer, initialState);
  const initialDate = new Date();
  initialDate.setDate(initialDate.getDate() - 6);
  const [currentStartDate, setCurrentStartDate] = useState(initialDate);
  const [isTimeToFetchDates, setIsTimeToFetchDates] = useState(false);

  function reducer(state, action) {
    switch (action.type) {
      case 'SET_MEMBERS':
        return { ...state, members: action.payload };
      case 'SET_PROGRESS':
        return { ...state, progress: action.payload };
      default:
        return state;
    }
  }


  const [forceUpdate, setForceUpdate] = useState(false);
  const [isLoadingTeamSleepDurations, setIsLoadingTeamSleepDurations] = useState(false);
  const [recentLocations, setRecentLocations] = useState(new Set());
  const [showError, setShowError] = useState(false);

  // FOR SCALE TESTING (TIMING LOADING SCREEN)
  const [loadingStartTime, setLoadingStartTime] = useState(null);
  const [loadingEndTime, setLoadingEndTime] = useState(null);

  const [allMembersPrivacyNone, setAllMembersPrivacyNone] = useState(false);

  const displaySleepWellImage = (privacySetting) => {
    return allMembersPrivacyNone || PRIVACY_LEVELS_REVERSED.get(privacySetting) === PRIVACY_LEVELS_STRINGS.NONE;
  };

  useEffect(() => {
    if (!forceUpdate) {
      setForceUpdate(true);
    }

    if (!hasTracked.current) {
      Mixpanel.track_pageview({"page": "Dashboard"});
      hasTracked.current = true;
    }
  }, []);

  // 1. Fetching User Details and Privacy Settings
  useEffect(() => {
    if (user) {
      dispatch(fetchUserDetails(user));
    }
  }, [user]);

  useEffect(() => {
    if (userDetails) {
      const userViewDb = userDetails.viewingPreferences;
      setPrivacySetting(privacyNamesToInts.get(userViewDb));
    }
  }, [userDetails]);


  // 2. Fetching Organization and Team Members
  useEffect(() => {
    if (!user || privacySetting === null || privacySetting === undefined) return;

    const getUserAndTeamMembers = async () => {
      setIsLoadingTeamSleepDurations(true);

      try {
        setLoadingOrganization(true);
        const organizationsRef = collection(db, 'organizations');

        // Use array-contains to check if user's uid exists in the adminUserId array
        const q = query(organizationsRef, where('adminUserIds', 'array-contains', user.uid));
        
        const querySnapshot = await getDocs(q);
        const organization = querySnapshot.docs.map(doc => doc.data())[0]; // This assumes there's only one organization per admin user
        
        setOrganization(organization);
        setTeamTimezone(organization.timeZone);

        // Get team members
        let members = [];
        const totalChunks = Math.ceil(organization.members.length / CHUNK_SIZE) - 1;
        const progressIncrement = 100 / totalChunks;
        let completedChunks = 0;



        // apply chunking
        for (let i = 0; i < organization.members.length; i += CHUNK_SIZE) {
          const chunk = organization.members.slice(i, i + CHUNK_SIZE);
          const chunkPromises = chunk.map(memberUid => fetchUserDataAndSleep(memberUid, FULL_MONTH_SOMNS, teamTimezone));
          const chunkData = await Promise.allSettled(chunkPromises);

          // Filter out rejected promises, if any, and extract the data from the fulfilled ones.
          const validChunkData = chunkData
            .filter(result => result.status === 'fulfilled')
            .map(result => result.value);

          members = [...members, ...validChunkData];

          // Update the progress
          progressDispatch({ type: 'SET_MEMBERS', payload: [...progressState.members, ...validChunkData] });

          // Update the progress
          completedChunks++;
          progressDispatch({ type: 'SET_PROGRESS', payload: completedChunks * progressIncrement });
        }
        const newPrivacyLevels = new Map(privacyLevels);
        const newMemberLabels = new Map(memberLabels);

        function processMembers(membersArray) {

          // Filter out members with empty sleepDurations or with isError flag
          const validMembers = membersArray.filter(member => {
            return Array.isArray(member.sleepDurations) && member.sleepDurations.length > 0 && !member.isError;
          });

          const processedMembers = validMembers.map(member => {
            const recentLocations = new Set();
            let updatedLocations;
            let privacyLevel;
            let label;

            let memberSleepDurations = member.sleepDurations || [];
            let userRecentLocations = member.recentLocations || [];

            recentLocations.add(...userRecentLocations.map(loc => loc.userLoc));
            updatedLocations = new Set([...recentLocations, ...userRecentLocations.map(loc => loc.userLoc)]);

            let memberPrivacy = member.organizationAccessLevel
              ? member.organizationAccessLevel.toLowerCase()
              : PRIVACY_LEVELS_STRINGS.IDENTIFIED.toLowerCase();

            if (memberPrivacy.toLowerCase() === "anonymized") {
              memberPrivacy = PRIVACY_LEVELS_STRINGS.ANONYMOUS.toLowerCase();
            }

            memberPrivacy = memberPrivacy[0].toUpperCase() + memberPrivacy.slice(1).toLowerCase();
            const currentMemberPrivacy = privacyNamesToInts.get(memberPrivacy);
            privacyLevel = Math.min(currentMemberPrivacy, privacySetting);

            label = (privacyLevel != PRIVACY_LEVELS.IDENTIFIED)
              ? `User ${getRandomUser()}`
              : `${member.firstName} ${member.lastName}`;

            return {
              updatedLocations,
              sleepDurations: memberSleepDurations,
              privacyLevel,
              label,
              uid: member.uid,
            };
          });

          processMembersData(processedMembers);
        }


        function processMembersData(membersData) {
          membersData.forEach(member => {
            if (member.isError) {
              console.error(`Error processing member UID: ${member.uid}`);
              console.error(member.errorMessage);
            } else {
              setRecentLocations(member.updatedLocations);
              setTeamMemberSleepDiffs(prevState => ({
                ...prevState,
                [member.uid]: member.sleepDurations
              }));
              newPrivacyLevels.set(member.uid, member.privacyLevel);
              newMemberLabels.set(member.uid, member.label);
            }
          });
          // Any other UI updates can be added here
        }

        // Assuming members is your populated array of members.
        processMembers(members);



        let allMembersPrivacyNoneLocal = members.every(member => {
          let memberPrivacy = member.organizationAccessLevel
            ? member.organizationAccessLevel.toLowerCase()
            : PRIVACY_LEVELS_STRINGS.IDENTIFIED.toLowerCase();

          memberPrivacy = memberPrivacy[0].toUpperCase() + memberPrivacy.slice(1).toLowerCase();
          return memberPrivacy === PRIVACY_LEVELS_STRINGS.NONE;
        });
        setAllMembersPrivacyNone(allMembersPrivacyNoneLocal);
        setPrivacyLevels(newPrivacyLevels);
        setMemberLabels(newMemberLabels);
        setTeam(members);
        setLoadingOrganization(false);
        if (![PRIVACY_LEVELS_STRINGS.NONE].includes(PRIVACY_LEVELS_REVERSED.get(privacySetting))) {
          setIsBarGraphVisible(true);
          setActiveButtonIndex(1);
        } else {
          setIsBarGraphVisible(false);
        }
      } catch (error) {
        console.error("An error occurred while fetching organization and team members:", error);
        setLoadingOrganization(false); // You might want to handle this error differently
      }
      setIsLoadingTeamSleepDurations(false);
    };

    getUserAndTeamMembers();
  }, [user, privacySetting]);

  // 3. Setting Up Filtered Team Lists
  useEffect(() => {
    if (!team || !privacyLevels) return;
    setAnonymousFilteredTeam(team.filter(member => {
      const privacyLevel = privacyLevels.get(member.uid);
      return privacyLevel >= PRIVACY_LEVELS.ANONYMOUS;
    }));
    setAggregatedFilteredTeam(team.filter(member => {
      const privacyLevel = privacyLevels.get(member.uid);
      return privacyLevel >= PRIVACY_LEVELS.AGGREGATED;
    }));

  }, [team, privacyLevels]);

  useEffect(() => {
    const anyLoading = loadingOrganization || loadingUser || loadingUserDetails || isLoadingTeamSleepDurations || loadingSubscription;

    // Only set start time if not already set
    if (anyLoading && !loadingStartTime) {
      setLoadingStartTime(Date.now());
    }

    // When everything is done loading and start time is set, and end time is not set
    if (!anyLoading && loadingStartTime && !loadingEndTime) {
      setLoadingEndTime(Date.now());
    }
    if (loadingStartTime && loadingEndTime) {
      const duration = loadingEndTime - loadingStartTime;
      // Mixpanel.track("Loaded in getLastNTimeZones", {
      //   "userId": user.uid,
      //   "duration": duration
      // });
      // Reset the start and end times for the next loading operation
      setLoadingStartTime(null);
      setLoadingEndTime(null);
    }
  }, [loadingOrganization, loadingUser, loadingUserDetails, isLoadingTeamSleepDurations, loadingSubscription, loadingStartTime, loadingEndTime]);




  const isDesktop = useMediaQuery('(min-width: 1200px)');

  const toggleGraph = () => {
    Mixpanel.track('Toggled graph');
    setIsBarGraphVisible((prev) => !prev);
    logEvent(user, "toggle-graph", !isBarGraphVisible ? "Bar graph toggled" : "Individual sleep durations toggled");
  };



  const handleSelectUser = (user) => { // Renamed function to handleSelectUser
    Mixpanel.track('Selected user', { 'selectedUser': user });
    setSelectedUserForModal(user);
  };

  const handleClickUser = (user) => {
    Mixpanel.track('Clicked on user', { 'clickedOnUser': user });

    if (user === null || (selectedUserForModal && user.uid === selectedUserForModal.uid)) {
      handleSelectUser(null);
      setOpen(false); // Explicitly set to false
    } else {
      handleSelectUser(user);
      setOpen(true);
    }
  };

  const updateName = async () => {
    if (organizationName !== null && organizationName !== undefined) {
      Mixpanel.track('Updated name', { 'newOrganizationName': organizationName });
      try {
        // Update the organization in the database here
        const organizationRef = doc(db, 'organizations', organization.organizationId);
        await updateDoc(organizationRef, { name: organizationName });

        // Update the local state
        setOrganization(prevState => ({
          ...prevState,
          name: organizationName,
        }));
      } catch (error) {
        console.error("Error updating the organization name:", error);
        // Optionally, inform the user that there was an issue updating the name.
      }
    }
  };


  const updateAndSwitchToReadMode = () => {
    if (!organizationName.trim()) {
      setShowError(true);
      return;
    }
    setShowError(false);
    updateName();
    setTimeout(() => {
      setEditingName(false);
    }, 1000);
  };

  if (loadingUser || loadingSubscription || loadingOrganization || loadingUserDetails || isLoadingTeamSleepDurations) {
    if (progressState.progress > 10) {
      return <LoadingWithValueLabel progress={progressState.progress} />
    }
    return <Loading />;
  }



  if (!isDesktop) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h4">Please open this page on a full screen for the best experience.</Typography>
      </div>
    );
  }

  if (!team || team.length === 0) {
    if (organization) {
      return <ConfirmationPage
        uniqueCode={organization.uniqueCode}
        organization={organization}
        editingName={editingName}
        setEditingName={setEditingName}
        updateAndSwitchToReadMode={updateAndSwitchToReadMode}
        organizationName={organizationName}
        setOrganizationName={setOrganizationName}
        showError={showError}
        setShowError={setShowError}
      />;
    } else if (!organization && !loadingOrganization) {
      return <ErrorPage />;
    }
  }

  // updates the organization doc with the new timezone
  const updateTeamTimezone = async (newTimezone) => {
    // Update the organization in the database here
    const organizationRef = doc(db, 'organizations', organization.organizationId);
    await updateDoc(organizationRef, { timeZone: newTimezone });

    // Update the local state
    setTeamTimezone(newTimezone);
  };

  const updateViewingPreference = async (newPrivacySetting) => {
    // Convert the integer value to its corresponding string representation
    const privacyString = PRIVACY_LEVELS_REVERSED.get(newPrivacySetting);

    if (typeof privacyString === 'undefined') {
      console.error("privacyString is undefined. Not updating Firestore.");
      return;
    }

    // Update the organization in the database here
    const adminUserRef = doc(db, 'adminUsers', user.uid);
    await updateDoc(adminUserRef, { viewingPreferences: privacyString });

    // Update the local state
    setPrivacySetting(newPrivacySetting);

    if (selectedUser && selectedUser.uid) {
      const newPrivacy = PRIVACY_LEVELS_REVERSED.get(Math.min(privacyLevels.get(selectedUser.uid), newPrivacySetting));
      setSelectedUserPrivacyLevel(newPrivacy);
    }
  };

  return (
    <>
      <div className={classes.pageContainer}>
        <Navbar />
        <div className={classes.teamPage}>

          <div style={{ width: "100%" }}>
            {!subscription ? (
              <div className="w-100 text-center">
                <Typography className={classes.headings}>My Subscriptions</Typography>
                <svg width="100%" height="2" style={{ margin: 'auto', marginTop: 0 }}>
                  <line x1="0" y1="0" x2="100%" y2="0" stroke="#D8D8D8" strokeWidth="2" />
                </svg>
                <Typography className={classes.heading}>You haven't purchased a subscription yet! <p><Button className={classes.button} onClick={() => { navigate('/pricing') }}>Click here to view pricing</Button></p></Typography>
              </div>
            ) : (

              <div>
                {!team || team.length === 0 ? (
                  <div className={classes.noTeamContainer}>
                    <h2>Welcome to the Team Page!</h2>
                    {user && <p>You don't seem to have any team members yet. Share your unique code: <strong>{user.uniqueCode}</strong> with your team members to get started!</p>}
                  </div>
                ) : (
                  <></>
                )}


                <Typography className={classes.title}>At a glance</Typography>
                <Typography
                  className={classes.name}

                >
                  <NameEditor
                    organization={organization}
                    updateAndSwitchToReadMode={updateAndSwitchToReadMode}
                    editingName={editingName}
                    setEditingName={setEditingName}
                    organizationName={organizationName}
                    setOrganizationName={setOrganizationName}
                    showError={showError}
                    setShowError={setShowError}
                  />

                  {/* {editingName ? (
                <InputContainer>
                  <Input
                    type="text"
                    value={organizationName !== null && organizationName !== undefined ? organizationName : organization.name}
                    onChange={e => setOrganizationName(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        updateAndSwitchToReadMode();
                      }
                    }}
                  />
                  <UploadButton onClick={updateAndSwitchToReadMode} />
                </InputContainer>
              ) : (
                <>
                  {organization.name}
                  <EditIcon
                    style={{ marginLeft: '1rem', cursor: 'pointer' }}
                    onClick={() => setEditingName(true)}
                  />
                </>
              )} */}
                </Typography>


                <div style={{ display: "flex", justifyContent: "justify-between" }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20 }}>
                    <nav>
                      {allMembersPrivacyNone || ![PRIVACY_LEVELS_STRINGS.AGGREGATED, PRIVACY_LEVELS_STRINGS.NONE].includes(PRIVACY_LEVELS_REVERSED.get(privacySetting)) &&
                        (
                          <div style={{ backgroundColor: '#0F3038', marginTop: 0, marginLeft: 0, marginRight: 50, borderRadius: 25 }}>
                            <Card className={classes.cards}>
                              <CardContent className={classes.content}>
                                <TeamInsights
                                  user={user}
                                  team={anonymousFilteredTeam}
                                  handleClickUser={handleClickUser}
                                  selectedUser={selectedUser}
                                  setSelectedUser={setSelectedUser}
                                  selectedUserForModal={selectedUserForModal} // Pass this
                                  setSelectedUserForModal={setSelectedUserForModal} // Pass this
                                  open={open}
                                  setOpen={setOpen}
                                  privacyLevels={privacyLevels}
                                  memberLabels={memberLabels}
                                  teamSleepDurations={teamMemberSleepDiffs}
                                  teamSleepDurationsVBW={teamMemberSleepDiffs}
                                  recentLocations={recentLocations}
                                  currentStartDate={currentStartDate}
                                  organization={organization}
                                />
                              </CardContent>
                            </Card>
                          </div>
                        )}
                    </nav>

                    <nav>
                      <div style={{ backgroundColor: '#0F3038', marginTop: 0, marginLeft: 0, marginRight: 50, borderRadius: 25 }}>
                        <Card className={classes.cards}>
                          <CardContent className={classes.content}>
                            <MiscInfo
                              uniqueCode={organization.uniqueCode}
                              teamTimezone={teamTimezone}
                              updateTeamTimezone={updateTeamTimezone}
                              organization={organization}
                              userViewingPreference={privacySetting}
                              updateViewingPreference={updateViewingPreference}
                            />
                          </CardContent>
                        </Card>
                      </div>
                    </nav>

                    <nav>
                      <div style={{ backgroundColor: '#0F3038', marginTop: 0, marginLeft: 0, marginRight: 50, borderRadius: 25 }}>
                        <Card className={classes.cards}>
                          <CardContent className={classes.content}>
                            <ActionCenter
                              user={user}
                              uniqueCode={organization.uniqueCode}
                              teamTimezone={teamTimezone}
                              updateTeamTimezone={updateTeamTimezone}
                              organization={organization}
                              userViewingPreference={privacySetting}
                              updateViewingPreference={updateViewingPreference}
                            />
                          </CardContent>
                        </Card>
                      </div>
                    </nav>
                  </div>



                  <div className={classes.container}>
                    <Card className={classes.mainCard}>
                      <CardContent className={classes.mainContent}>
                        <div style={{ marginTop: 10, marginBottom: 30 }}>
                          {![PRIVACY_LEVELS_STRINGS.AGGREGATED, PRIVACY_LEVELS_STRINGS.NONE].includes(PRIVACY_LEVELS_REVERSED.get(privacySetting) &&
                            <ButtonGroup
                              activeButtonIndex={activeButtonIndex}
                              handleButtonClick={(index) => {
                                setActiveButtonIndex(index);
                                setIsBarGraphVisible(false);
                              }}
                            />
                          )}
                        </div>

                        {activeButtonIndex === 0 && ![PRIVACY_LEVELS_STRINGS.AGGREGATED, PRIVACY_LEVELS_STRINGS.NONE].includes(PRIVACY_LEVELS_REVERSED.get(privacySetting)) &&
                          (
                            <>
                              <TeamInsights
                                team={anonymousFilteredTeam}
                                handleClickUser={handleClickUser}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                selectedUserForModal={selectedUserForModal} // Pass this
                                setSelectedUserForModal={setSelectedUserForModal} // Pass this
                                open={open}
                                setOpen={setOpen}
                                privacyLevels={privacyLevels}
                                memberLabels={memberLabels}
                                teamSleepDurations={teamMemberSleepDiffs}
                                recentLocations={recentLocations}
                                organization={organization}
                              />
                              {![PRIVACY_LEVELS_STRINGS.AGGREGATED, PRIVACY_LEVELS_STRINGS.NONE].includes(PRIVACY_LEVELS_REVERSED.get(privacySetting)) &&
                                (
                                  <TeamLeaderboard
                                    classes={classes}
                                    selectedUser={selectedUser}
                                    selectedUserPrivacyLevel={selectedUserPrivacyLevel}
                                    PRIVACY_LEVELS_STRINGS={PRIVACY_LEVELS_STRINGS}
                                    PRIVACY_LEVELS_REVERSED={PRIVACY_LEVELS_REVERSED}
                                    privacyLevels={privacyLevels}
                                    calculateSleepVariation={calculateSleepVariation}
                                    anonymousFilteredTeam={anonymousFilteredTeam}
                                    setAnonymousFilteredTeam={setAnonymousFilteredTeam}
                                    memberLabels={memberLabels}
                                    teamSleepDurations={teamMemberSleepDiffs}
                                  />
                                )}
                            </>
                          )}

                        {
                          displaySleepWellImage(privacySetting) && (
                            <>
                              <div className={classes.messageContainer}>
                                <Typography variant="h5" className={classes.message}>
                                  Privacy setting is set to None or all team members have chosen to share no data. Please contact Somn.
                                </Typography>
                              </div>
                              <img className={classes.sleepWellImage} src={sleepWellImage} />
                            </>
                          )
                        }

                        {activeButtonIndex === 1 && !displaySleepWellImage(privacySetting) && (
                          <>
                            <div className={classes.gap}>
                              <Typography variant="h5" className={classes.graphTitle}>
                                {isBarGraphVisible ? 'Aggregated Sleep Durations' : 'Individual Sleep Durations'}
                              </Typography>
                              {![PRIVACY_LEVELS_STRINGS.AGGREGATED, PRIVACY_LEVELS_STRINGS.NONE].includes(PRIVACY_LEVELS_REVERSED.get(privacySetting)) && (
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <span className={classes.toggleTitle}>{isBarGraphVisible ? 'Individual' : 'Aggregated'}</span>
                                  <button className={`${classes.toggleButton} slideToggle ${isBarGraphVisible ? 'active' : ''}`} onClick={toggleGraph}>
                                  </button>
                                </div>
                              )}
                            </div>
                              <>
                                <DateSpread 
                                user={user}
                                startDate={currentStartDate} 
                                setStartDate={setCurrentStartDate} 
                                daysBeingFetched={FULL_MONTH_SOMNS}
                                organization={organization}/>
                              </>
                            {isBarGraphVisible ? (
                              <div className={classes.compressedGraphs}>
                                <BarGraph
                                  team={aggregatedFilteredTeam}
                                  averageSleepDurationByDay={averageSleepDurationByDay}
                                  privacyLevels={privacyLevels}
                                  memberLabels={memberLabels}
                                  teamSleepDurations={teamMemberSleepDiffs}
                                  recentLocations={recentLocations}
                                  currentStartDate={currentStartDate}
                                  previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                                />
                              </div>
                            ) : (
                              <div className={classes.graphs}>
                                <SleepChart
                                  user={user}
                                  team={anonymousFilteredTeam}
                                  selectedUser={selectedUser}
                                  setSelectedUser={setSelectedUser}
                                  privacyLevels={privacyLevels}
                                  memberLabels={memberLabels}
                                  teamSleepDurations={teamMemberSleepDiffs}
                                  currentStartDate={currentStartDate}
                                  previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                                />
                              </div>
                            )}
                            <div className={classes.graphSpacing} />
                            <div>
                              <Typography variant="h5" className={classes.graphTitle}>Sleep Duration Spread</Typography>
                            </div>
                            <div className={classes.graphs}>
                              <ViolinPlot
                                title='Sleep Duration Spread'
                                user={user}
                                team={aggregatedFilteredTeam}
                                organization={organization}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                privacyLevels={privacyLevels}
                                memberLabels={memberLabels}
                                teamSleepDurations={teamMemberSleepDiffs}
                                teamSleepDurationsVBW={teamMemberSleepDiffs}
                                calculateValue={(lastSevenSomns) => {
                                  return lastSevenSomns
                                    .filter(b => b.sleepTimeReported && b.wakeTimeReported)
                                    .map(b => b.y); // Change this if "diff" is the desired field
                                }}
                                currentStartDate={currentStartDate}
                                previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                              />
                            </div>
                            <div className={classes.graphSpacing} />
                            <div>
                              <Typography variant="h5" className={classes.graphTitle}>Sleep Debt Distribution</Typography>
                            </div>
                            <div className={classes.compressedGraphs}>
                              <BoxAndWhiskerPlot
                                title='Sleep Debt Distribution'
                                user={user}
                                team={aggregatedFilteredTeam}
                                organization={organization}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                privacyLevels={privacyLevels}
                                memberLabels={memberLabels}
                                teamSleepDurations={teamMemberSleepDiffs}
                                teamSleepDurationsVBW={teamMemberSleepDiffs}
                                calculateValue={(lastSevenSomns, lastEightSomns, hoursForRest, user) => {
                                  lastSevenSomns = lastSevenSomns.filter(b => b.sleepTimeReported && b.wakeTimeReported);
                                  let mappedValues = lastSevenSomns.map(time => hoursForRest - time.diff);
                                  let value = mappedValues.reduce((a, b) => a + b, 0);
                                  return value < 0 ? 0 : value;
                                }}
                                currentStartDate={currentStartDate}
                                previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                              />
                            </div>
                            <div className={classes.graphSpacing} />
                            <div>
                              <Typography variant="h5" className={classes.graphTitle}>Bedtime Variation Spread</Typography>
                            </div>
                            <div className={classes.graphs}>
                              <ViolinPlot
                                title='Bedtime Variation Spread'
                                user={user}
                                team={aggregatedFilteredTeam}
                                organization={organization}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                privacyLevels={privacyLevels}
                                memberLabels={memberLabels}
                                teamSleepDurations={teamMemberSleepDiffs}
                                teamSleepDurationsVBW={teamMemberSleepDiffs}
                                calculateValue={(lastSevenSomns, lastEightSomns) => {
                                  lastEightSomns = lastEightSomns.filter(b => b.sleepTimeReported && b.wakeTimeReported);
                                  let btVars = [];

                                  function timeInMinutesFromMidnight(dateString) {
                                    let timePart = dateString.split(" ")[4]; // Extracts "22:46:23"
                                    let [hours, minutes] = timePart.split(":").map(Number); // Only consider hours and minutes
                                    return hours * 60 + minutes;
                                  }

                                  for (let i = 1; i < lastEightSomns.length; i++) {
                                    let sleepTime1 = timeInMinutesFromMidnight(lastEightSomns[i - 1].sleepTime);
                                    let sleepTime2 = timeInMinutesFromMidnight(lastEightSomns[i].sleepTime);

                                    let difference;

                                    if (sleepTime2 < sleepTime1) {
                                      difference = (24 * 60 - sleepTime1) + sleepTime2;
                                    } else {
                                      difference = sleepTime2 - sleepTime1;
                                    }

                                    // Ensuring that the difference considers only the day's time and not the transition of dates
                                    if (difference > 720) {
                                      difference = 1440 - difference;
                                    }

                                    btVars.push(difference);
                                  }

                                  if (btVars.length === 0) {
                                    return [0];
                                  }

                                  return btVars;
                                }}
                                currentStartDate={currentStartDate}
                                previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                              />
                            </div>
                            <div className={classes.graphSpacing} />
                            <div>
                              <Typography variant="h5" className={classes.graphTitle}>Wake Time Variation Spread</Typography>
                            </div>
                            <div className={classes.graphs}>
                              <ViolinPlot
                                title='Wake Time Variation Spread'
                                user={user}
                                team={aggregatedFilteredTeam}
                                organization={organization}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                privacyLevels={privacyLevels}
                                memberLabels={memberLabels}
                                teamSleepDurations={teamMemberSleepDiffs}
                                teamSleepDurationsVBW={teamMemberSleepDiffs}
                                calculateValue={(lastSevenSomns, lastEightSomns) => {
                                  lastEightSomns = lastEightSomns.filter(b => b.sleepTimeReported && b.wakeTimeReported);

                                  let wtVars = []; // wake time variations

                                  function timeInMinutesFromMidnight(dateString) {
                                    let timePart = dateString.split(" ")[4];
                                    let [hours, minutes] = timePart.split(":").map(Number);
                                    return hours * 60 + minutes;
                                  }

                                  for (let i = 1; i < lastEightSomns.length; i++) {
                                    let wakeTime1 = timeInMinutesFromMidnight(lastEightSomns[i - 1].wakeTime);
                                    let wakeTime2 = timeInMinutesFromMidnight(lastEightSomns[i].wakeTime);

                                    // Calculate wake time difference
                                    let wakeDifference;
                                    if (wakeTime2 < wakeTime1) {
                                      wakeDifference = (24 * 60 - wakeTime1) + wakeTime2;
                                    } else {
                                      wakeDifference = wakeTime2 - wakeTime1;
                                    }

                                    // Ensure difference considers only the day's time and not the transition of dates
                                    if (wakeDifference > 720) {
                                      wakeDifference = 1440 - wakeDifference;
                                    }

                                    wtVars.push(wakeDifference);
                                  }

                                  if (wtVars.length === 0) {
                                    return [0];
                                  }

                                  return wtVars;
                                }}
                                currentStartDate={currentStartDate}
                                previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                              />
                            </div>
                            <div className={classes.graphSpacing} />
                            <div>
                              <Typography variant="h5" className={classes.graphTitle}>Average Bedtime Variation</Typography>
                            </div>
                            <div className={classes.compressedGraphs}>
                              <BoxAndWhiskerPlot
                                title='Average Bedtime Variation'
                                user={user}
                                team={aggregatedFilteredTeam}
                                organization={organization}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                privacyLevels={privacyLevels}
                                memberLabels={memberLabels}
                                teamSleepDurations={teamMemberSleepDiffs}
                                teamSleepDurationsVBW={teamMemberSleepDiffs}
                                calculateValue={(lastSevenSomns, lastEightSomns) => {
                                  lastEightSomns = lastEightSomns.filter(b => b.sleepTimeReported && b.wakeTimeReported);
                                  let btVars = [];

                                  function timeInMinutesFromMidnight(dateString) {
                                    let timePart = dateString.split(" ")[4]; // Extracts "22:46:23"
                                    let [hours, minutes] = timePart.split(":").map(Number); // Only consider hours and minutes
                                    return hours * 60 + minutes;
                                  }

                                  for (let i = 1; i < lastEightSomns.length; i++) {
                                    let sleepTime1 = timeInMinutesFromMidnight(lastEightSomns[i - 1].sleepTime);
                                    let sleepTime2 = timeInMinutesFromMidnight(lastEightSomns[i].sleepTime);

                                    let difference;

                                    if (sleepTime2 < sleepTime1) {
                                      difference = (24 * 60 - sleepTime1) + sleepTime2;
                                    } else {
                                      difference = sleepTime2 - sleepTime1;
                                    }

                                    // Ensuring that the difference considers only the day's time and not the transition of dates
                                    if (difference > 720) {
                                      difference = 1440 - difference;
                                    }

                                    btVars.push(difference);
                                  }

                                  if (btVars.length === 0) {
                                    return 0;
                                  }

                                  return btVars.reduce((a, b) => a + b, 0) / (btVars.length + 1);
                                }}
                                currentStartDate={currentStartDate}
                                previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                              />
                            </div>
                            <div className={classes.graphSpacing} />
                            <div>
                              <Typography variant="h5" className={classes.graphTitle}>Average Wake Time Variation</Typography>
                            </div>
                            <div className={classes.compressedGraphs}>
                              <BoxAndWhiskerPlot
                                title='Average Wake Time Variation'
                                user={user}
                                team={aggregatedFilteredTeam}
                                organization={organization}
                                selectedUser={selectedUser}
                                setSelectedUser={setSelectedUser}
                                privacyLevels={privacyLevels}
                                memberLabels={memberLabels}
                                teamSleepDurations={teamMemberSleepDiffs}
                                teamSleepDurationsVBW={teamMemberSleepDiffs}
                                calculateValue={(lastSevenSomns, lastEightSomns) => {
                                  lastEightSomns = lastEightSomns.filter(b => b.sleepTimeReported && b.wakeTimeReported);

                                  let wtVars = []; // wake time variations

                                  function timeInMinutesFromMidnight(dateString) {
                                    let timePart = dateString.split(" ")[4];
                                    let [hours, minutes] = timePart.split(":").map(Number);
                                    return hours * 60 + minutes;
                                  }

                                  for (let i = 1; i < lastEightSomns.length; i++) {
                                    let wakeTime1 = timeInMinutesFromMidnight(lastEightSomns[i - 1].wakeTime);
                                    let wakeTime2 = timeInMinutesFromMidnight(lastEightSomns[i].wakeTime);

                                    // Calculate wake time difference
                                    let wakeDifference;
                                    if (wakeTime2 < wakeTime1) {
                                      wakeDifference = (24 * 60 - wakeTime1) + wakeTime2;
                                    } else {
                                      wakeDifference = wakeTime2 - wakeTime1;
                                    }

                                    // Ensure difference considers only the day's time and not the transition of dates
                                    if (wakeDifference > 720) {
                                      wakeDifference = 1440 - wakeDifference;
                                    }

                                    wtVars.push(wakeDifference);
                                  }
                                  if (wtVars.length === 0) {
                                    return 0;
                                  }
                                  return wtVars.reduce((a, b) => a + b, 0) / wtVars.length;
                                }}
                                currentStartDate={currentStartDate}
                                previousDaysCutoff={PREVIOUS_DAYS_CUTOFF}
                              />
                            </div>
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}



export default TeamPage;
