import { React, useState, useEffect, useMemo } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import {
  getLastSevenSomns, getRandomColor, getSleepDiffs,
  getLastSevenTimeZones, getLocationAndStamps,
  Colors, getProcessedLastSevenSomns, formatDateLabels,
  PRIVACY_LEVELS, PRIVACY_LEVELS_REVERSED,
  PRIVACY_LEVELS_STRINGS, validateDataAlignment, generateDateLabels, getSevenDaysAgo,
  formatDateToWeekdayMMDDYY, alignDataWithLabels, getProcessedChartData, logEvent
} from '../../helpers/utils';
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import '../../../src/fonts.css';
import { set } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import SearchableDropdown from './SearchableDropdown';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SleepChart = ({ user, team, selectedUser, setSelectedUser, privacyLevels, 
  memberLabels, teamSleepDurations, currentStartDate, previousDaysCutoff }) => {
  const [chartData, setChartData] = useState([]);

  const adminUser = user;
  const userOrder = useMemo(() => team.map(user => user.uid), [team]);
  let labels = generateDateLabels(currentStartDate, previousDaysCutoff);
  const [timeoutReached, setTimeoutReached] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const initializeEmptyData = labels => {
    return labels.map(label => ({
      t: label,
      y: null,
      sleepTime: null,
      wakeTime: null,
      sleepTimeReported: false,
      wakeTimeReported: false,
    }));
  };
  const userLocations = new Set();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeoutReached(true);
    }, 7000); // 5 seconds

    // Clear the timeout if the component is unmounted before the timeout finishes
    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    const calculateDatasets = async () => {
      let allDataSets = [];

      // Helper function to process a single user's data.
      const processDataForUser = (user) => {
        const privacyLevel = privacyLevels.get(user.uid);
        if (privacyLevel === PRIVACY_LEVELS.NONE) {
          return null;
        }
        const color = selectedUser ? (user.uid === selectedUser.uid ? '#2ABAC7' : '#165760') : 'rgb(200,200,200)';
        const sleepDiffs = teamSleepDurations[user.uid];
        const lastSevenSomns = getProcessedChartData(labels, sleepDiffs);
        const initializedData = initializeEmptyData(labels);

        labels.forEach((label, index) => {
          const dataForTheDay = lastSevenSomns.find(somn => somn.t === label);
          if (dataForTheDay) {
            initializedData[index] = dataForTheDay;
          }
        });

        const dataSet = {
          label: memberLabels.get(user.uid),
          data: initializedData.filter(somn => somn.sleepTimeReported && somn.wakeTimeReported),
          fill: false,
          borderColor: color,
          borderWidth: selectedUser && user.uid === selectedUser.uid ? 9 : 3,
          tension: 0.1,
          sleepTime: lastSevenSomns.map(somn => somn.sleepTime),
          wakeTime: lastSevenSomns.map(somn => somn.wakeTime),
          sleepTimeReported: lastSevenSomns.map(somn => somn.sleepTimeReported),
          wakeTimeReported: lastSevenSomns.map(somn => somn.wakeTimeReported),
          pointRadius: lastSevenSomns.map(somn => somn.wakeTimeReported && somn.sleepTimeReported ? 5 : 0),
          pointStyle: lastSevenSomns.map(somn => 'circle'),
          pointBackgroundColor: lastSevenSomns.map(somn => somn.wakeTimeReported && somn.sleepTimeReported ? color : Colors.WarningOrange),
        };

        return dataSet;
      };

      // Process data for the selected user first and prepend to the beginning of allDataSets
      if (selectedUser) {
        const selectedUserDataSet = processDataForUser(selectedUser);
        if (selectedUserDataSet) {
          allDataSets.unshift(selectedUserDataSet); // Add the selected user's data at the beginning
        }
      }

      // Process data for all team members
      for (let user of team) {
        if (selectedUser && user.uid === selectedUser.uid) continue; // skip the selected user since it's already processed
        const dataSet = processDataForUser(user);
        if (dataSet) {
          allDataSets.push(dataSet);
        }
      }

      // Filter datasets for members with data privacy set to NONE (this step might be redundant now since we're already checking within processDataForUser, but I'll keep it just in case)
      allDataSets = allDataSets.filter(memberData => memberData != null);

      setChartData(allDataSets);
      validateDataAlignment(labels, allDataSets);
    }

    calculateDatasets();
    setDataLoaded(true);
    // getFormattedDatesForLastSevenTimeZones(user.uid, setFormattedDates);
  }, [team, selectedUser, currentStartDate]);

  // useEffect() is going to run every single time either team or selected user updates its state

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: team.length <= 5,
        position: 'top',
        labels: {
          font: {
            family: 'Figtree',
            size: 14,
            weight: 900,
          },
          generateLabels: (chart) => {
            return userOrder.map(uid => {
              const user = team.find(user => user.uid === uid);
              return {
                text: memberLabels.get(user.uid),
                fillStyle: chart.data.datasets.find(dataset => dataset.label === memberLabels.get(user.uid)).borderColor,
                fontColor: '#fff',
              };
            });
          }
        },
        onClick: (event, item) => {
          logEvent(adminUser, "individual-duration-legend-clicked", "Selected " + item.text + " in legend above individual sleep durations chart.");
          const userClicked = team.find(user => memberLabels.get(user.uid) === item.text);
          if (selectedUser && userClicked.uid === selectedUser.uid) {
            setSelectedUser(null); // Deselect if the clicked user is already selected
          } else {
            setSelectedUser(userClicked); // Select the clicked user
          }
        }
      },
      tooltip: {
        callbacks: {
          beforeTitle: (context) => {
            const tooltipItem = context[0];
            if (chartData[tooltipItem.datasetIndex] && chartData[tooltipItem.datasetIndex].data[tooltipItem.index]) {
              const { label } = chartData[tooltipItem.datasetIndex];
              return label; // User's Name
            }
            return '';
          },
          title: (context) => {
            const d = new Date(context[0].label);
            return d.toLocaleDateString('en-US', { weekday: 'long' }); // Day of the week
          },
          label: (context) => {
            const dateStr = context.label;
            const sleepTime = context.dataset.sleepTime[context.dataIndex];
            const wakeTime = context.dataset.wakeTime[context.dataIndex];
            const sleepTimeReported = context.dataset.sleepTimeReported[context.dataIndex];
            const wakeTimeReported = context.dataset.wakeTimeReported[context.dataIndex];

            const diffInMilliseconds = new Date(wakeTime) - new Date(sleepTime);
            const hoursSlept = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2);

            if (!sleepTimeReported || !wakeTimeReported) {
              return [
                context.dataset.label,
                dateStr,
                "⚠️No data! App access required",
              ];
            }
            return [
              context.dataset.label,
              dateStr, // Date
              `Hours slept: ${hoursSlept}`, // Hours slept
            ];
          },

        },
      }


    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Hours',
          color: 'white',
          font: {
            size: 20,
            family: 'Figtree',
          },
        },
        ticks: {
          color: 'white',
          font: {
            size: 15,
            weight: 900,
            family: 'Figtree'
          },
        },
        grid: {
          display: true,
          color: '#23383C',
          drawOnChartArea: true,
          drawTicks: true,
          lineWidth: 2.5,
        },
        border: {
          display: false,
        },
      },
      x: {
        type: 'category',
        ticks: {
          color: 'white',
          font: {
            size: 14,
            weight: 900,
            family: 'Figtree',
          },
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  function interpolateGaps(data) {
    let prevValidIndex = null;
    const interpolatedData = [...data]; // Create a copy of the data array
    interpolatedData.forEach((item, index) => {
      if (item !== null) {
        if (prevValidIndex !== null && index - prevValidIndex === 2) {
          const prevValue = interpolatedData[prevValidIndex];
          const nextValue = item;
          interpolatedData[index - 1] = (prevValue + nextValue) / 2;
        }
        prevValidIndex = index;
      }
    });
    return interpolatedData;
  }
  const data = {
    labels,
    datasets: chartData,
  };
  const commonTimezonesArray = Array.from(userLocations); // Assuming commonLocations is a Set
  const mostRecentTimezone = commonTimezonesArray[commonTimezonesArray.length - 1];

  // Check for the existence of datasets and its data before calling alignDataWithLabels
  // let { alignedData, indexMapping } = data.datasets && data.datasets[0] && data.datasets[0].data
  // ? alignDataWithLabels(labels, data.datasets[0].data)
  // : { alignedData: [], indexMapping: [] }; // Provide default values in case of a falsy check

  let modifiedData = {
    ...data,
    datasets: data.datasets.map(dataset => {
      const { alignedData } = dataset.data
        ? alignDataWithLabels(labels, dataset.data)
        : { alignedData: [] };
      return {
        ...dataset,
        data: interpolateGaps(alignedData),
      };
    }),
    labels: labels.map(dateStr => formatDateToWeekdayMMDDYY(dateStr, mostRecentTimezone)),
  };

  if (team.length > 0 && data.datasets && data.datasets.length > 0) {
    return (
      <>
        {team.length > 5 && <SearchableDropdown users={team} onSelect={(user) => {
          logEvent(adminUser, "user-selected-searachable-dropdown", "Selected " + user.firstName + " with id: " + user.uid + " from searchable dropdown above individual sleep durations chart.")
          setSelectedUser(user);
          }} memberLabels={memberLabels} />}
        <Line options={options} data={modifiedData} />
      </>
    );
  } else if (!timeoutReached || (timeoutReached && !dataLoaded)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    return <div>No viewable team members! Check privacy settings.</div>;
  }



};

export default SleepChart;