import React, { useState, useRef } from 'react';
import { Container, Typography, Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import '../../src/fonts.css';
import image from "../../src/assets/conf.png";
import styled from '@emotion/styled';
import NameEditor from './NameEditor';
import { Colors } from '../helpers/utils';
import Mixpanel from 'mixpanel-browser';

const Input = styled.input`
  display: block;
  font-size: 0.9em;
  width: 20%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #1C434B;
  background-color: #1C434B;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

 const useStyles = makeStyles((theme) => ({
     page: {
         background: '#011D23',
         marginBottom: theme.spacing(0)
     },

     heroSection: {
         display: 'flex',
         justifyContent: 'space-between',
         padding: theme.spacing(8, 0),
         flexDirection: 'row',   
         alignItems: 'flex-start',
         [theme.breakpoints.down('md')]: {
           flexDirection: 'column',
           alignItems: 'center',
         },
         [theme.breakpoints.down('sm')]: {
           flexDirection: 'column',
           alignItems: 'center',
         },
       },

     title: {
         maxWidth: '70%',
         fontWeight: 900,
         margin: theme.spacing(2,8),
         color: '#fff',
         textAlign: 'left',
         fontFamily: 'Figtree',
         fontSize: 70,
         marginRight:'auto',
         [theme.breakpoints.down('md')]: {
           margin: theme.spacing(0, 4),
           maxWidth: '100%',
           fontSize: 100,
           textAlign: 'left',
         },
         [theme.breakpoints.down('sm')]: {
           margin: theme.spacing(0, 4),
           maxWidth: '100%',
           fontSize: 40,
           textAlign: 'left',
         },
       },



       code: {
         fontFamily: 'Figtree',
         fontWeight: 900,
         color: '#fff',
         fontSize: 70,
         textAlign: 'left',
         color: Colors.SoftYellow,
         marginLeft: theme.spacing (7),
         marginTop: theme.spacing (5),
         [theme.breakpoints.down('sm')]: {
           fontSize: 40,
           marginLeft: theme.spacing (0),
           marginTop: theme.spacing (3),
           textAlign: 'center',
         },
       },

       codeDescription: {
         fontFamily: 'Figtree',
         fontSize: 35,
         fontWeight: 1000,
         textAlign: 'left',
         width: '100%',
         [theme.breakpoints.down('sm')]: {
           fontSize: 25,
           fontWeight: 900,
           padding: theme.spacing(1, 0),
           textAlign: 'center',
           marginBottom: theme.spacing(5)
         },
       },

       smallMessage: {
        fontFamily: 'Figtree',
        fontSize: 25,
        fontWeight: 1000,
        textAlign: 'left',
        width: '100%',
        marginTop: theme.spacing(5),
        color: Colors.SoftYellow,
        [theme.breakpoints.down('sm')]: {
          fontSize: 10,
          fontWeight: 900,
          padding: theme.spacing(1, 0),
          textAlign: 'center',
          marginBottom: theme.spacing(5)
        },
      },

       container: {
         width: '40%',
         marginLeft: theme.spacing(6),
         marginTop: theme.spacing(7),
         [theme.breakpoints.down('sm')]: {
             width: '100%',
             margin: 'auto',
             marginTop: theme.spacing(4)
         }
       },

       codeCard: {
         backgroundColor: '#0F3038',
         color: '#fff',
         height: '100%',
         margin: theme.spacing (0, 8),
         marginBottom: theme.spacing (0),
         borderRadius: 20,
         [theme.breakpoints.down('sm')]: {
           margin: theme.spacing(0, 4),
           display: 'flex',
           flexDirection: 'column',
           padding: theme.spacing(0, 0),
         },
       },


       descriptionSection: {
         display: 'flex',
         justifyContent: 'space-between',   
         [theme.breakpoints.down('sm')]: {
           flexDirection: 'column',
           alignItems: 'center',
           width: '100%'

         },
       },


       dashboardButton: {
         textTransform: 'none',
         backgroundColor: '#DEE8A1',
         color: '#011D23',
         fontFamily: 'Figtree',
         fontSize: 18,
         fontWeight: 800, 
         height: 45,
         width: '100%',
         padding: 10,
         display: 'flex', 
         justifyContent: 'center',
         marginTop: theme.spacing (10),

         [theme.breakpoints.down('sm')]: {
           height: 57,
           width: '100%',
           marginTop: theme.spacing (6),
           marginBottom: theme.spacing (9),

         },
       },

       name: {
        fontSize: 50,
        textAlign: 'left',
        fontWeight: 900,
        fontFamily: 'Figtree',
        color: '#fff',
        marginTop: theme.spacing (2),
        marginLeft: 50
      },

 }))


 const Confirmation = ({
  code,
  organization,
  editingName,
  setEditingName,
  updateAndSwitchToReadMode,
  organizationName,
  setOrganizationName,
  showError,
  setShowError
}) => {
     const classes = useStyles();

     const navigate = useNavigate();
     const hasTracked = useRef(false);

     if (!hasTracked.current) {
        Mixpanel.track_pageview({"page": "Confirmation"});
        hasTracked.current = true;
      }

 return (
     <>
     <div className={classes.page}>
     <div className={classes.heroSection}>
         <Typography variant="h2" className={classes.title}>
         Congratulations!
         </Typography>
     </div>

       <div >
         <Card className={classes.codeCard}>
         <CardContent>
         <Typography 
            className={classes.name}
            onClick={() => {
              setEditingName(true)
              Mixpanel.track("Clicked on organization name")
            }}
          >
                
                <NameEditor
                  organization={organization}
                  updateAndSwitchToReadMode={updateAndSwitchToReadMode}
                  editingName={editingName}
                  setEditingName={setEditingName}
                  organizationName={organizationName}
                  setOrganizationName={setOrganizationName}
                  showError={showError}
                  setShowError={setShowError}
                />
              </Typography>

             <Typography variant="h2" className={classes.code}>
              Your code is: <span >{code}</span>
             </Typography>

             <div className={classes.descriptionSection}>
             <Container className={classes.container}>
             <Typography variant="h5" className={classes.codeDescription}>
             Provide this unique code to your members when signing up for Somn.
             </Typography>
             <Typography variant="h5" className={classes.smallMessage}>
             The dashboard will update once you get your first member!
             </Typography>
             </Container>

             <img
             className={classes.image}
             src={image}
             alt="somn screenshot" />
             </div>

         </CardContent>
         </Card>
         </div>
         </div>
         </>
 );
 };

 export default Confirmation;
